import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
// import LineChart from '../Charts/LineChart'
import { Line } from 'react-chartjs-2';
import { CustomSelect } from "../../components/PopUp/CustomSelect"

class Tab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plot : 'MAE',
            selectOption: [
                { name: "METRICS", value: "METRICS", disabled: false },
                { name: "PRECISION", value: "PRECISION", disabled: true },
                { name: "SENSIVITY", value: "SENSIVITY", disabled: true },
                { name: "F1", value: "F1", disabled: true },
                { name: "MAE", value: "MAE" },
                { name: "LOSS", value: "LOSS" },
              ],
        }
    }

    selectOption = (e, v) => {
        // eslint-disable-next-line
        switch (v.value) {
          case "MAE":
            this.setState({plot: 'MAE'})
            break;
          case "LOSS":
            this.setState({plot: 'LOSS'})
            break;
          case "METRICS":
            break;
          case "PRECISION":
            break;
          case "SENSIVITY":
            break;
          case "F1":
            break;
        }
      };

    render() {
        const { MLcsvData, MLjsonData } = this.props;
        const { plot } = this.state;
        var maeData = [];
        var lossData = [];
        var labels = []
        for (var i = 0; i < MLcsvData.length; i++) {
            maeData.push({ x: parseFloat(MLcsvData[i].val_mae), y: MLcsvData[i].mae });
            lossData.push({ x: parseFloat(MLcsvData[i].val_loss), y: MLcsvData[i].loss });
            labels.push(MLcsvData[i].epoch);
        }
        // console.log('maeData', maeData)
        let mae_datasets = {
            lineTension: 0.1,
            label: 'MAE',
            backgroundColor: '#88DD88',
            borderColor: '#88DD88',
            pointRadius: 0,
            fill: false,
            data: maeData,
        }

        var loss_datasets = {
            lineTension: 0.1,
            label: 'LOSS',
            backgroundColor: '#25CFF9',
            borderColor: '#25CFF9',
            pointRadius: 0,
            fill: false,
            data: lossData,
        }
        var datasets = plot  === 'MAE' ? mae_datasets : loss_datasets;
        let data = {
            labels: labels,
            fill: false,
            datasets: [datasets]
        }

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            maintainAspectRatio: false,
            fill: false,
            plugins: {
                datalabels: {
                    // hide datalabels for all datasets
                    display: false
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                y: {
                    grid: {
                        drawOnChartArea: false
                    },
                    title: {
                        font: {
                            color: '#000000',
                            size: 15,
                        },
                        display: true,
                        text: 'Metric'
                    },
                    ticks: {
                        autoSkip: true,
                        font: {
                            size: 10
                        }
                    }
                },
                x: {
                    //  gridLines: {
                    //   drawOnChartArea: false
                    // },
                    title: {
                        font: {
                            color: '#000000',
                            size: 15,
                        },
                        display: true,
                        text: 'Epoch Number'
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 10,
                        
                    }
                }
            }

        };

        // ...
        const { mae, mae_95, r2} = MLjsonData;

        return (
            <div style={{width: "100%", height: "calc(100% - 45px)"}}>
                <div className = "info" style = {{width: "100%"}}>
                <table style = {{width: "100%",
                                height: "50%", 
                                marginBottom: '3px',
                                textAlign: 'center',
                                tableLayout: "fixed"
                                }}>

                <tr style={{
                        'fontSize': '10px',
                        'fontWeight': '600',
                        'color': '#0c68b2'
                    }}>
                        <td>{mae_95 && mae_95.toFixed(2)}%</td>
                        <td>{mae && mae.toFixed(2)}</td>
                        <td>1.283</td>
                        <td>0.94</td>
                        <td>{r2 && r2.toFixed(2)}</td>
                        <td>1.85</td>
                </tr>
                
                <tr 
                    style={{
                        'color': 'black',
                        'fontWeight': '800',
                        'fontSize': '8px'
                    }}
                >
                        <td>ACCURACY</td>
                        <td>Mean Absolute Error</td>
                        <td>LOSS</td>
                        <td>SENSITIVITY</td>
                        <td>Coefficient of Determination (R<sup>2</sup>)</td>
                        <td>ROCAUC</td>
                </tr>

                
                <tr style={{
                        width: "100%",
                        fontSize: '18px',
                        fontWeight: 600,
                        padding: '7px 14px',
                        textAlign: 'center'
                    }}>
                    <td colSpan="6" style={{
                                    'width': '100%',
                                    'fontSize': '14px',
                                    'padding': '0px', 
                            }}><div style={{display: "inline-block", marginRight: "5px"}}>Metric vs. Epoch #</div>
                            <div style={{display: "inline-block"}}>
                        <CustomSelect
                        options={this.state.selectOption}
                        value="MAE"
                        name="language"
                        placeholder="Select"
                        onChange={(e, v) => {
                        this.selectOption(e, v);
                        }}
                    />
                    </div>
                    </td>
                </tr>
                </table>



                </div>


                    <div style = {{width: "100%", height: "70%"}} className="machine_learning_canvas">

                        <Line data={data} options={options} />
                    </div>

            </div>
        );
    }
}

export default Tab;
