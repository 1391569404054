import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

// Other Layout related Component

import Header from "./Header/header"
import Footer from "./Footer/footer"
// import Navbar from "./Sidebar/sidebar"
import "./layOutIndex.scss"
import "./edit.scss"

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuOpened: false,
    }

  }

  /**
   * Open/close right sidebar
   */


  componentDidMount() {
   
    // console.log('prosp layout -----------------\n',this.props);
    // const title = this.props.location.pathname
    // let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    // document.title =
    //   currentage + " | Skote - Responsive Bootstrap 4 Admin Dashboard"

    // this.props.changeLayout("horizontal")
    // if (this.props.topbarTheme) {
    //   this.props.changeTopbarTheme(this.props.topbarTheme)
    // }
    // if (this.props.layoutWidth) {
    //   this.props.changeLayoutWidth(this.props.layoutWidth)
    // }
    // if (this.props.showRightSidebar) {
    //   this.toggleRightSidebar()
    // }
  }

  /**
   * Opens the menu - mobile
   */
  openMenu = e => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened })
  }
  render() {
    const { leftSideBarType } = this.props;
    return (
      <React.Fragment>
          <div id="layout-wrapper">
            <Header/>
            {/* <Navbar menuOpen={this.state.isMenuOpened} /> */}
              <div className={leftSideBarType === 'default' ? "content-wrapper" :  "content-wrapper main-wrapper"}>
                <div className="main-content">{this.props.children}</div>
              </div>
            <Footer />
          </div>
      </React.Fragment>
    )
  }
}



const mapStateToProps = state => {
  return {
    ...state.Layout,
  }
}
export default connect(mapStateToProps)(withRouter(Layout))

