/* ALL LIST */
export const GET_TEAMS_LIST = "GET_TEAMS_LIST"
export const GET_TEAMS_LIST_SUCCESS = "GET_TEAMS_LIST_SUCCESS"
export const GET_TEAMS_LIST_FAIL = "GET_TEAMS_LIST_FAIL"

/* ORG INS LIST */
export const GET_INS_TEAMS_LIST = "GET_INS_TEAMS_LIST"
export const GET_INS_TEAMS_LIST_SUCCESS = "GET_INS_TEAMS_LIST_SUCCESS"
export const GET_INS_TEAMS_LIST_FAIL = "GET_INS_TEAMS_LIST_FAIL"

export const GET_TEAMS_INS_LIST = "GET_TEAMS_INS_LIST"
export const GET_TEAMS_INS_LIST_SUCCESS = "GET_TEAMS_INS_LIST_SUCCESS"
export const GET_TEAMS_INS_LIST_FAIL = "GET_TEAMS_INS_LIST_FAIL"

//staff
export const TEAM_STAFF = "TEAM_STAFF"
export const TEAM_STAFF_SUCCESS = "TEAM_STAFF_SUCCESS"
export const TEAM_STAFF_FAIL = "TEAM_STAFF_FAIL"
//single team
export const TEAM_BYID = "TEAM_BYID"
export const TEAM_BYID_SUCCESS = "TEAM_BYID_SUCCESS"
export const TEAM_BYID_FAIL = "TEAM_BYID_FAIL"

/** edit */
export const ADD_TEAM = "ADD_TEAM"
export const RENAME_TEAM = "RENAME_TEAM"
export const DELETS_TEAM = "DELETS_TEAM"
export const EDIT_TEAM = "EDIT_TEAM"
export const EDIT_TEAM_STATE = "EDIT_TEAM_STATE"
export const EDIT_TEAM_SUCCESS = "EDIT_TEAM_SUCCESS"
export const EDIT_TEAM_FAIL = "EDIT_TEAM_FAIL"
export const EPMTY_EDITING_DATA = "EPMTY_EDITING_DATA"
export const MERGE_TEAM = "MERGE_TEAM"

/** simulation portal action type*/
export const MESH_TYPE = "MESH_TYPE"
export const JOBS_UPLOAD_SUCCESS = "JOBS_UPLOAD_SUCCESS"


/** edit player */
export const UPDATE_SENSOR_ID = "UPDATE_SENSOR_ID"
export const UPDATE_PLAYER_STATUS = "UPDATE_PLAYER_STATUS"
export const UPDATE_ALL_PLAYER_STATUS = "UPDATE_ALL_PLAYER_STATUS"
export const DELETE_PLAYERS = "DELETE_PLAYERS"
export const CHANGE_ADMIN_TO_PLAYER = "CHANGE_ADMIN_TO_PLAYER"
export const EDIT_PLAYER = "EDIT_PLAYER"
export const EDIT_PLAYER_STATE = "EDIT_PLAYER_STATE"
export const EDIT_PLAYER_SUCCESS = "EDIT_PLAYER_SUCCESS"
export const EDIT_PLAYER_FAIL = "EDIT_PLAYER_FAIL"
export const EPMTY_EDITING_PLAYERS_DATA = "EPMTY_EDITING_PLAYERS_DATA"

//single team
export const GET_TEAM_STAT_SUMMARY = "GET_TEAM_STAT_SUMMARY"
export const GET_TEAM_STAT_SUMMARY_SUCCESS = "GET_TEAM_STAT_SUMMARY_SUCCESS"
export const GET_TEAM_STAT_SUMMARY_FAIL = "GET_TEAM_STAT_SUMMARY_FAIL"

//fetch team members
export const FETCH_TEAM_MEMBERS = "FETCH_TEAM_MEMBERS"
export const FETCH_TEAM_MEMBERS_SUCCESS = "FETCH_TEAM_MEMBERS_SUCCESS"
export const FETCH_TEAM_MEMBERS_FAIL = "FETCH_TEAM_MEMBERS_FAIL"

export const REMOVE_TEAM_MEMBERS = "REMOVE_TEAM_MEMBERS"
export const REMOVE_TEAM_MEMBERS_SUCC = "REMOVE_TEAM_MEMBERS_SUCC"
export const REMOVE_TEAM_MEMBERS_FAIL = "REMOVE_TEAM_MEMBERS_FAIL"

export const SET_TEAM_ANYLSIS_DATA = "SET_TEAM_ANYLSIS_DATA"
export const INSTANCE_TYPE = "INSTANCE_TYPE"
export const MESH_SUBTYPE = "MESH_SUBTYPE"
export const EXCLUDE_SIMULATIONS = "EXCLUDE_SIMULATIONS"

export const SET_TEAM_MPS_DATA = "SET_TEAM_MPS_DATA"

export const GET_TEAM_SIMULATIONS = "GET_TEAM_SIMULATIONS"
export const GET_TEAM_SIMULATIONS_SUCCESS = "GET_TEAM_SIMULATIONS_SUCCESS"
export const GET_TEAM_SIMULATIONS_FAIL = "GET_TEAM_SIMULATIONS_FAIL"
export const GET_SIMS_LOG = "GET_SIMS_LOG"
export const SIMS_LOG_SUCCESS = "SIMS_LOG_SUCCESS"
export const SIMS_LOG_FAIL = "SIMS_LOG_FAIL"

export const GET_ALL_SIMULATIONS_MPS_SUCC = "GET_ALL_SIMULATIONS_MPS_SUCC"

/*deleting the undefined member*/
export const DELETE_UNDEFINED_MEM_STATE = "DELETE_UNDEFINED_MEM_STATE"
export const DELETE_UNDEFINED_MEM_FAIL = "DELETE_UNDEFINED_MEM_FAIL"
export const DELETE_UNDEFINED_MEM_LOADER = "DELETE_UNDEFINED_MEM_LOADER"
/*deleting the team staff*/
export const DELETE_TEAM_STAFF_STATE = "DELETE_TEAM_STAFF_STATE"
export const DELETE_TEAM_STAFF_FAIL = "DELETE_TEAM_STAFF_FAIL"
export const DELETE_TEAM_STAFF_LOADER = "DELETE_TEAM_STAFF_LOADER"
export const DELETE_TEAM_STAFF_AFTER_SUCC = "DELETE_TEAM_STAFF_AFTER_SUCC"

/* load ml training result */
export const ML_TRAINING_RESULT_GET = "ML_TRAINING_RESULT_GET"
export const ML_TRAINING_RESULT = "ML_TRAINING_RESULT"
export const ML_TRAINING_RESULT_FAIL = "ML_TRAINING_RESULT_FAIL"

export const GET_TEAM_BRAIN_IMAGE = "GET_TEAM_BRAIN_IMAGE"
export const GET_TEAM_BRAIN_IMAGE_SUCCESS = "GET_TEAM_BRAIN_IMAGE_SUCCESS"
export const GET_TEAM_BRAIN_IMAGE_FAIL = "GET_TEAM_BRAIN_IMAGE_FAIL"

export const GET_ALL_SIMULATIONS_MPS = "GET_ALL_SIMULATIONS_MPS"
export const GET_ALL_SIMULATIONS_MPS_FAIL = "GET_ALL_SIMULATIONS_MPS_FAIL"

export const RESET_TEAM_SIMULATIONS = "RESET_TEAM_SIMULATIONS"

//STRAIN
export const GET_ALL_PLAYER_ANALYSIS_DATA = "GET_ALL_PLAYER_ANALYSIS_DATA"
export const GET_ALL_PLAYER_ANALYSIS_DATA_SUCCESS = "GET_ALL_PLAYER_ANALYSIS_DATA_SUCCESS"
export const GET_ALL_PLAYER_ANALYSIS_DATA_FAIL = "GET_ALL_PLAYER_ANALYSIS_DATA_FAIL"

//ML
export const GET_ALL_PLAYER_ML_ANALYSIS_DATA = "GET_ALL_PLAYER_ML_ANALYSIS_DATA"
export const GET_ALL_PLAYER_ML_ANALYSIS_DATA_SUCCESS = "GET_ALL_PLAYER_ML_ANALYSIS_DATA_SUCCESS"
export const GET_ALL_PLAYER_ML_ANALYSIS_DATA_FAIL = "GET_ALL_PLAYER_ML_ANALYSIS_DATA_FAIL"

//PRESSURE 

export const GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA = "GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA"
export const GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA_SUCCESS = "GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA_SUCCESS"
export const GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA_FAIL = "GET_ALL_PLAYER_PRESSURE_ANALYSIS_DATA_FAIL"

//STRAIN
export const FETCH_TEAM_TOP_30_DATA = "FETCH_TEAM_TOP_30_DATA"
export const FETCH_TEAM_TOP_30_DATA_SUCCESS = "FETCH_TEAM_TOP_30_DATA_SUCCESS"
export const FETCH_TEAM_TOP_30_DATA_FAIL = "FETCH_TEAM_TOP_30_DATA_FAIL"


export const UPDATE_LOADING_STATUS = "UPDATE_LOADING_STATUS"