import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import "./footer.scss"
class footer extends Component {
  constructor(props) {
    super(props)
    this.state = {}

  }

  render() {
    const { leftSideBarType } = this.props;
    return (
      <React.Fragment>
        <footer className={leftSideBarType === 'default' ? "site-footer" :  "site-footer main-footer"} id="site-footer">
            <p>© 2023 Copyright: BrainSim Technologies Inc</p>
        </footer>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    leftSideBarType: state.Layout.leftSideBarType,
  }
}

export default connect(mapStateToProps)(withRouter(footer))