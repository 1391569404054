export const REGISTERING_USER = "registering_user"
export const REGISTER_USER = "register_user"
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull"
export const REGISTER_USER_FAILED = "register_user_failed"

export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS"
export const GET_ORGANIZATIONS_SUCCESS = "GET_ORGANIZATIONS_SUCCESS"
export const GET_ORGANIZATIONS_FAILURE = "GET_ORGANIZATIONS_FAILURE"

export const GET_INS = "GET_INS"
export const GET_INS_SUCCESS = "GET_INS_SUCCESS"
export const GET_INS_FAILURE = "GET_INS_FAILURE"

export const GET_TEAMS = "GET_TEAMS"
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS"
export const GET_TEAMS_FAILURE = "GET_TEAMS_FAILURE"

export const INVITE_USER_DETAILS = "INVITE_USER_DETAILS"
export const INVITE_USER_DETAILS_SUCCESS = "INVITE_USER_DETAILS_SUCCESS"
export const INVITE_USER_DETAILS_FAILURE = "INVITE_USER_DETAILS_FAILURE"

export const PLAYER_DETAILS_FROM_TOKEN = "PLAYER_DETAILS_FROM_TOKEN"
export const PLAYER_DETAILS_FROM_TOKEN_SUCCESS = "PLAYER_DETAILS_FROM_TOKEN_SUCCESS"
export const PLAYER_DETAILS_FROM_TOKEN_FAILURE = "PLAYER_DETAILS_FROM_TOKEN_FAILURE"

export const REGISTER_OAOUTH_USER = "REGISTER_OAOUTH_USER"



