
/* USERS PROFILE */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

export const GET_ACTIVE_USERS = "GET_ACTIVE_USERS"
export const GET_ACTIVE_USERS_SUCCESS = "GET_ACTIVE_USERS_SUCCESS"
export const GET_ACTIVE_USERS_FAIL = "GET_ACTIVE_USERS_FAIL"

/* USERS PROFILE */
export const CONTACT_MAIL = "CONTACT_MAIL"
export const CONTACT_MAIL_SUCCESS = "CONTACT_MAIL_SUCCESS"
export const CONTACT_MAIL_FAIL = "CONTACT_MAIL_FAIL"

/* Numbers of simulations */
export const GET_SIMULATIONS_NUMBERS = "GET_SIMULATIONS_NUMBERS"
export const SIMULATIONS_NUMBERS = "SIMULATIONS_NUMBERS"
export const GET_SIMULATIONS_NUMBERS_FAIL = "GET_SIMULATIONS_NUMBERS_FAIL"

export const GET_AWS_COST = "GET_AWS_COST"
export const AWS_COST = "AWS_COST"
export const GET_AWS_FAIL = "GET_AWS_FAIL"
/* ge MPS */
export const GET_MPS_DATA = "GET_MPS_DATA"
export const GET_MPS_DATA_SUCC = "GET_MPS_DATA_SUCC"
export const GET_MPS_DATA_FAIL = "GET_MPS_DATA_FAIL"