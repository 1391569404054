import { CSSTransition } from "react-transition-group";
import classes from "./CustomSelect.module.css";
import { useSelect } from "react-select-search";

const classNames = {
  appear: classes.appear,
  appearActive: classes["appear-active"],
  appearDone: classes["appear-done"],
  enter: classes.enter,
  enterActive: classes["enter-active"],
  enterDone: classes["enter-done"],
  exit: classes.exit,
  exitActive: classes["exit-active"],
  exitDone: classes["exit-done"],
};


export const CustomSelect = ({ options, value, onChange }) => {
  const [snapshot, valueProps, optionProps] = useSelect({
    options,
    value,
    allowEmpty: false,
    onChange,
  });

  return (
    <div>
      <div
        {...valueProps}
        className={classes.button}
      >{`${snapshot.displayValue}`}</div>
      <CSSTransition
        in={snapshot.focus}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={classNames}
      >
        <div className={classes.select}>
          <div className={classes.options}>
            {snapshot.options.map((option) => (
              <button
                key={option.value}
                {...optionProps}
                value={option.value}
                disabled = {option.disabled}
                className={[
                  classes.option,
                  snapshot.value === option.value
                    ? classes["is-selected"]
                    : null,
                ]
                  .filter(Boolean)
                  .join(" ")}
              >
                {option.name}
              </button>
            ))}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
