import React, { useState, useEffect } from "react";
import "./TopEvents.scss";
import { getSimDate } from "../../helpers/utility/dateHelper";
import { Td } from "../Link/Td";
import axios from "axios";
import { Link } from "react-router-dom";

const MPSRows = ({
  Simulations,
  item,
  active = false,
  checkBoxHandler = () => true,
}) => {
  const calculateImpactDate = (event_id) => {

    if (!Simulations) return "N/A";
    const filterSimulations = Simulations.filter(
      (el) => el.event_id === event_id
    );
    if (filterSimulations.length === 0) return "N/A";
    return typeof filterSimulations[0].impact_date !== "undefined"
      ? getSimDate(filterSimulations[0].impact_date)
      : "N/A";
  };
  return (
    <>
      <Td to={`/player/details/${item.event_id}`} className="event-id">
        <div className="td-content">{item.mps_value}</div>
      </Td>
      <Td to={`/player/details/${item.event_id}`} className="event-id">
        <div className="td-content">{calculateImpactDate(item.event_id)}</div>
      </Td>
      <Td className="event-id">
        <div className="td-content">
          {active ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              onClick={() => checkBoxHandler(false)}
            >
              <path d="M10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591zm-5.041-15c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              onClick={() => checkBoxHandler(true)}
            >
              <path d="M5 2c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z" />
            </svg>
          )}
        </div>
      </Td>
    </>
  );
};

export const TopEvents = ({
  MpsValues,
  Simulations,
  selected_sphere_type,
  player_id,
  pressure_dashboardd,
}) => {
  const [mps, setMPS] = useState([]);
  const [diagnosedConcussion, setDiagnosedConcussion] = useState([]);
  const [selectedSphereColor, setSelectedSphereColor] = useState(null);

  useEffect(() => {
    if (MpsValues || Simulations) {
      if (MpsValues && Simulations) {
        const SimulationsDiagnose = Simulations.filter(
          (el) => el.diagnosed_concussion
        ).map((el) => el.event_id);
        setDiagnosedConcussion(SimulationsDiagnose);
        const allEvents = Simulations.map((el) => el.event_id);
        const sortMPSvalues = MpsValues.filter(
          (el) => allEvents.indexOf(el.event_id) > -1
        )
          .map((el) => {
            const mps_value = parseFloat((el.value * 100).toFixed(2));
            let mps_color = "green";
            if (mps_value > 30) {
              mps_color = "black";
            } else if (mps_value >= 18) {
              mps_color = "red";
            } else if (mps_value >= 10) {
              mps_color = "orange";
            }
            return {
              ...el,
              mps_value,
              mps_color,
            };
          })
          .sort((a, b) => {
            if (a.mps_value > b.mps_value) return -1;
            if (a.mps_value < b.mps_value) return 1;
            return 0;
          })
          .filter((el, index) => index < 5);
        if (sortMPSvalues.length > 0) {
          setMPS(sortMPSvalues);
        }
      } else if (MpsValues && !Simulations) {
        const sortMPSvalues = MpsValues.map((el) => {
          const mps_value = parseFloat((el.value * 100).toFixed(2));
          let mps_color = "green";
          if (mps_value > 30) {
            mps_color = "black";
          } else if (mps_value >= 18) {
            mps_color = "red";
          } else if (mps_value >= 10) {
            mps_color = "orange";
          }
          return {
            ...el,
            mps_value,
            mps_color,
          };
        })
          .sort((a, b) => {
            if (a.mps_value > b.mps_value) return -1;
            if (a.mps_value < b.mps_value) return 1;
            return 0;
          })
          .filter((el, index) => index < 5);
        if (sortMPSvalues.length > 0) {
          setMPS(sortMPSvalues);
        }
      }
    }
    return () => true;
  }, [MpsValues, Simulations]);

  useEffect(() => {
    if (selected_sphere_type) {
      let selected_color = null;
      switch (selected_sphere_type) {
        case "X-Large":
          selected_color = "black";
          break;
        case "Large":
          selected_color = "red";
          break;
        case "Medium":
          selected_color = "orange";
          break;
        case "Small":
          selected_color = "green";
          break;
        default:
          selected_color = null;
      }
      setSelectedSphereColor(selected_color);
    }
    return () => true;
  }, [selected_sphere_type]);

  const handleCheck = (value, event_id) => {
    const previousDiagnose = [...diagnosedConcussion];
    let updatedDiagnosedCon = [...diagnosedConcussion];
    if (value) {
      updatedDiagnosedCon = [...updatedDiagnosedCon, event_id];
    } else {
      if (updatedDiagnosedCon.indexOf(event_id) > -1) {
        updatedDiagnosedCon.splice(updatedDiagnosedCon.indexOf(event_id), 1);
      }
    }
    setDiagnosedConcussion(updatedDiagnosedCon);
    const player_id = Simulations.filter((el) => el.event_id === event_id).map(
      (el) => el.player_id
    );
    // console.log('player_id++',player_id)
    if (player_id.length > 0) {
      axios
        .post(`/player/add-diagnose-concession/${player_id[0]}`, {
          diagnosed_concussion: updatedDiagnosedCon,
        })
        .catch((err) => {
          console.log(err);
          setDiagnosedConcussion(previousDiagnose);
        });
    }
  };

  const emptyRows = () => {
    const empty_space = [];
    if (mps.length < 5) {
      const emptyRowsLength = 5 - mps.length;
      for (let i = 0; i < emptyRowsLength; i++) {
        if (i % 2) {
          empty_space.push(
            <tr className="white-row">
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          );
        } else {
          empty_space.push(
            <tr className="grey-row">
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          );
        }
      }
    }
    return empty_space;
  };

  return (
    <>
      <table
        style={{ width: "100%", height: "100%", tableLayout: "fixed" }}
        className="top-five-table"
      >
        <tr className="white-row">
          {pressure_dashboardd ? (
            <th>
              Max Intracranial Pressure
              <br />
              <span className="newTitle">
                95<sup>th</sup> Percentile
              </span>
            </th>
          ) : (
            <th>
              MPS (%)
              <br />
              <span className="newTitle">
                95<sup>th</sup> Percentile
              </span>
            </th>
          )}
          {pressure_dashboardd ? <th>Event Date</th> : <th>Impact Date</th>}

          <th>
            Diagnosed Concussion?{" "}
            {player_id ? (
              <Link to={`/player/data-collection/${player_id}`}>
                <svg
                  fill="#000000"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18px"
                  height="18px"
                >
                  <path d="M 3 3 L 3 21 L 21 21 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 3 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z" />
                </svg>
              </Link>
            ) : null}
          </th>
        </tr>
        {mps.map((el, index) => {
          if (index % 2) {
            return (
              <tr
                className="grey-row"
                style={{
                  backgroundColor:
                    selected_sphere_type && el.mps_color === selectedSphereColor
                      ? "#FFFF00"
                      : "#ededed",
                }}
              >
                <MPSRows
                  Simulations={Simulations}
                  item={el}
                  active={diagnosedConcussion.indexOf(el.event_id) > -1}
                  checkBoxHandler={(value) => handleCheck(value, el.event_id)}
                />
              </tr>
            );
          } else {
            return (
              <tr
                className="white-row"
                style={{
                  backgroundColor:
                    selected_sphere_type && el.mps_color === selectedSphereColor
                      ? "#FFFF00"
                      : "#ffffff",
                }}
              >
                <MPSRows
                  Simulations={Simulations}
                  item={el}
                  active={diagnosedConcussion.indexOf(el.event_id) > -1}
                  checkBoxHandler={(value) => handleCheck(value, el.event_id)}
                />
              </tr>
            );
          }
        })}
        {mps && mps.length > 0 ? (
          emptyRows()
        ) : (
          <tr className="white-row">
            <td colSpan="3">No data to display here.</td>
          </tr>
        )}
      </table>
    </>
  );
};

TopEvents.defaultProps = {
  MpsValues: [],
};
