import React from "react";
import "./TopEvents.scss";

import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { TopEvents } from "./TopEvents";

import { fetchInstitutionSimulations } from "../../store/actions";

class TopInstitutionEvents extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { insTeams } = this.props;
    this.props.fetchInstitutionSimulations({ insTeams: insTeams });
  }

  render() {
    const { insMPSData, insSimulations } = this.props;

    return (
      <TopEvents
        MpsValues={insMPSData}
        Simulations={insSimulations}
      ></TopEvents>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    insMPSData: state.Institutions.insMPSData,
    insTeams: state.Teams.insTeams,
    insSimulations: state.Institutions.insSimulations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInstitutionSimulations: (body) =>
      dispatch(fetchInstitutionSimulations(body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TopInstitutionEvents));
