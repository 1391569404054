import moment from 'moment'
export const getDaysOFMonth = (m, y) => {
    // months in JavaScript start at 0 so decrement by 1 e.g. 11 = Dec
    // --m;

    // if month is Sept, Apr, Jun, Nov return 30 days
    if (/8|3|5|10/.test(m)) return 30;

    // if month is not Feb return 31 days
    if (m !== 1) return 31;

    // To get this far month must be Feb ( 1 )
    // if the year is a leap year then Feb has 29 days
    if ((y % 4 === 0 && y % 100 !== 0) || y % 400 === 0) return 29;

    // Not a leap year. Feb has 28 days.
    return 28;
}

export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getSimDate = (time) => {
    return moment(time).format('MM-DD-YYYY')
    // console.log('time ---------------------\n', time)
    // var d = new Date(time);
    // console.log('d ---------------------\n', d)
    // var m = d.getMonth() + 1
    // return d.getFullYear() + '-' + addZero(m) + '-' + addZero(d.getDate());
}


export const getFormatedDate = (time) => {
    var d = new Date(time);
    var m = d.getMonth() + 1
    var y = d.getFullYear()+''
    return addZero(d.getDate()) + '/' + addZero(m) + '/' + y.substring(2);
}

export const getFormatedDateMMDDYY = (time) => {
    // var d = new Date(time);
    // var m = d.getMonth() + 1
    // var y = d.getFullYear()+''
    // return  addZero(m) + '/' +  addZero(d.getDate()) + '/' + y.substring(2);
    return moment(time).format('MM-DD-YYYY')
}


export const getSimTime = (time) => {
    var d = new Date(time);
    var h = addZero(d.getHours());
    var m = addZero(d.getMinutes());
    var s = addZero(d.getSeconds());
    return tConvert(h + ":" + m + ":" + s);
}
export const getexactSimTimes = (time) => {
    var d = new Date(time);
    var h = addZero(d.getHours());
    var m = addZero(d.getMinutes());
   // var s = addZero(d.getSeconds());
    return tConvert(h + ":" + m);
}

const tConvert = (time) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}

const addZero = (i) => {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

export const getCompledSimTime = (time) => {
    let currentStamp = new Date().getTime();
    let simulationTimestamp = new Date(time).getTime();
    var diff = (currentStamp - simulationTimestamp) / 1000;
    diff /= 60;
    let minutes = Math.abs(Math.round(diff));
    // minutes = minutes;
    return minutes;
}

export const converTime = (time) => {
    if (time && time !== null && time !== undefined) {
        var splitTime = time.toString().split(':');
        if (splitTime.length > 3) {

            time = splitTime[0] + ':' + splitTime[1] + ':' + splitTime[2];
            time = time.toString().split(' ');
            time = time[1] ? time[1] : time;
            // time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join('');
        } else {
            time = time.toString().split(' ');

            time = time[1] ? time[1] : time;
            // console.log('splitTime 2', time)
            time = time.toString().split('.')[1] ? time.toString().split('.')[0] : time
            // time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            // console.log("time", time);
            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }

            return time.join('');
        }

        // return adjusted time or original string
    } else {
        return "";
    }
}
export const converFormatOfTime = (time) => {
    if (time && time !== null && time !== undefined) {
        var splitTime = time.toString().split(':');
      
        if (splitTime.length > 3) {

            time = splitTime[0] + ':' + splitTime[1] + ':' + splitTime[2];
            //time = splitTime[0] + ':' + splitTime[1] + ':' + splitTime[2];
            time = time.toString().split(' ');
            time = time[1] ? time[1] : time;
            // time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                time[3]=""; 
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
           
            return time.join('');
        } else {
            time = time.toString().split(' ');
            time = time[1] ? time[1] : time;
            time = time.toString().split('.')[1] ? time.toString().split('.')[0] : time
            // time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            
            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                time[3]=""; 
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join('');
        }

        // return adjusted time or original string
    } else {
        return "";
    }
}

export const getLastDateTimeFromCurrentDate = (day) => {
    const d = new Date();
    var dateLimit = new Date(new Date().setDate(d.getDate() - day));
    return dateLimit;
}
export const getLastMonthFromCurrentDate = (month) => {
    const d = new Date();
    var dateLimit = new Date(new Date().setMonth(d.getMonth() - month));
    return dateLimit;
}
