import React from 'react';
import { Link } from 'react-router-dom';

export function Td({ children, to }) {
  // Conditionally wrapping content into a link
  const ContentTag = to ? Link : 'div';

  return (
    <td >
      <ContentTag to={to} target="_blanck">{children}</ContentTag>
    </td>
  );
}