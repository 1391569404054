import React from "react";
import { Bar } from "react-chartjs-2";

const PositionChart = (props) => {
  const { positionData, brainPosition } = props;
  let BRAIN_POSITIONS = positionData;
  if (!BRAIN_POSITIONS) return null;
  let sensorname = [];
  let chartdata = [];
  var count_positions_val = {};
  var count_positions = {};
  if (BRAIN_POSITIONS[brainPosition]) {
    BRAIN_POSITIONS[brainPosition].forEach(async (res) => {
      Object.entries(res).forEach(([key, value]) => {
        // console.log('res',key, value)
        if (key !== "null") {
          count_positions_val[key] = (count_positions_val[key] || 0) + value;
          count_positions[key] = (count_positions[key] || 0) + 1;
        }
      });
    });
  }

  

  const capitalizePosition = (words) => {
    var separateWord = words.toLowerCase().split(" ");
    for (var i = 0; i < separateWord.length; i++) {
      if (i == 0) {
        if (separateWord[0].split("-").length > 1) {
          var char = separateWord[0].split("-")[1];
          separateWord[i] = separateWord[i].charAt(0).toUpperCase();
          separateWord[1] = char.charAt(0).toUpperCase();
        } else {
          separateWord[i] = separateWord[i].charAt(0).toUpperCase();
        }
      } else {
        separateWord[i] = /^[a-zA-Z]+$/.test(separateWord[i])
          ? separateWord[i].charAt(0).toUpperCase()
          : "";
      }
    }
    return separateWord.join(" ");
  };

  /* */
  var data = [];
  var labels = [];
  // # Take first latter of postions ...
  // for position ...
  if (count_positions && count_positions_val) {
    Object.entries(count_positions).forEach(([key, value], index) => {
      let impactLen = value;
      let totalPostionVal = count_positions_val[key];
      totalPostionVal = totalPostionVal ? totalPostionVal.toFixed(2) : 0;
      // console.log('totalPostionVal = ',totalPostionVal,', totalImpact = ',impactLen)

      let mpsAvg = totalPostionVal / impactLen;
      //let mpsAvg = impactLen;
      /* console.log("totalPostionVal",totalPostionVal);
            console.log("impactLen",impactLen);*/
      mpsAvg = mpsAvg.toFixed(2);
      let position = key;

      if (key !== "Unknown") {
        position = capitalizePosition(key);
      }
      data.push(mpsAvg);
      labels.push(position);
    });
  }
  
  /**Position value --- */

  var ylabel = "";
  if (brainPosition == "principal-max-strain") {
    ylabel = "Avg. Max. Princ. Str.";
  } else if (brainPosition == "principal-min-strain") {
    ylabel = "Avg. Min. Princ. Str.";
  } else if (brainPosition == "csdm-15") {
    ylabel = "CSDM 15";
  }

  var maxval = 0.02;
  var yval = data.sort(function (a, b) {
    return a - b;
  });
  if (parseFloat(yval[0]) > 0.0) {
    var yval2 = data.sort(function (a, b) {
      return a - b;
    });
    maxval = parseFloat(yval2[0]);
  } else if (parseFloat(yval[0]) < 0.0) {
    var yval1 = data.sort(function (a, b) {
      return a - b;
    });
    maxval = parseFloat(yval1[0]);
  } else {
    maxval = 0.02;
  }

  const carrtData = {
    datasets: [
      {
        label: "Position",
        backgroundColor: "#0c68b2",
        borderColor: "#0c68b2",
        data: data,
      },
    ],
    labels: labels,
  };
  let options = {
    barThickness: 25,
    minBarLength: 2,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
      x: {
        grid: {
          offset: true,
        },
        title: {
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          font: {
            size: 9
          },
          text: ylabel,
        },
        min: 0,
        max: maxval,
		beginAtZero: true,
        ticks: {
          autoSkip: true,
        },
      },
    },
  };

  return (
    <div className="chart-wrapper">
      <Bar data={carrtData} options={options} />
    </div>
  );
};

export default PositionChart;
