import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Card,
  CardTitle,
  Spinner,
} from "reactstrap"
import { connect } from "react-redux"
import { Redirect, withRouter, Link } from "react-router-dom"
import { changeSidebarType, logout, activeSidebarMenu,setSingleNotifications,getAllnotification,readAllNotifications,getOrgUserSimulationsLeft } from "../../store/actions"
import "./header.scss"
import { People, ExclamationOctagon, BoxArrowRight } from 'react-bootstrap-icons';
import axios from "axios";

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      user: localStorage.getItem("authUser"),
      renderUrl:"",
      loadershow:false,
      simulation_list: 0,
      mesh_quote: 0,
    }

  }
  

  handleClickOutside = event => {
    let menuClass=document.querySelector('.mobile-menu').classList.contains('active')
    let currentClickedClass = event.target.className;
    // console.log('div',menuClass )
    // console.log('event.target.className',event.target.className)
    //console.log('event.target.className',event.target.attributes.getNamedItem('t').value )
      if(menuClass && currentClickedClass !="menuToggleBtn" && currentClickedClass!="mobile-menu active" && currentClickedClass!="nsf" && currentClickedClass!="nsf1" &&  currentClickedClass !="navbar-toggler" && currentClickedClass !="navbar-toggler-icon " && currentClickedClass !="menuToggleBtn"){
     
      this.props.activeSidebarMenu();
     }
  
 };
  async fetchSimulationQuotes() {
    // console.log('simulationQuotes----')
    let newUser = JSON.parse(this.state.user);
    const account_id = newUser._id;
    const simulationQuotes = await axios.get(`/auth/SimulationQuotes/${account_id}`);
    if (simulationQuotes.data.data.user !== null) {
      const { quote, mesh_quote } = simulationQuotes.data.data.user;

      const currentUser = localStorage.getItem('authUser')
      if (currentUser) {
        const parseCurrent = JSON.parse(currentUser)
        parseCurrent.subscription = simulationQuotes.data.data.subscription
        parseCurrent.subscription_quote = simulationQuotes.data.data.user
        localStorage.setItem('authUser', JSON.stringify(parseCurrent))
      }
      this.setState({
        simulation_list: quote,
        mesh_quote,
      });
    }
  }
  componentDidMount() {
    const { isOpen, user } = this.state;
    document.body.classList.add('dash-body')
    document.body.classList.remove('home-body')
    document.addEventListener("click", this.handleClickOutside,false);
    let _this = this;
    if (user) {
      let newUser = JSON.parse(user);
      var level = newUser.level;
      if(level== 100 || level==200 ||level==300){
        var data={
          user_id:newUser._id
        }
        
        this.props.getAllnotification(data)
        this.props.getOrgUserSimulationsLeft(data)

  
  
      }
    }
    this.fetchSimulationQuotes();
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }
  componentWillReceiveProps(props) {
  
    const {user} = this.state;
    //var notificationsFetchOneTime=true;
    if(props.notificationRead){
      if (user) {
        let newUser = JSON.parse(user);
        var level = newUser.level;
        if(level== 100 || level==200 ||level==300){
          var data={
            user_id:newUser._id
          }
          this.props.getAllnotification(data)
          this.setState({ loadershow: false})
          this.props.getOrgUserSimulationsLeft(data)
          //notificationsFetchOneTime= false
        }
      }
    }
  }
  toggleSidebar = () => {
    const { leftSideBarType } = this.props;
    if (leftSideBarType === 'maxmize') {
      this.props.changeSidebarType('default')
    } else {
      this.props.changeSidebarType('maxmize')
    }
  }
  closeNav(){
    document.getElementById("mySidenav").style.right = "-113%";
  }
  openNav(){
    var width =window.innerWidth;
      document.getElementById("mySidenav").style.right = "0px";
    
    
  }
  //dismiss all and read all
  UpdateNotification=async(val,user_id)=>{
    this.setState({ loadershow: true})
    var data={
      status:val,
      user_id:user_id
    }
    var dataVal={
      user_id:user_id
    }
    this.props.readAllNotifications({
      status:val,
      user_id:user_id,
    });
  }
  //cross individual notifications
  UpdateAndRedirect= async (val, user_id, url, name) => {
    this.props.setSingleNotifications({
      status: val,
      user_id: user_id,
      notification_name: name,
    });
    this.props.history.push(url)
  }
  UpdateSingleNotification=async(val,user_id,id,name)=>{
    this.setState({ loadershow: true})
    var _this=this;
    var dataVal={
      user_id:user_id
    }
    this.props.setSingleNotifications({
      status:val,
      user_id:user_id,
      notification_name:name,
      singleDismiss:1
    });

  }
  menuRedirection(e){
   
    var attribute = e.target.attributes.getNamedItem('rel').value;
    if(attribute =="signout"){
      this.props.logout();
      this.props.activeSidebarMenu();
      this.setState({ logoutClick: true })
    } 
    else if(attribute =="/login"){
      this.props.activeSidebarMenu();
      document.querySelector('body').classList.remove("mobile-menu-overlay");
      this.props.history.push(attribute)
    }
    else{
      this.props.activeSidebarMenu();
      this.props.history.push(attribute)
    }
  }

  render() {
    const { isOpen, user,renderUrl,loadershow, simulation_list, mesh_quote } = this.state;
    const { islogout, activeSidebarMenu, activeSidebar,notificationData,notificationCount,orgSimulationsCount } = this.props;
    if(this.props.activeSidebar){
      document.querySelector('body').classList.add("mobile-menu-overlay");
    }else{
      document.querySelector('body').classList.remove("mobile-menu-overlay");
    }
    let userRole = "Unknown";
    if (islogout)
      return (
        <Redirect
          to={{ pathname: "/home" }}
        />
      )
    let newUser = JSON.parse(user);
    var fullName = '';
    if (user) {
      var userName = newUser.first_name.substring(0, 1) + ' ' + newUser.last_name.substring(0, 1);
      fullName = newUser.first_name + ' ' + newUser.last_name;
      var level = newUser.level;
      var user_id=newUser._id;
      if(level && level == "1000"){
        userRole = "Admin";

      }
      else if(level && level == "300"){
        userRole = "Organization";

      }
      else if(level && level == "250"){
        userRole = "Institution ";

      }
      else if(level && level == "200"){
        userRole = "Team ";

      }
      else if(level && level == "150"){
        userRole = "Family";

      }
      else if(level && level == "100"){
        userRole = "Player ";

      }
    }
    return (
      <React.Fragment>
        <div className="header">
          <Container fluid>
            <Row className="align-items-center">
              <Col lg={2}>
                <div className="logo">
                  <Link to="/home">
                    <img src="/BrainSim.png" alt="img" className="logo-image" />
                  </Link>
                </div>
              </Col>
              <Col lg={10}>
                <div className="navbar-custom-menu">
                {level== 100 || level == 200  || level==300 ?
                  <ul className="nav notify">
                   <li className="nav-item notification">
                    {/* eslint-disable-next-line  */}
                      <a href="javascript:void(0);" onClick={() => this.openNav()}>
                        <svg id="Layer_2" enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m13.5 4.18c-.276 0-.5-.224-.5-.5v-1.68c0-.551-.449-1-1-1s-1 .449-1 1v1.68c0 .276-.224.5-.5.5s-.5-.223-.5-.5v-1.68c0-1.103.897-2 2-2s2 .897 2 2v1.68c0 .277-.224.5-.5.5z" /></g><g><path d="m12 24c-1.93 0-3.5-1.57-3.5-3.5 0-.276.224-.5.5-.5s.5.224.5.5c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5c0-.276.224-.5.5-.5s.5.224.5.5c0 1.93-1.57 3.5-3.5 3.5z" /></g><g><path d="m20.5 21h-17c-.827 0-1.5-.673-1.5-1.5 0-.439.191-.854.525-1.14 1.576-1.332 2.475-3.27 2.475-5.322v-3.038c0-3.86 3.14-7 7-7 3.86 0 7 3.14 7 7v3.038c0 2.053.899 3.99 2.467 5.315.342.293.533.708.533 1.147 0 .827-.672 1.5-1.5 1.5zm-8.5-17c-3.309 0-6 2.691-6 6v3.038c0 2.348-1.028 4.563-2.821 6.079-.115.098-.179.237-.179.383 0 .276.224.5.5.5h17c.276 0 .5-.224.5-.5 0-.146-.064-.285-.175-.38-1.796-1.519-2.825-3.735-2.825-6.082v-3.038c0-3.309-2.691-6-6-6z" /></g></svg>
                        <span className="label label-warning">{notificationCount}</span>
                      </a>
                   
                  
                    </li>
                  </ul>
                  :null}
                  {/* <Button variant="primary" className="toggle-btn" onClick={() => activeSidebarMenu()}><img src="/toggle.png" alt="img" /></Button>{' '} */}
                  <div className="navbar-toggler toggle-btn" onClick={() => activeSidebarMenu()}>
                    <span className="navbar-toggler-icon " ><img src="/toggle.png" alt="toggle" className="menuToggleBtn"/></span>
                  </div>
                  <div className="desktop-menu">
                    <ul className="nav">
                      <li className="nav-item active">
                        <Link className="nav-link" to="/home">{" "}Home{" "}</Link>{" "}
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/how-it-works">{" "}How It Works {" "}</Link>{" "}
                      </li>
                      <li className="nav-item ">
                        <Link className="nav-link" to="/technical-details">{" "}Technical Details{" "}</Link>{" "}
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/contact">{" "}Contact Us{" "}</Link>{" "}
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/user/dashboard">{" "}Dashboard{" "}</Link>{" "}
                      </li>
                      {level === 300 || level === 200 ?
                        <li className="simulations-count">
                          <div>
                            <span>Simulations Left : {simulation_list}</span>
                            <Link className="nav-link" to="/subscription/plan">{" "}Upgrade Now{" "}</Link>{" "}

                            {/* <span>Simulations Left : {simulation_list}</span>
                          <Link className="nav-link" to="/subscription/plan">{" "}Upgrade Now{" "}</Link>{" "} */}
                          </div>
                        </li>
                      :null}
                      {level === 100 ? (
                        <li className="simulations-buy">
                          <Link className="nav-link" to="/subscription/plan">Buy Now</Link>
                        </li>
                      ) : null}
                      {level== 100 || level==200 || level==300 ?
                        <li className="dropdown notifications-menu">
                          {/* eslint-disable-next-line  */}
                          <a href="javascript:void(0);" onClick={() => this.openNav()}>
                            <svg id="Layer_2" enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m13.5 4.18c-.276 0-.5-.224-.5-.5v-1.68c0-.551-.449-1-1-1s-1 .449-1 1v1.68c0 .276-.224.5-.5.5s-.5-.223-.5-.5v-1.68c0-1.103.897-2 2-2s2 .897 2 2v1.68c0 .277-.224.5-.5.5z" /></g><g><path d="m12 24c-1.93 0-3.5-1.57-3.5-3.5 0-.276.224-.5.5-.5s.5.224.5.5c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5c0-.276.224-.5.5-.5s.5.224.5.5c0 1.93-1.57 3.5-3.5 3.5z" /></g><g><path d="m20.5 21h-17c-.827 0-1.5-.673-1.5-1.5 0-.439.191-.854.525-1.14 1.576-1.332 2.475-3.27 2.475-5.322v-3.038c0-3.86 3.14-7 7-7 3.86 0 7 3.14 7 7v3.038c0 2.053.899 3.99 2.467 5.315.342.293.533.708.533 1.147 0 .827-.672 1.5-1.5 1.5zm-8.5-17c-3.309 0-6 2.691-6 6v3.038c0 2.348-1.028 4.563-2.821 6.079-.115.098-.179.237-.179.383 0 .276.224.5.5.5h17c.276 0 .5-.224.5-.5 0-.146-.064-.285-.175-.38-1.796-1.519-2.825-3.735-2.825-6.082v-3.038c0-3.309-2.691-6-6-6z" /></g></svg>
                            <span className="label label-warning">{notificationCount}</span>
                          </a>
                        </li> 
                      :null}
                      <li className="dropdown user user-menu" onClick={() => this.setState({ isOpen: isOpen ? false : true })}>
                        {/* eslint-disable-next-line  */}
                        <a href="#" className="user-pr-pop">
                          <span className="hidden-xs" style={{ textTransform: 'capitalize' }}>{fullName}</span>
                          <Dropdown isOpen={isOpen} toggle={() => this.setState({ isOpen: isOpen ? false : true })}>
                            <DropdownToggle
                              style={{
                                'background': 'none',
                                'border': 'none',
                                'padding': 0,
                                'margin': 0,
                              }}
                            >
                              <div className="rounded-circle profile-user-name">{userName}</div>
                              {/* <img src="/profile.png" className="rounded-circle " alt="User Image" /> */}
                            </DropdownToggle>
                            <DropdownMenu

                              modifiers={{
                                setMaxHeight: {
                                  enabled: true,
                                  order: 890,
                                  fn: (data) => {
                                    return {
                                      ...data,
                                      styles: {
                                        ...data.styles,
                                        overflow: 'auto',
                                      },
                                    };
                                  },
                                },
                              }}
                            >
                              <Card body
                                style={{
                                  'border': 'none'
                                }}
                              >
                                <div>
                                  <div className='user-pf-pop-head'>

                                    <div className="rounded-circle profile-user-name-2">{userName}</div>
                                    <div style={{ display: 'inline-block'}} className="pl-pf-dt">
                                      {fullName}<br />
                                      {<p>{userRole}</p> }
                                    </div>
                                  </div>
                                  <div>
                                    <Link to={"/profile/" + newUser._id} className="user-pf-item"><People size={20} />Profile</Link>
                                    <Link to={"/contact"} className="user-pf-item"><ExclamationOctagon size={30} />Report a problem or request a feature</Link>
                                    <Link to={"#"} onClick={() => this.props.logout()} className="user-pf-item"><BoxArrowRight size={20} />Sign out</Link>
                                  </div>
                                </div>
                                {/* <CardTitle tag="h5" style={{ textAlign: 'center' }}>
                                  <Link to={"/profile/" + newUser._id} style={{ 'display': 'block', color: '#000' }}>Profile</Link>
                                </CardTitle>
                                <Button onClick={() => this.props.logout()}>Sign out</Button> */}
                              </Card>
                            </DropdownMenu>
                          </Dropdown>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className={activeSidebar ? "mobile-menu active" : "mobile-menu"}>
                  <div className="nsf1">
                    <div className="nsf">
                      <Button variant="primary" className="close-btn" onClick={() => activeSidebarMenu()}>
                      <img src="/ic-cross.png" />
                        {/* <svg height="365.696pt" viewBox="0 0 365.696 365.696" width="365.696pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" /></svg> */}
                      </Button>{' '}
                      {/* <span>nsf</span> */}
                    </div>
                    <ul className="nav">
                    <li className="nav-item active" >
                        <Link className="nav-link home-menu" rel="/home"  onClick={(e) => this.menuRedirection(e)}>{" "}Home{" "}</Link>{" "}
                        {/* <Button className="nav-link" variant="link" rel="/home" onClick={(e) => this.menuRedirection(e)}>{" "}Home{" "}</Button> */}
                      </li>
                     
                      <li className="nav-item" >
                        <Link className="nav-link howitwork-menu" rel="/how-it-works"  onClick={(e) => this.menuRedirection(e)}>{" "}How It Works {" "}</Link>{" "}
                        {/* <Button className="nav-link" variant="link" rel="/how-it-works" onClick={(e) => this.menuRedirection(e)}>{" "}How It Works{" "}</Button> */}
                      </li>
                      <li className="nav-item " >
                        <Link className="nav-link validtion-menu" rel="/technical-details"  onClick={(e) => this.menuRedirection(e)}>{" "}Technical Details{" "}</Link>{" "}
                        {/* <Button className="nav-link" variant="link" rel="/validation" onClick={(e) => this.menuRedirection(e)}>{" "}Validation{" "}</Button> */}
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link contact-menu" rel="/contact"  onClick={(e) => this.menuRedirection(e)}>{" "}Contact Us{" "}</Link>{" "}
                        {/* <Button className="nav-link" variant="link" rel="/contact" onClick={(e) => this.menuRedirection(e)}>{" "}Contact Us{" "}</Button> */}
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link dashboard-menu" rel="/user/dashboard"  onClick={(e) => this.menuRedirection(e)}>{" "}Dashboard{" "}</Link>{" "}
                        {/* <Button className="nav-link" variant="link" rel="/user/dashboard" onClick={(e) => this.menuRedirection(e)}>{" "}Dashboard{" "}</Button> */}
                      </li>
                      {level === 300 || level === 200 ?
                      <li className="simulations-count">
                        <div>
                          <span>Simulations Left : {simulation_list}</span>
                        <Link className="nav-link" to="/subscription/plan">{" "}Upgrade Now{" "}</Link>{" "}
                        </div>
                      </li>
                      :null}
                      {level === 100 ? (
                        <li className="simulations-buy">
                          <Link className="nav-link buy-now" to="/subscription/plan">Buy Now</Link>
                        </li>
                      ) : null}
                      {
                        user &&
                        <li className="nav-item">
                          <Link className="nav-link profile-menu" rel={"/profile/" + newUser._id}  onClick={(e) => this.menuRedirection(e)}>Profile</Link>
                          {/* <Button className="nav-link" variant="link" rel={"/profile/" + newUser._id} onClick={(e) => this.menuRedirection(e)}>{" "}Profile{" "}</Button> */}
                        </li>
                      }
                      <li className="nav-item">
                        {
                          user ?
                             <Link to={"#"} className="nav-link sign-menu" rel="signout"   onClick={(e) => this.menuRedirection(e)}>  Sign out</Link>
                            // <Button className="nav-link" variant="link" onClick={() => this.props.logout()} rel="signout" onClick={(e) =>this.menuRedirection(e)}>Sign out</Button>
                            :
                             <Link className="nav-link sign-menu" rel="/login"  onClick={(e) =>this.menuRedirection(e)}>Sign In</Link>
                            // <Button className="nav-link" variant="link" rel="/login" onClick={(e) =>this.menuRedirection(e)}>Sign In</Button>

                        }
                      </li>
                      
                      
                    </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div id="mySidenav" class="sidenav">
              <a href="javascript:void(0);" onClick={() => this.closeNav()}class="closebtn">&times;</a>
              
              <ul className="notification-heading">
                <h4>Notifications</h4>
                <li><a href="javascript:void(0);" onClick={() => this.UpdateNotification(1,user_id)}>Mark read all</a></li>
              </ul>
              <Button className="close-btn dismiss-all" type="button" onClick={() => this.UpdateNotification(2,user_id)}>
                Dismiss All &times;
              </Button>
              {loadershow?
                 <Spinner color="primary" />
              : 
             
                <ul className="notification-content">
                {
                  notificationData ?
                  notificationData.map((item, index) => {
                      if (item.length < 1) return null
                      let name;
                      let link;
                      let clickEvent;
                      let notificationName;
                      if(item.notification_name=="welcome-board"){
                        name="Welcome On Board";
                        link = "/home"
                        notificationName="home"
                        clickEvent=1

                      }else if(item.notification_name=="complete-profile"){
                        name="Complete Your Profile"
                        link = "/profile/" + user_id+"/contact"
                        notificationName="contact"
                        clickEvent=1
                      }else if(item.notification_name=="your-dashboard"){
                        name="Check Your Dashboard"
                        link = "/player/dashboard/" + user_id
                        clickEvent=1
                        notificationName="dashboard"

                      }
                      else if(item.notification_name=="verify-phone"){
                        name="Verify phone number"
                        link = "/profile/" + user_id+'/contact'
                        notificationName="contact"

                      }
                      else if(item.notification_name=="verify-email"){
                        name="Verify Email"
                        link = "/profile/" + user_id+'/contact'
                        notificationName="contact"

                      }
                      else if(item.notification_name=="register-sensor"){
                        name="Register your sensor"
                        link = "/profile/" + user_id+'/sensor_position_info'
                        notificationName="sensor_position_info"

                      }
                      else if(item.notification_name=="sport-position"){
                        name="Select your sport and position"
                        link = "/profile/" + user_id+'/sensor_position_info'
                        notificationName="sensor_position_info"


                      }
                      else if(item.notification_name=="customize-model"){
                        name="Create your customized computer model"
                        link = "/profile/" + user_id+'/sensor_info'
                        notificationName="sensor_info"

                      }
                      else if(item.notification_name=="communication-preferences"){
                        name="Review your notifications"
                        link = "/profile/" + user_id+'/notifications'
                        clickEvent=1
                        notificationName="communication_preferences";
                      }
                      else if( item.notification_name == "mesh-quote" ){
                        name=`You have ${mesh_quote} mesh ${mesh_quote > 1 ? 'quotes' : 'quote'} available`
                        link = "/buy-mesh"
                        clickEvent=1
                        notificationName="meshquote";
                      }
                      return (
                        <li data_id={item._id} data_user_id={item.user_id} >  
                          {item.status ==1 ? <img src="/notification-tick.png" className="notification-tick"/> : null }
                          {item.notification_name  == "mesh-quote" ?
                            <b onClick={() => this.UpdateAndRedirect(1, user_id, link, item.notification_name)}>{name}</b>
                            :
                              <Link to={{ pathname:link }}><b>{name}</b> </Link>                            
                          }
                          <span  onClick={() => this.UpdateSingleNotification(2,user_id,item._id,item.notification_name)}><img src="/notifications-cross.png" className="notification-crossbtn" /></span>
                        </li>
                      )
                    })
                  : null
                }
                </ul>
              }
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}


const mapStateToProps = state => {
  return {
    leftSideBarType: state.Layout.leftSideBarType,
    error: state.Login.error,
    islogout: state.Login.islogout,
    activeSidebar: state.Layout.activeSidebar,
    notificationCount: state.Layout.notificationCount,
    notificationRead: state.Layout.notificationRead,
    notificationData: state.Layout.notificationData,
    notificationAvail: state.Layout.notificationAvail,
    orgSimulationsCount:state.Layout.orgSimulationsCount
  }
}
const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    changeSidebarType: (type) => dispatch(changeSidebarType(type)),
    logout: () => dispatch(logout()),
    activeSidebarMenu: () => dispatch(activeSidebarMenu()),
    getAllnotification: (body) => dispatch(getAllnotification(body)),
    setSingleNotifications: (body) => dispatch(setSingleNotifications(body)),
    readAllNotifications: (body) => dispatch(readAllNotifications(body)),
    getOrgUserSimulationsLeft: (body) => dispatch(getOrgUserSimulationsLeft(body))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))

