import React from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Bar } from "react-chartjs-2";
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import "./dash.css";
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
// import summary from "./summary_york_tech_35204.json";
import { CustomSelect } from "../../components/PopUp/CustomSelect"
import { relativeTimeThreshold } from "moment";
import { getMaxValOfBrainEffect } from "../../store/actions"
//import { setMpsValues } from "../../../store/actions"
// const insults = summary.Insults;
const ENABLE_COLOR_SPHERES = true;
const sphereGeo = new THREE.SphereGeometry(0.003, 12, 6);
const sphereMat = new THREE.MeshStandardMaterial({
  color: 0xff0000,
});
const SMALL_BOUNDARY = 0.1;
const MEDIUM_BOUNDARY = 0.18;
const LARGE_BOUNDARY = 0.3;
const SMALL_COLOR = new THREE.Color(0x00b050);
const MEDIUM_COLOR = new THREE.Color(0xed7d31);
const LARGE_COLOR = new THREE.Color(0xff0000);
const X_LARGE_COLOR = new THREE.Color(0x000000);
const SMALL_GEOMETRY = new THREE.SphereGeometry(0.0015, 12, 6);
const MEDIUM_GEOMETRY = new THREE.SphereGeometry(0.002, 12, 6);
const LARGE_GEOMETRY = new THREE.SphereGeometry(0.003, 12, 6);
const X_LARGE_GEOMETRY = new THREE.SphereGeometry(0.004, 12, 6);

// let stem_json = [];
// let pariental_lobe_json = [];
// let frontal_lobe_json = [];
// let cerebellum_lobe_json = [];
// let middle_part_of_the_brain_json = [];
// let occipital_lobe_json = [];
// let temporal_lobe_json = [];
// let middle_part_of_the_brain_min_json = []
let minimumPS = [[], [], [], [], [], [], []];
let maximumPS = [[], [], [], [], [], [], []];
let csdm_15 = [[], [], [], [], [], [], []];
let csdm_5 = [[], [], [], [], [], [], []];
let csdm_10 = [[], [], [], [], [], [], []];
let previousClicked = null;
// json parse
// insults.forEach((insult) => {
//   if (insult["principal-max-strain"]) {
//     switch (insult["principal-max-strain"]["brain-region"]) {
//       case "msc":
//         maximumPS[6] = [
//           {
//             x: insult["principal-max-strain"]["location"][0],
//             y: insult["principal-max-strain"]["location"][1],
//             z: insult["principal-max-strain"]["location"][2],
//             value: insult["principal-max-strain"]["value"],
//           },
//           ...maximumPS[6],
//         ];
//         break;
//       case "stem":
//         maximumPS[5] = [
//           {
//             x: insult["principal-max-strain"]["location"][0],
//             y: insult["principal-max-strain"]["location"][1],
//             z: insult["principal-max-strain"]["location"][2],
//             value: insult["principal-max-strain"]["value"],
//           },
//           ...maximumPS[5],
//         ];
//         break;
//       case "cerebellum":
//         maximumPS[4] = [
//           {
//             x: insult["principal-max-strain"]["location"][0],
//             y: insult["principal-max-strain"]["location"][1],
//             z: insult["principal-max-strain"]["location"][2],
//             value: insult["principal-max-strain"]["value"],
//           },
//           ...maximumPS[4],
//         ];
//         break;
//       case "temporal":
//         maximumPS[3] = [
//           {
//             x: insult["principal-max-strain"]["location"][0],
//             y: insult["principal-max-strain"]["location"][1],
//             z: insult["principal-max-strain"]["location"][2],
//             value: insult["principal-max-strain"]["value"],
//           },
//           ...maximumPS[3],
//         ];
//         break;
//       case "occipital":
//         maximumPS[2] = [
//           {
//             x: insult["principal-max-strain"]["location"][0],
//             y: insult["principal-max-strain"]["location"][1],
//             z: insult["principal-max-strain"]["location"][2],
//             value: insult["principal-max-strain"]["value"],
//           },
//           ...maximumPS[2],
//         ];
//         break;
//       case "parietal":
//         maximumPS[1] = [
//           {
//             x: insult["principal-max-strain"]["location"][0],
//             y: insult["principal-max-strain"]["location"][1],
//             z: insult["principal-max-strain"]["location"][2],
//             value: insult["principal-max-strain"]["value"],
//           },
//           ...maximumPS[1],
//         ];
//         break;
//       case "frontal":
//         maximumPS[0] = [
//           {
//             x: insult["principal-max-strain"]["location"][0],
//             y: insult["principal-max-strain"]["location"][1],
//             z: insult["principal-max-strain"]["location"][2],
//             value: insult["principal-max-strain"]["value"],
//           },
//           ...maximumPS[0],
//         ];
//         break;
//       default:
//         break;
//     }
//   }
//   if (insult["principal-min-strain"]) {
//     switch (insult["principal-min-strain"]["brain-region"]) {
//       case "msc":
//         minimumPS[6] = [
//           {
//             x: insult["principal-min-strain"]["location"][0],
//             y: insult["principal-min-strain"]["location"][1],
//             z: insult["principal-min-strain"]["location"][2],
//             value: insult["principal-min-strain"]["value"],
//           },
//           ...minimumPS[6],
//         ];
//         break;
//       case "stem":
//         minimumPS[5] = [
//           {
//             x: insult["principal-min-strain"]["location"][0],
//             y: insult["principal-min-strain"]["location"][1],
//             z: insult["principal-min-strain"]["location"][2],
//             value: insult["principal-min-strain"]["value"],
//           },
//           ...minimumPS[5],
//         ];
//         break;
//       case "cerebellum":
//         minimumPS[4] = [
//           {
//             x: insult["principal-min-strain"]["location"][0],
//             y: insult["principal-min-strain"]["location"][1],
//             z: insult["principal-min-strain"]["location"][2],
//             value: insult["principal-min-strain"]["value"],
//           },
//           ...minimumPS[4],
//         ];
//         break;
//       case "temporal":
//         minimumPS[3] = [
//           {
//             x: insult["principal-min-strain"]["location"][0],
//             y: insult["principal-min-strain"]["location"][1],
//             z: insult["principal-min-strain"]["location"][2],
//             value: insult["principal-min-strain"]["value"],
//           },
//           ...minimumPS[3],
//         ];
//         break;
//       case "occipital":
//         minimumPS[2] = [
//           {
//             x: insult["principal-min-strain"]["location"][0],
//             y: insult["principal-min-strain"]["location"][1],
//             z: insult["principal-min-strain"]["location"][2],
//             value: insult["principal-min-strain"]["value"],
//           },
//           ...minimumPS[2],
//         ];
//         break;
//       case "parietal":
//         minimumPS[1] = [
//           {
//             x: insult["principal-min-strain"]["location"][0],
//             y: insult["principal-min-strain"]["location"][1],
//             z: insult["principal-min-strain"]["location"][2],
//             value: insult["principal-min-strain"]["value"],
//           },
//           ...minimumPS[1],
//         ];
//         break;
//       case "frontal":
//         minimumPS[0] = [
//           {
//             x: insult["principal-min-strain"]["location"][0],
//             y: insult["principal-min-strain"]["location"][1],
//             z: insult["principal-min-strain"]["location"][2],
//             value: insult["principal-min-strain"]["value"],
//           },
//           ...minimumPS[0],
//         ];
//         break;
//       default:
//         break;
//     }
//   }
//   if (insult["CSDM-15"]) {
//     switch (insult["CSDM-15"]["brain-region"]) {
//       case "msc":
//         csdm_15[6] = [
//           {
//             x: insult["CSDM-15"]["location"][0],
//             y: insult["CSDM-15"]["location"][1],
//             z: insult["CSDM-15"]["location"][2],
//             value: insult["CSDM-15"]["value"],
//           },
//           ...csdm_15[6],
//         ];
//         break;
//       case "stem":
//         csdm_15[5] = [
//           {
//             x: insult["CSDM-15"]["location"][0],
//             y: insult["CSDM-15"]["location"][1],
//             z: insult["CSDM-15"]["location"][2],
//             value: insult["CSDM-15"]["value"],
//           },
//           ...csdm_15[5],
//         ];
//         break;
//       case "cerebellum":
//         csdm_15[4] = [
//           {
//             x: insult["CSDM-15"]["location"][0],
//             y: insult["CSDM-15"]["location"][1],
//             z: insult["CSDM-15"]["location"][2],
//             value: insult["CSDM-15"]["value"],
//           },
//           ...csdm_15[4],
//         ];
//         break;
//       case "temporal":
//         csdm_15[3] = [
//           {
//             x: insult["CSDM-15"]["location"][0],
//             y: insult["CSDM-15"]["location"][1],
//             z: insult["CSDM-15"]["location"][2],
//             value: insult["CSDM-15"]["value"],
//           },
//           ...csdm_15[3],
//         ];
//         break;
//       case "occipital":
//         csdm_15[2] = [
//           {
//             x: insult["CSDM-15"]["location"][0],
//             y: insult["CSDM-15"]["location"][1],
//             z: insult["CSDM-15"]["location"][2],
//             value: insult["CSDM-15"]["value"],
//           },
//           ...csdm_15[2],
//         ];
//         break;
//       case "parietal":
//         csdm_15[1] = [
//           {
//             x: insult["CSDM-15"]["location"][0],
//             y: insult["CSDM-15"]["location"][1],
//             z: insult["CSDM-15"]["location"][2],
//             value: insult["CSDM-15"]["value"],
//           },
//           ...csdm_15[1],
//         ];
//         break;
//       case "frontal":
//         csdm_15[0] = [
//           {
//             x: insult["CSDM-15"]["location"][0],
//             y: insult["CSDM-15"]["location"][1],
//             z: insult["CSDM-15"]["location"][2],
//             value: insult["CSDM-15"]["value"],
//           },
//           ...csdm_15[0],
//         ];
//         break;
//       default:
//         break;
//     }
//   }
//   if (insult["CSDM-5"]) {
//     switch (insult["CSDM-5"]["brain-region"]) {
//       case "msc":
//         csdm_5[6] = [
//           {
//             x: insult["CSDM-5"]["location"][0],
//             y: insult["CSDM-5"]["location"][1],
//             z: insult["CSDM-5"]["location"][2],
//             value: insult["CSDM-5"]["value"],
//           },
//           ...csdm_5[6],
//         ];
//         break;
//       case "stem":
//         csdm_5[5] = [
//           {
//             x: insult["CSDM-5"]["location"][0],
//             y: insult["CSDM-5"]["location"][1],
//             z: insult["CSDM-5"]["location"][2],
//             value: insult["CSDM-5"]["value"],
//           },
//           ...csdm_5[5],
//         ];
//         break;
//       case "cerebellum":
//         csdm_5[4] = [
//           {
//             x: insult["CSDM-5"]["location"][0],
//             y: insult["CSDM-5"]["location"][1],
//             z: insult["CSDM-5"]["location"][2],
//             value: insult["CSDM-5"]["value"],
//           },
//           ...csdm_5[4],
//         ];
//         break;
//       case "temporal":
//         csdm_5[3] = [
//           {
//             x: insult["CSDM-5"]["location"][0],
//             y: insult["CSDM-5"]["location"][1],
//             z: insult["CSDM-5"]["location"][2],
//             value: insult["CSDM-5"]["value"],
//           },
//           ...csdm_5[3],
//         ];
//         break;
//       case "occipital":
//         csdm_5[2] = [
//           {
//             x: insult["CSDM-5"]["location"][0],
//             y: insult["CSDM-5"]["location"][1],
//             z: insult["CSDM-5"]["location"][2],
//             value: insult["CSDM-5"]["value"],
//           },
//           ...csdm_5[2],
//         ];
//         break;
//       case "parietal":
//         csdm_5[1] = [
//           {
//             x: insult["CSDM-5"]["location"][0],
//             y: insult["CSDM-5"]["location"][1],
//             z: insult["CSDM-5"]["location"][2],
//             value: insult["CSDM-5"]["value"],
//           },
//           ...csdm_5[1],
//         ];
//         break;
//       case "frontal":
//         csdm_5[0] = [
//           {
//             x: insult["CSDM-5"]["location"][0],
//             y: insult["CSDM-5"]["location"][1],
//             z: insult["CSDM-5"]["location"][2],
//             value: insult["CSDM-5"]["value"],
//           },
//           ...csdm_5[0],
//         ];
//         break;
//       default:
//         break;
//     }
//   }
//   if (insult["CSDM-10"]) {
//     switch (insult["CSDM-10"]["brain-region"]) {
//       case "msc":
//         csdm_10[6] = [
//           {
//             x: insult["CSDM-10"]["location"][0],
//             y: insult["CSDM-10"]["location"][1],
//             z: insult["CSDM-10"]["location"][2],
//             value: insult["CSDM-10"]["value"],
//           },
//           ...csdm_10[6],
//         ];
//         break;
//       case "stem":
//         csdm_10[5] = [
//           {
//             x: insult["CSDM-10"]["location"][0],
//             y: insult["CSDM-10"]["location"][1],
//             z: insult["CSDM-10"]["location"][2],
//             value: insult["CSDM-10"]["value"],
//           },
//           ...csdm_10[5],
//         ];
//         break;
//       case "cerebellum":
//         csdm_10[4] = [
//           {
//             x: insult["CSDM-10"]["location"][0],
//             y: insult["CSDM-10"]["location"][1],
//             z: insult["CSDM-10"]["location"][2],
//             value: insult["CSDM-10"]["value"],
//           },
//           ...csdm_10[4],
//         ];
//         break;
//       case "temporal":
//         csdm_10[3] = [
//           {
//             x: insult["CSDM-10"]["location"][0],
//             y: insult["CSDM-10"]["location"][1],
//             z: insult["CSDM-10"]["location"][2],
//             value: insult["CSDM-10"]["value"],
//           },
//           ...csdm_10[3],
//         ];
//         break;
//       case "occipital":
//         csdm_10[2] = [
//           {
//             x: insult["CSDM-10"]["location"][0],
//             y: insult["CSDM-10"]["location"][1],
//             z: insult["CSDM-10"]["location"][2],
//             value: insult["CSDM-10"]["value"],
//           },
//           ...csdm_10[2],
//         ];
//         break;
//       case "parietal":
//         csdm_10[1] = [
//           {
//             x: insult["CSDM-10"]["location"][0],
//             y: insult["CSDM-10"]["location"][1],
//             z: insult["CSDM-10"]["location"][2],
//             value: insult["CSDM-10"]["value"],
//           },
//           ...csdm_10[1],
//         ];
//         break;
//       case "frontal":
//         csdm_10[0] = [
//           {
//             x: insult["CSDM-10"]["location"][0],
//             y: insult["CSDM-10"]["location"][1],
//             z: insult["CSDM-10"]["location"][2],
//             value: insult["CSDM-10"]["value"],
//           },
//           ...csdm_10[0],
//         ];
//         break;
//       default:
//         break;
//     }
//   }
// });
// console.log('minimumPS ------------', minimumPS)
Chart.register(ChartDataLabels);
let all_spheres_json = null;
// all_spheres_json = [...maximumPS];
let camera,
  scene,
  renderer,
  canvas,
  raycaster,
  root,
  sphereContainer,
  labelSize = 10,
  lobeButtonClicked = false;
let brainModel;
let aspectRatio,
  width,
  height,
  currentSubCamera,
  initialRatio,
  prevCanvasWidth,
  prevCanvasHeight;
const defaultTransparency = 0.3;
const highlightTransparency = 0.4;
const defaultColor = 0x7a5a16;
const highlightColor = 0xadab24;
const highlightEmissiveIntensity = 0.6;
const amount = 2;
const space = 10;
const near = 0.1;
const far = 100;
const cameraAttArr = [
  {
    x: 0,
    y: 0,
    rotX: 0,
    rotY: -Math.PI / 2,
    rotZ: 0,
    fov: 12,
  },
  {
    x: 1,
    y: 0,
    rotX: -Math.PI / 15,
    rotY: -Math.PI / 5,
    rotZ: 0,
    fov: 12,
  },
  {
    x: 1,
    y: 1,
    rotX: -Math.PI / 2,
    rotY: 0,
    rotZ: 0,
    fov: 12,
  },
];
const defaultCamAtt = {
  x: -1,
  y: -1,
  rotX: 0,
  rotY: 0,
  rotZ: 0,
  fov: 10,
};
const pickPosition = { x: 0, y: 0 };
raycaster = new THREE.Raycaster();
let pickedObject = null;
let prevPickedObject = null;
let pickedObjectSavedColor = 0;
let defaultBarColors = [
  "#7CB5EC",
  "#7CB5EC",
  "#7CB5EC",
  "#7CB5EC",
  "#7CB5EC",
  "#7CB5EC",
  "#7CB5EC",
];

//Use this flag to prevent resesting spheres on the scene
var isReset = false;

var instancedMeshArr = [];

class DashPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.spheres_selected = "All";
    this.state = {
      selected_region: "",
      selected_strain_metric: "max-ps",
      isLoading: true,
      barColors: defaultBarColors,
      loadedActionButtons: false,
      chartHovered: false,
      spheres_selected: "All",
      colored_spheres_clicked: "All",
      actionButtons: [
        {
          id: "motor_and_sensor_cortex",
          name: "Motor and Sensor Cortex",
          shortenName: "MSC",
        },
        {
          id: "stem_btn",
          name: "Stem",
          shortenName: "Stem",
        },
        {
          id: "cerebellum_btn",
          name: "Cerebellum Lobe",
          shortenName: "Cer",
        },
        {
          id: "temporal_btn",
          name: "Temporal Lobe",
          shortenName: "Temp",
        },
        {
          id: "occipital_btn",
          name: "Occipital Lobe",
          shortenName: "Occ",
        },
        {
          id: "pariental_btn",
          name: "Parietal Lobe",
          shortenName: "Par",
        },
        {
          id: "front_btn",
          name: "Frontal Lobe",
          shortenName: "Front",
        },
      ],
      actionButtonPositions: [],
      selectOption: [
        { name: "Principal Strain Max", value: "max-ps" },
        { name: "Principal Strain Min", value: "min-ps" },
        { name: "CSDM-15", value: "csdm_15" },
        { name: "CSDM-5", value: "csdm_5" },
        { name: "CSDM-10", value: "csdm_10" },
      ],
    };
    this.plugins = [
      {
        afterDraw: (chart) => {
          if (this.state.loadedActionButtons) return;
          setTimeout(() => this.afterDrawChart(chart), 2000);
        },
      },
    ];
    this.dashContainer = React.createRef();
  }

  componentDidMount() {
    // Scrolling the screen to top
    //window.scrollTo(0, 0);
    //Scrolling the screen to show the middle of the dashboard on desktop
    if (window.innerWidth > 1278) {
      const element = document.querySelector('.purpo')
      if (element) {
        const rect = element.getBoundingClientRect() // get rects(width, height, top, etc)
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        window.scroll({
          top: rect.top + rect.height / 2 - viewHeight / 2,
          behavior: 'smooth' // smooth scroll
        });
      }
      else {
        window.scrollTo(0, 0);
      }
    }

    const { data_minimumPS, data_maximumPS, data_csdm_15, data_csdm_5, data_csdm_10, pressure_dashboard } = this.props;

    if (pressure_dashboard) {
      this.setState({
        selectOption: [
          { name: "Max Intracranial Pressure", value: "max-ps" },
          { name: "Min Intracranial Pressure", value: "min-ps" },
          { name: "CSDM-15", value: "csdm_15" },
          { name: "CSDM-5", value: "csdm_5" },
          { name: "CSDM-10", value: "csdm_10" },
        ]
      });
    } else {
      this.setState({
        selectOption: [
          { name: "Principal Strain Max", value: "max-ps" },
          { name: "Principal Strain Min", value: "min-ps" },
          { name: "CSDM-15", value: "csdm_15" },
          { name: "CSDM-5", value: "csdm_5" },
          { name: "CSDM-10", value: "csdm_10" },
        ]
      });

    }
    /**
     * Assign value in all metrics variables...
     */
    minimumPS = data_minimumPS;
    maximumPS = data_maximumPS;
    csdm_15 = data_csdm_15;
    csdm_5 = data_csdm_5;
    csdm_10 = data_csdm_10;
    all_spheres_json = [...maximumPS];
    this.set_filtered_spheres_ids();
    console.log('all_spheres_json ----', all_spheres_json)
    console.log('minimumPS ---- 2', minimumPS)
    console.log('maximumPS ---- 2', maximumPS)
    console.log('csdm_15 ---- 2', csdm_15)
    console.log('csdm_5 ---- 2', csdm_5)
    console.log('csdm_10 ---- 2', csdm_10)
    let empty;
    let metricVal = {
      name : "Principal Strain Max",
      value : "max-ps"
    }
    this.strainMetric(empty, metricVal)
    //..
    this.init();
    window.addEventListener("resize", this.onWindowResize, false);
    window.addEventListener("mousemove", this.onMouseMove, false);
    window.addEventListener("mouseout", this.onMouseOut, false);
    window.addEventListener("mouseleave", this.onMouseLeave, false);
    window.addEventListener("touchstart", this.onTouchStart, false);
    window.addEventListener("touchmove", this.onTouchMove, false);
    window.addEventListener("touchend", this.onTouchEnd, false);
    this.startAnimationLoop();
  }
  componentDidUpdate() {
    if (
      !this.state.loadedActionButtons &&
      this.state.actionButtonPositions.length !== 0
    ) {
      this.setState({
        loadedActionButtons: true,
      });
      let me = this;
      //Highlight brain model on mouse hover on brain button
      document.getElementById("front_btn").addEventListener(
        "mouseover",
        function (event) {
          me.onMouseHover(event, "Frontal_Lobe_node_Frontal_Lobe");
        },
        false
      );
      document.getElementById("front_btn").addEventListener(
        "click",
        function (event) {
          me.onMouseClick(event, "Frontal_Lobe_node_Frontal_Lobe");
        },
        false
      );
      document.getElementById("pariental_btn").addEventListener(
        "mouseover",
        function (event) {
          me.onMouseHover(
            event,
            "Cerebral_hemispheres_R_node_Cerebral_hemispheres_R"
          );
        },
        false
      );
      document.getElementById("pariental_btn").addEventListener(
        "click",
        function (event) {
          me.onMouseClick(
            event,
            "Cerebral_hemispheres_R_node_Cerebral_hemispheres_R"
          );
        },
        false
      );
      document.getElementById("occipital_btn").addEventListener(
        "mouseover",
        function (event) {
          me.onMouseHover(event, "node_Mesh_16");
        },
        false
      );
      document.getElementById("occipital_btn").addEventListener(
        "click",
        function (event) {
          me.onMouseClick(event, "node_Mesh_16");
        },
        false
      );
      document.getElementById("temporal_btn").addEventListener(
        "mouseover",
        function (event) {
          me.onMouseHover(event, "Temporal_Lobe_node_Temporal_Lobe");
        },
        false
      );
      document.getElementById("temporal_btn").addEventListener(
        "click",
        function (event) {
          me.onMouseClick(event, "Temporal_Lobe_node_Temporal_Lobe");
        },
        false
      );
      document.getElementById("cerebellum_btn").addEventListener(
        "mouseover",
        function (event) {
          me.onMouseHover(event, "Cerebellum_node_Cerebellum");
        },
        false
      );
      document.getElementById("cerebellum_btn").addEventListener(
        "click",
        function (event) {
          me.onMouseClick(event, "Cerebellum_node_Cerebellum");
        },
        false
      );
      document.getElementById("motor_and_sensor_cortex").addEventListener(
        "mouseover",
        function (event) {
          me.onMouseHover(
            event,
            "Motor_and_Sensor_Cortex_node_Motor_and_Sensor_Cortex"
          );
        },
        false
      );
      document.getElementById("motor_and_sensor_cortex").addEventListener(
        "click",
        function (event) {
          me.onMouseClick(
            event,
            "Motor_and_Sensor_Cortex_node_Motor_and_Sensor_Cortex"
          );
        },
        false
      );
      document.getElementById("stem_btn").addEventListener(
        "mouseover",
        function (event) {
          me.onMouseHover(
            event,
            "Brainstem_Spinal_cord_node_Brainstem_Spinal_cord"
          );
        },
        false
      );
      document.getElementById("stem_btn").addEventListener(
        "click",
        function (event) {
          me.onMouseClick(
            event,
            "Brainstem_Spinal_cord_node_Brainstem_Spinal_cord"
          );
        },
        false
      );
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("mouseout", this.onMouseOut);
    window.removeEventListener("mouseleave", this.onMouseLeave);
    window.removeEventListener("touchstart", this.onTouchStart);
    window.removeEventListener("touchmove", this.onTouchMove);
    window.removeEventListener("touchend", this.onTouchEnd);
  }
  afterDrawChart = (chart) => {
    var ctx = chart.ctx;
    // const rect = chart.canvas.getBoundingClientRect();
    if (!ctx) return null;
    ctx.save();
    console.log('chart ::', chart)
    var xAxis = chart.scales["x"];
    var yAxis = chart.scales["y"];
    let actionButtonPosArr = [];
    xAxis.ticks.forEach((v, i) => {
      var x = xAxis.getPixelForTick(i);
      actionButtonPosArr = [
        {
          x: x,
          y: yAxis.bottom,
        },
        ...actionButtonPosArr,
      ];
    });
    ctx.restore();
    this.setState({
      actionButtonPositions: actionButtonPosArr,
    });
  };



  generateInstancedSpheres = (sphere_arr) => {
    if (!sphereContainer) {
      return;
    }
    isReset = false;
    // Remove prev spheres
    this.removeAllSpheres();

    var small_spheres_arr = [];
    var medium_spheres_arr = [];
    var large_spheres_arr = [];
    var x_large_spheres_arr = [];
    sphere_arr.forEach(function (object, index) {

      if (object.value && ENABLE_COLOR_SPHERES) {

        if (object.value <= SMALL_BOUNDARY) {
          small_spheres_arr.push(object)
        } else if (object.value <= MEDIUM_BOUNDARY) {
          medium_spheres_arr.push(object)
        } else if (object.value <= LARGE_BOUNDARY) {
          large_spheres_arr.push(object)
        } else if (object.value > LARGE_BOUNDARY) {
          x_large_spheres_arr.push(object)
        }
      }

    });


    var small_instanced_mesh = new THREE.InstancedMesh(SMALL_GEOMETRY, new THREE.MeshStandardMaterial({ color: SMALL_COLOR }), small_spheres_arr.length);

    for (let i = 0; i < small_spheres_arr.length; i++) {
      var dummy = new THREE.Object3D();
      const pointerPos = new THREE.Vector3(small_spheres_arr[i].x, small_spheres_arr[i].y, small_spheres_arr[i].z);
      // (x, y, z) --> (x, -z, y)
      dummy.position.x += pointerPos.x;
      dummy.position.y += pointerPos.z;
      dummy.position.z -= pointerPos.y;

      dummy.updateMatrix();
      small_instanced_mesh.setMatrixAt(i, dummy.matrix);
      small_instanced_mesh.needsUpdate = true;
    }

   
    var medium_instanced_mesh = new THREE.InstancedMesh(MEDIUM_GEOMETRY, new THREE.MeshStandardMaterial({ color: MEDIUM_COLOR }), medium_spheres_arr.length);


    for (let i = 0; i < medium_spheres_arr.length; i++) {
      var dummy = new THREE.Object3D();
      const pointerPos = new THREE.Vector3(medium_spheres_arr[i].x, medium_spheres_arr[i].y, medium_spheres_arr[i].z);
      // (x, y, z) --> (x, -z, y)
      dummy.position.x += pointerPos.x;
      dummy.position.y += pointerPos.z;
      dummy.position.z -= pointerPos.y;
      // dummy.position.set(small_spheres_arr[i].x, small_spheres_arr[i].y, small_spheres_arr[i].z);
      dummy.updateMatrix();
      medium_instanced_mesh.setMatrixAt(i, dummy.matrix);
      medium_instanced_mesh.needsUpdate = true;
    }

    var large_instanced_mesh = new THREE.InstancedMesh(LARGE_GEOMETRY, new THREE.MeshStandardMaterial({ color: LARGE_COLOR }), large_spheres_arr.length);

    for (let i = 0; i < large_spheres_arr.length; i++) {
      var dummy = new THREE.Object3D();
      const pointerPos = new THREE.Vector3(large_spheres_arr[i].x, large_spheres_arr[i].y, large_spheres_arr[i].z);
      // (x, y, z) --> (x, -z, y)
      dummy.position.x += pointerPos.x;
      dummy.position.y += pointerPos.z;
      dummy.position.z -= pointerPos.y;

      dummy.updateMatrix();
      large_instanced_mesh.setMatrixAt(i, dummy.matrix);
      large_instanced_mesh.needsUpdate = true;
    }

    var x_large_instanced_mesh = new THREE.InstancedMesh(X_LARGE_GEOMETRY, new THREE.MeshStandardMaterial({ color: X_LARGE_COLOR }), x_large_spheres_arr.length);

    for (let i = 0; i < x_large_spheres_arr.length; i++) {
      var dummy = new THREE.Object3D();
      const pointerPos = new THREE.Vector3(x_large_spheres_arr[i].x, x_large_spheres_arr[i].y, x_large_spheres_arr[i].z);
      // (x, y, z) --> (x, -z, y)
      dummy.position.x += pointerPos.x;
      dummy.position.y += pointerPos.z;
      dummy.position.z -= pointerPos.y;
      // dummy.position.set(small_spheres_arr[i].x, small_spheres_arr[i].y, small_spheres_arr[i].z);
      dummy.updateMatrix();
      x_large_instanced_mesh.setMatrixAt(i, dummy.matrix);
      x_large_instanced_mesh.needsUpdate = true;
    }

    if (sphereContainer) {
      switch (this.spheres_selected) {
        case "All":
          sphereContainer.add(small_instanced_mesh);
          sphereContainer.add(medium_instanced_mesh);
          sphereContainer.add(large_instanced_mesh);
          sphereContainer.add(x_large_instanced_mesh);
          instancedMeshArr.push(
            small_instanced_mesh, 
            medium_instanced_mesh, 
            large_instanced_mesh, 
            x_large_instanced_mesh
          )
          break;
        case "Small":
          sphereContainer.add(small_instanced_mesh);
          instancedMeshArr.push(small_instanced_mesh);
          break;
        case "Medium":
          sphereContainer.add(medium_instanced_mesh);
          instancedMeshArr.push(medium_instanced_mesh);
          break;
        case "Large":
          sphereContainer.add(large_instanced_mesh);
          instancedMeshArr.push(large_instanced_mesh);
          break;
        case "X-Large":
          sphereContainer.add(x_large_instanced_mesh);
          instancedMeshArr.push(x_large_instanced_mesh);
          break;

      }

    }



  }
  createLobeSpheres = (type) => {


    const selected_events_by_region = [];
    // Add new spheres
    switch (type) {

      case "Frontal_Lobe_node_Frontal_Lobe":
        all_spheres_json[0].forEach(function (object, index) {
          selected_events_by_region.push(object.event_id);
        });
        this.generateInstancedSpheres(all_spheres_json[0]);
        break;
      case "node_Mesh_16":
        all_spheres_json[2].forEach(function (object, index) {
          selected_events_by_region.push(object.event_id);
        });
        this.generateInstancedSpheres(all_spheres_json[2]);
        break;
      case "Cerebral_hemispheres_R_node_Cerebral_hemispheres_R":
        all_spheres_json[1].forEach(function (object, index) {
          selected_events_by_region.push(object.event_id);
        });
        this.generateInstancedSpheres(all_spheres_json[1]);
        break;
      case "Brainstem_Spinal_cord_node_Brainstem_Spinal_cord":
        all_spheres_json[5].forEach(function (object, index) {
          selected_events_by_region.push(object.event_id);
        });
        this.generateInstancedSpheres(all_spheres_json[5]);
        break;
      case "Temporal_Lobe_node_Temporal_Lobe":
        all_spheres_json[3].forEach(function (object, index) {
          selected_events_by_region.push(object.event_id);
        });
        this.generateInstancedSpheres(all_spheres_json[3]);
        break;
      case "Cerebellum_node_Cerebellum":
        all_spheres_json[4].forEach(function (object, index) {
          selected_events_by_region.push(object.event_id);
        });
        this.generateInstancedSpheres(all_spheres_json[4]);
        break;
      case "Motor_and_Sensor_Cortex_node_Motor_and_Sensor_Cortex":
        all_spheres_json[6].forEach(function (object, index) {
          selected_events_by_region.push(object.event_id);
        });
        this.generateInstancedSpheres(all_spheres_json[6]);

        break;
      default:
        break;
    }


    const { set_selected_events_by_region } = this.props;
    if (set_selected_events_by_region) {
      set_selected_events_by_region(selected_events_by_region);
    }

  };


  //generate all spheres
  showAllSpheres = () => {
    const generated_spheres_arr = [];
    all_spheres_json.forEach((ele) => {
      ele.forEach((object) => {
        generated_spheres_arr.push(object);
      })
    })
    this.generateInstancedSpheres(generated_spheres_arr);
  }


  //remove all spheres
  removeAllSpheres = () => {
    if (root) {
      instancedMeshArr.forEach((mesh) => {

        mesh.geometry.dispose();
        mesh.material.dispose();
        sphereContainer.remove(mesh);

      })
      instancedMeshArr = [];
    }
  };
  // lobe button click event, highlight button
  highlightButtons = (type) => {
    // let barColors = defaultBarColors;
    let buttonColors = [];
    switch (type) {
      case "Frontal_Lobe_node_Frontal_Lobe":
        this.state.actionButtons.forEach((ele) => {
          if (ele.id === "front_btn")
            if (lobeButtonClicked) {
              document.getElementById(ele.id).style.backgroundColor = "#ffff66";
              document.getElementById(ele.id + "a").style.color = "#007bff";
            } else {
              document.getElementById(ele.id).style.backgroundColor = "#007bff";
              document.getElementById(ele.id + "a").style.color = "white";
            }
          else {
            document.getElementById(ele.id).style.backgroundColor = "#007bff";
            document.getElementById(ele.id + "a").style.color = "white";
          }
        });
        break;
      case "Cerebral_hemispheres_R_node_Cerebral_hemispheres_R":
        this.state.actionButtons.forEach((ele) => {
          if (ele.id === "pariental_btn") {
            if (lobeButtonClicked) {
              document.getElementById(ele.id).style.backgroundColor = "#ffff66";
              document.getElementById(ele.id + "a").style.color = "#007bff";
            } else {
              document.getElementById(ele.id).style.backgroundColor = "#007bff";
              document.getElementById(ele.id + "a").style.color = "white";
            }
          } else {
            document.getElementById(ele.id).style.backgroundColor = "#007bff";
            document.getElementById(ele.id + "a").style.color = "white";
          }
        });
        break;
      case "node_Mesh_16":
        this.state.actionButtons.forEach((ele) => {
          if (ele.id === "occipital_btn") {
            if (lobeButtonClicked) {
              document.getElementById(ele.id).style.backgroundColor = "#ffff66";
              document.getElementById(ele.id + "a").style.color = "#007bff";
            } else {
              document.getElementById(ele.id).style.backgroundColor = "#007bff";
              document.getElementById(ele.id + "a").style.color = "white";
            }
          } else {
            document.getElementById(ele.id).style.backgroundColor = "#007bff";
            document.getElementById(ele.id + "a").style.color = "white";
          }
        });
        break;
      case "Temporal_Lobe_node_Temporal_Lobe":
        this.state.actionButtons.forEach((ele) => {
          if (ele.id === "temporal_btn") {
            if (lobeButtonClicked) {
              document.getElementById(ele.id).style.backgroundColor = "#ffff66";
              document.getElementById(ele.id + "a").style.color = "#007bff";
            } else {
              document.getElementById(ele.id).style.backgroundColor = "#007bff";
              document.getElementById(ele.id + "a").style.color = "white";
            }
          } else {
            document.getElementById(ele.id).style.backgroundColor = "#007bff";
            document.getElementById(ele.id + "a").style.color = "white";
          }
        });
        break;
      case "Cerebellum_node_Cerebellum":
        this.state.actionButtons.forEach((ele) => {
          if (ele.id === "cerebellum_btn") {
            if (lobeButtonClicked) {
              document.getElementById(ele.id).style.backgroundColor = "#ffff66";
              document.getElementById(ele.id + "a").style.color = "#007bff";
            } else {
              document.getElementById(ele.id).style.backgroundColor = "#007bff";
              document.getElementById(ele.id + "a").style.color = "white";
            }
          } else {
            document.getElementById(ele.id).style.backgroundColor = "#007bff";
            document.getElementById(ele.id + "a").style.color = "white";
          }
        });
        break;
      case "Brainstem_Spinal_cord_node_Brainstem_Spinal_cord":
        this.state.actionButtons.forEach((ele) => {
          if (ele.id === "stem_btn") {
            if (lobeButtonClicked) {
              document.getElementById(ele.id).style.backgroundColor = "#ffff66";
              document.getElementById(ele.id + "a").style.color = "#007bff";
            } else {
              document.getElementById(ele.id).style.backgroundColor = "#007bff";
              document.getElementById(ele.id + "a").style.color = "white";
            }
          } else {
            document.getElementById(ele.id).style.backgroundColor = "#007bff";
            document.getElementById(ele.id + "a").style.color = "white";
          }
        });
        break;
      case "Motor_and_Sensor_Cortex_node_Motor_and_Sensor_Cortex":
        this.state.actionButtons.forEach((ele) => {
          if (ele.id === "motor_and_sensor_cortex") {
            if (lobeButtonClicked) {
              document.getElementById(ele.id).style.backgroundColor = "#ffff66";
              document.getElementById(ele.id + "a").style.color = "#007bff";
            } else {
              document.getElementById(ele.id).style.backgroundColor = "#007bff";
              document.getElementById(ele.id + "a").style.color = "white";
            }
          } else {
            document.getElementById(ele.id).style.backgroundColor = "#007bff";
            document.getElementById(ele.id + "a").style.color = "white";
          }
        });
        break;
      default:
        break;
    }
    this.setState({
      buttonColors: buttonColors,
    });
  };
  highlightGraphBar = (type) => {
    let barColors = defaultBarColors;
    switch (type) {
      case "Frontal_Lobe_node_Frontal_Lobe":
        barColors = [
          "rgba(255,255,102)",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
        ];
        break;
      case "Cerebral_hemispheres_R_node_Cerebral_hemispheres_R":
        barColors = [
          "#7CB5EC",
          "rgba(255,255,102)",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
        ];
        break;
      case "node_Mesh_16":
        barColors = [
          "#7CB5EC",
          "#7CB5EC",
          "rgba(255,255,102)",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
        ];
        break;
      case "Temporal_Lobe_node_Temporal_Lobe":
        barColors = [
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "rgba(255,255,102)",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
        ];
        break;
      case "Cerebellum_node_Cerebellum":
        barColors = [
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "rgba(255,255,102)",
          "#7CB5EC",
          "#7CB5EC",
        ];
        break;
      case "Brainstem_Spinal_cord_node_Brainstem_Spinal_cord":
        barColors = [
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "rgba(255,255,102)",
          "#7CB5EC",
        ];
        break;
      case "Motor_and_Sensor_Cortex_node_Motor_and_Sensor_Cortex":
        barColors = [
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "#7CB5EC",
          "rgba(255,255,102)",
        ];
        break;
      default:
        break;
    }
    this.setState({
      barColors: barColors,
    });
  };
  reset = () => {

    if (!lobeButtonClicked) {
      this.unHighlightPickedObject();
      pickedObject = null;
      prevPickedObject = null;
      let barColors = defaultBarColors;
      this.setState({
        barColors: barColors,
        chartHovered: false,
      });

      if (isReset) {
        return;
      }

      this.removeAllSpheres();
      // Show all spheres
      this.showAllSpheres();
    }
    // else
    // {
    //   let barColors = defaultBarColors;
    //   this.setState({
    //     chartHovered: false,
    //   });
    //   this.removeSpheres();
    //   // Show all spheres
    //   this.showAllSpheres();
    // }
    isReset = true;
  };


  pick = (normalizedPosition, pickingScene, pickingCamera) => {
    // cast a ray through the frustum
    if (lobeButtonClicked) return;
    raycaster.setFromCamera(normalizedPosition, pickingCamera);
    // get the list of objects the ray intersected
    const intersectedObjects = raycaster.intersectObjects(
      brainModel.children[0].children[0].children
    );
    if (intersectedObjects.length > 0) {
      // pick the first object. It's the closest one
      pickedObject = intersectedObjects[0].object;
      this.highlightGraphBar(pickedObject.name);
      if (prevPickedObject && prevPickedObject.name === pickedObject.name)
        return;
      this.unHighlightPickedObject();
      prevPickedObject = pickedObject;
      this.highlightPickedObject();
      this.createLobeSpheres(pickedObject.name);



    } else {
      if (!this.state.chartHovered) {
        this.reset();
        const { set_selected_events_by_region } = this.props;
        if (set_selected_events_by_region
        ) {
          set_selected_events_by_region([]);
        }
      }
    }
  };

  unHighlightPickedObject = () => {
    // restore the color if there is a picked object
    if (prevPickedObject) {
      prevPickedObject.material.emissive.setHex(pickedObjectSavedColor);
      prevPickedObject.material.opacity = defaultTransparency;
      prevPickedObject.material.emissiveIntensity = 1;
      this.cursorAdd(false);
    }
  };
  highlightPickedObject = () => {
    this.cursorAdd(true);
    // save its color
    pickedObjectSavedColor = pickedObject.material.emissive.getHex();
    // set its emissive color to flashing red/yellow
    pickedObject.material.emissiveIntensity = highlightEmissiveIntensity;
    pickedObject.material.opacity = highlightTransparency;
    pickedObject.material.emissive.setHex(highlightColor);
  };
  init = () => {
    this.sceneSetup();
    this.cameraSetup();
    this.lightSetup();
    this.objectSetup();
    this.clearPickPosition();
  };
  startAnimationLoop = () => {
    if (brainModel && currentSubCamera)
      this.pick(pickPosition, scene, currentSubCamera);
    // canvas.width = this.threeCanvasContainer.offsetWidth;
    // canvas.height = this.threeCanvasContainer.offsetHeight;
    // console.log('an' + canvas.width);
    renderer.render(scene, camera);
    requestAnimationFrame(this.startAnimationLoop);
    //Update the renderer size/dimentions
    if (this.threeCanvasContainer) {
      this.onWindowResize();
    }
  };
  sceneSetup = () => {
    console.log("Scene setup");
    scene = new THREE.Scene();
    scene.background = new THREE.Color("white");
    canvas = document.querySelector("#c");
    renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      preserveDrawingBuffer: true,
      antialias: true,
      alpha: true,
    });
    aspectRatio =
      this.threeCanvasContainer.offsetWidth /
      this.threeCanvasContainer.offsetHeight;
    initialRatio = 1 / aspectRatio;
    console.log("initialRatio", initialRatio);
    width =
      (this.threeCanvasContainer.offsetWidth / amount) *
      window.devicePixelRatio;
    height =
      (this.threeCanvasContainer.offsetHeight / amount) *
      window.devicePixelRatio;
    renderer.setPixelRatio(window.devicePixelRatio);
    console.log(
      "this.threeCanvasContainer.offsetWidth",
      this.threeCanvasContainer.offsetWidth,
      "this.threeCanvasContainer.offsetHeight",
      this.threeCanvasContainer.offsetHeight
    );
    renderer.setSize(
      this.threeCanvasContainer.offsetWidth,
      this.threeCanvasContainer.offsetHeight
    );
  };
  cameraSetup = () => {
    let cameras = [];
    for (let y = 0; y < amount; y++) {
      for (let x = 0; x < amount; x++) {
        let cameraAtt = cameraAttArr.filter((item) => {
          return item.x === x && item.y === y;
        });
        if (cameraAtt.length === 0) cameraAtt[0] = defaultCamAtt;
        const subCamera = new THREE.PerspectiveCamera(
          cameraAtt[0].fov,
          aspectRatio,
          near,
          far
        );
        subCamera.viewport = new THREE.Vector4(
          Math.floor(x * width + space / 2),
          Math.floor(y * height + space / 2),
          Math.ceil(width) - space,
          Math.ceil(height) - space
        );
        const subCameraContainer = new THREE.Object3D();
        subCameraContainer.add(subCamera);
        subCamera.position.x = 0;
        subCamera.position.y = 0;
        subCamera.position.z = 1;
        subCameraContainer.rotation.x += cameraAtt[0].rotX;
        subCameraContainer.rotation.y += cameraAtt[0].rotY;
        subCameraContainer.rotation.z += cameraAtt[0].rotZ;
        subCamera.updateProjectionMatrix();
        subCamera.lookAt(0, 0, 0);
        subCamera.updateMatrixWorld();
        cameras.push(subCamera);
      }
    }
    camera = new THREE.ArrayCamera(cameras);
    camera.position.z = 3;
  };
  lightSetup = () => {
    const hemLight = new THREE.HemisphereLight(0xb1e1ff, 0xb97a20, 1);
    scene.add(hemLight);
    const dirLight = new THREE.DirectionalLight(0xffffff, 1);
    dirLight.position.set(10, 20, 10);
    scene.add(dirLight);
  };
  objectSetup = () => {
    // Add background
    const bgGeo = new THREE.PlaneBufferGeometry(100, 100);
    const bgMat = new THREE.MeshBasicMaterial({ color: 0xffffff });
    const background = new THREE.Mesh(bgGeo, bgMat);
    background.name = "background";
    const bg1 = background.clone();
    const bg2 = background.clone();
    const bg3 = background.clone();
    const bg4 = background.clone();
    bg1.position.set(0, 0, -2);
    bg2.position.set(-2, 0, 0);
    bg2.rotation.y = Math.PI / 2;
    bg3.position.set(2, 0, 0);
    bg3.rotation.y = -Math.PI / 2;
    bg4.position.set(0, -2, 0);
    bg4.rotation.x = -Math.PI / 2;
    scene.add(bg1);
    scene.add(bg2);
    scene.add(bg3);
    scene.add(bg4);
    // let me = this;
    // Load&Add brain
    const gltfLoader = new GLTFLoader();
    gltfLoader.load(
      "https://assets.codepen.io/3194077/cloud-viz-brain-Aug-15-2020-V1-Optimized.glb",
      (gltf) => {
        root = gltf.scene;
        const box = new THREE.Box3().setFromObject(root);
        // const boxSize = box.getSize(new THREE.Vector3()).length();
        const boxCenter = box.getCenter(new THREE.Vector3());
        sphereContainer = new THREE.Object3D();
        root.add(sphereContainer);

        root.position.x -= boxCenter.x;
        root.position.y -= boxCenter.y;
        root.position.z -= boxCenter.z;
        root.traverse((n) => {
          let match = n.name.match(/pointer/g);
          if (n.isMesh && !match) {
            n.material = n.material.clone();
            n.material.transparent = true;
            n.material.map = null;
            n.material.color.set(defaultColor);
            n.material.opacity = defaultTransparency;
            if (
              n.name !== "Brainstem_Spinal_cord_node_Brainstem_Spinal_cord" &&
              n.name !== "Cerebellum_node_Cerebellum" &&
              n.name !== "Cerebral_hemispheres_R_node_Cerebral_hemispheres_R" &&
              n.name !== "Frontal_Lobe_node_Frontal_Lobe" &&
              n.name !==
              "Motor_and_Sensor_Cortex_node_Motor_and_Sensor_Cortex" &&
              n.name !== "node_Mesh_16" &&
              n.name !== "Temporal_Lobe_node_Temporal_Lobe"
            )
              n.visible = false;
          }
        });
        brainModel = new THREE.Object3D();
        brainModel.add(root);
        brainModel.rotation.x = Math.PI / 2;
        brainModel.rotation.y = Math.PI;
        brainModel.rotation.z = Math.PI;
        scene.add(brainModel);
        this.showAllSpheres();

        this.setState({
          isLoading: false,
        });
      }
    );
  };
  getCanvasRelativePosition = (event) => {
    const rect = canvas.getBoundingClientRect();
    return {
      x: ((event.clientX - rect.left) * canvas.width) / rect.width,
      y: ((event.clientY - rect.top) * canvas.height) / rect.height,
    };
  };
  setPickPosition = (event) => {
    const pos = this.getCanvasRelativePosition(event);
    if (pos.x < 0 || pos.y < 0) return;
    for (let y = 0; y < amount; y++) {
      for (let x = 0; x < amount; x++) {
        const startX = Math.floor(x * width + space / 2);
        const endX =
          Math.floor(x * width + space / 2) + Math.ceil(width - space);
        const startY = Math.floor(y * height + space / 2);
        const endY =
          Math.floor(y * height + space / 2) + Math.ceil(height - space);
        if (
          pos.x >= startX &&
          pos.x <= endX &&
          amount * height - pos.y >= startY &&
          amount * height - pos.y <= endY
        ) {
          // Current camera
          currentSubCamera = camera.cameras[amount * y + x];
          pickPosition.x = ((pos.x - x * width) / width) * 2 - 1;
          pickPosition.y =
            ((pos.y - (amount - 1 - y) * height) / height) * -2 + 1;
          break;
        }
      }
    }
  };
  clearPickPosition = () => {
    // unlike the mouse which always has a position
    // if the user stops touching the screen we want
    // to stop picking. For now we just pick a value
    // unlikely to pick something
    pickPosition.x = -100000;
    pickPosition.y = -100000;
  };
  // Add cursor or not
  cursorAdd = (flag) => {
    flag
      ? canvas.classList.add("cursor-pointer")
      : canvas.classList.remove("cursor-pointer");
  };
  onWindowResize = () => {
    if (
      prevCanvasWidth === this.threeCanvasContainer.offsetWidth &&
      prevCanvasHeight === this.threeCanvasContainer.offsetHeight
    ) {
      return;
    }
    const canvasWidth = this.threeCanvasContainer.offsetWidth;
    const canvasHeight = this.threeCanvasContainer.offsetHeight;
    prevCanvasWidth = canvasWidth;
    prevCanvasHeight = canvasHeight;
    // Fixing the bug When the screen height is much bigger and the scene is stretched
    // if(initialRatio > 1)
    // {
    // 	initialRatio = 1;
    // }
    // const canvasHeight = this.threeCanvasContainer.offsetWidth
    //   * initialRatio;
    // aspectRatio = canvasWidth / canvasHeight;
    // width = (canvasWidth / amount) * window.devicePixelRatio;
    // height = (canvasHeight / amount) * window.devicePixelRatio;
    renderer.setPixelRatio(window.devicePixelRatio);
    //renderer.setSize(canvasWidth, canvasHeight);
    renderer.setSize(canvasWidth, canvasHeight);
    aspectRatio = canvasWidth / canvasHeight;
    width = (canvasWidth / amount) * window.devicePixelRatio;
    height = (canvasHeight / amount) * window.devicePixelRatio;
    for (let y = 0; y < amount; y++) {
      for (let x = 0; x < amount; x++) {
        const subCamera = camera.cameras[amount * y + x];
        subCamera.viewport.set(
          Math.floor(x * width + space / 2),
          Math.floor(y * height + space / 2),
          Math.ceil(width - space),
          Math.ceil(height - space)
        );
        // Limit aspect ratio to prevent chopped off camera
        var aspect;
        if (aspectRatio < 0.7) {
          aspect = 0.7;
        } else {
          aspect = aspectRatio;
        }
        subCamera.aspect = aspect;
        subCamera.updateProjectionMatrix();
      }
    }
    this.setState({
      loadedActionButtons: false,
      actionButtonPositions: [],
    });
    this.removeEventListeners();
  };
  onMouseClick = (event, type) => {
    if (previousClicked !== type) lobeButtonClicked = true;
    else lobeButtonClicked = !lobeButtonClicked;
    if (event !== "") event.preventDefault();
    this.setState({
      chartHovered: lobeButtonClicked,
      selected_region: type
    });
    pickedObject = scene.getObjectByName(type, true);
    this.highlightGraphBar(type);
    this.highlightButtons(type);
    this.unHighlightPickedObject();
    prevPickedObject = pickedObject;
    this.highlightPickedObject();
    this.createLobeSpheres(type);
    previousClicked = type;

  };
  onMouseHover = (event, type) => {
    if (lobeButtonClicked) return;
    if (event !== "") event.preventDefault();
    this.setState({
      chartHovered: true,
      selected_region: type
    });
    pickedObject = scene.getObjectByName(type, true);
    this.highlightGraphBar(type);
    if (
      !pickedObject ||
      (prevPickedObject && prevPickedObject.name === pickedObject.name)
    )
      return;
    this.unHighlightPickedObject();
    prevPickedObject = pickedObject;
    this.highlightPickedObject();
    this.createLobeSpheres(type);
    console.log("hover");
  };
  onMouseMove = (event) => {
    // Set pick position
    console.log('onMouseMove')
    this.setPickPosition(event);
  };
  onMouseOut = () => {
    if (!lobeButtonClicked) {
      this.setState({
        barColors: defaultBarColors,
        chartHovered: false,
        selected_region: ""
      });

      this.clearPickPosition();
      this.reset();

      const { set_selected_events_by_region } = this.props;
      if (set_selected_events_by_region) {
        set_selected_events_by_region([]);
      }

      return;
    }


    this.setState()

    this.setState({
      chartHovered: false,
    });

  };

  onMouseLeave = () => {
    console.log('onMouseLeave')
    if (!lobeButtonClicked) {
      const { set_selected_events_by_region } = this.props;
      if (set_selected_events_by_region) {
        set_selected_events_by_region([]);
      }
      // Clear pick position
      this.clearPickPosition();

      this.setState({
        barColors: defaultBarColors,
        chartHovered: false,
        selected_region: ""
      });
    }
  };
  onTouchStart = (event) => {
    // prevent the window from scrolling
    event.preventDefault();
    this.setPickPosition(event.touches[0]);
  };
  onTouchMove = (event) => {
    // Set pick position
    this.setPickPosition(event.touches[0]);
  };
  onTouchEnd = () => {
    // Clear pick position
    // this.clearPickPosition();
  };
  removeEventListeners = () => {
    if (document.getElementById("front_btn"))
      document
        .getElementById("front_btn")
        .removeEventListener("mouseover", this.onMouseHover);
    if (document.getElementById("pariental_btn"))
      document
        .getElementById("pariental_btn")
        .removeEventListener("mouseover", this.onMouseHover);
    if (document.getElementById("occipital_btn"))
      document
        .getElementById("occipital_btn")
        .removeEventListener("mouseover", this.onMouseHover);
    if (document.getElementById("temporal_btn"))
      document
        .getElementById("temporal_btn")
        .removeEventListener("mouseover", this.onMouseHover);
    if (document.getElementById("cerebellum_btn"))
      document
        .getElementById("cerebellum_btn")
        .removeEventListener("mouseover", this.onMouseHover);
    if (document.getElementById("motor_and_sensor_cortex"))
      document
        .getElementById("motor_and_sensor_cortex")
        .removeEventListener("mouseover", this.onMouseHover);
    if (document.getElementById("stem_btn"))
      document
        .getElementById("stem_btn")
        .removeEventListener("mouseover", this.onMouseHover);
  };
  strainMetric = (e, v) => {

    // eslint-disable-next-line
    if(this.props.set_title_for_brain_strain_metric){
      this.props.set_title_for_brain_strain_metric(v.name)
    }
   
    switch (v.value) {
      case "max-ps":
        all_spheres_json = [];
        all_spheres_json = [...maximumPS];
        this.set_filtered_spheres_ids();
        break;
      case "min-ps":
        all_spheres_json = [];
        all_spheres_json = [...minimumPS];
        this.set_filtered_spheres_ids();
        break;
      case "csdm_15":
        all_spheres_json = [];
        all_spheres_json = [...csdm_15];
        this.set_filtered_spheres_ids();
        break;
      case "csdm_5":
        all_spheres_json = [];
        all_spheres_json = [...csdm_5];
        this.set_filtered_spheres_ids();
        break;
      case "csdm_10":
        all_spheres_json = [];
        all_spheres_json = [...csdm_10];
        this.set_filtered_spheres_ids();
        break;
    }

    this.setState({ selected_strain_metric: v.value })
    //update scene
    this.removeAllSpheres();
    this.showAllSpheres();

  };
  colored_sphere_click = (type) => {
    if (this.state.colored_spheres_clicked == type) {
      this.setState({
        spheres_selected: "All",
        colored_spheres_clicked: "All",
      });
      this.spheres_selected = "All";
      if (this.props.setSphereType)
        this.props.setSphereType("All");
    } else {
      this.setState({ spheres_selected: type, colored_spheres_clicked: type });
      this.spheres_selected = type;
      if (this.props.setSphereType)
        this.props.setSphereType(type)
    }

    this.removeAllSpheres();

    //if Lobe button clicked, generate colored spheres only for this region
    if (lobeButtonClicked) {
      this.createLobeSpheres(this.state.selected_region);
    }
    else {
      this.showAllSpheres();
    }
  };
  colored_sphere_enter = (type) => {
    if (this.state.colored_spheres_clicked != "All") {
      return;
    }
    this.setState({ spheres_selected: type });
    this.spheres_selected = type;
    this.removeAllSpheres();

    //if Lobe button clicked, generate colored spheres only for this region
    if (lobeButtonClicked) {
      this.createLobeSpheres(this.state.selected_region);
    }
    else {
      this.showAllSpheres();
    }
  };
  colored_sphere_leave = (type) => {
    if (this.state.colored_spheres_clicked != "All") {
      return;
    }
    this.setState({ spheres_selected: "All" });
    this.spheres_selected = "All";
    this.removeAllSpheres();

    //if Lobe button clicked, generate colored spheres only for this region
    if (lobeButtonClicked) {
      this.createLobeSpheres(this.state.selected_region);
    }
    else {
      this.showAllSpheres();
    }
  };
  renderStrainMetricMagnitudes() {
    return (
      <div
        style={{
          height: "100%",
          width: "20%",
          display: "inline-block",
          verticalAlign: "bottom",
        }}
      >
        <div
          id="strain-metric-magnitudes"
          style={{
            textAlign: "center",
            fontSize: this.dashContainer.offsetWidth < 650 ? "12px" : "14px",
          }}
        >
          <strong style={{ height: "15%" }}>Select a Strain Range</strong>
          <div className="dot-container-small" style={{ height: "15%" }}>
            <button
              id="small-spheres-btn"
              className="btn btn-primary colored-sphere-btn"
              style={{
                backgroundColor:
                  this.state.spheres_selected == "Small"
                    ? "#ffff66"
                    : "#0069d9",
                color:
                  this.state.spheres_selected == "Small" ? "#007bff" : "white",
                width: "100%",
                fontSize: this.dashContainer.offsetWidth < 650 || this.dashContainer.offsetHeight < 350 ? "8px" : "12px",
              }}
              onClick={() => {
                this.colored_sphere_click("Small");
              }}
              onMouseEnter={() => {
                console.log("MouseEnter");
                this.colored_sphere_enter("Small");
              }}
              onMouseLeave={() => {
                console.log("MouseLeave");
                this.colored_sphere_leave("All");
              }}
            >
              <span className="green dot" />
              <strong className="sphere-button-label">Small</strong>
            </button>
            <div
              className="small-grey-text"
              style={{
                fontSize:
                  this.dashContainer.offsetWidth < 650 || this.dashContainer.offsetHeight < 350 ? "10px" : "14px",
              }}
            >
              &lt;10%
            </div>
          </div>
          <div
            id="medium-spheres-btn"
            className="dot-container-small"
            style={{ height: "15%" }}
          >
            <button
              className="btn btn-primary colored-sphere-btn"
              style={{
                backgroundColor:
                  this.state.spheres_selected == "Medium"
                    ? "#ffff66"
                    : "#0069d9",
                color:
                  this.state.spheres_selected == "Medium" ? "#007bff" : "white",
                width: "100%",
                fontSize: this.dashContainer.offsetWidth < 650 || this.dashContainer.offsetHeight < 350 ? "8px" : "12px",
              }}
              onClick={() => {
                this.colored_sphere_click("Medium");
              }}
              onMouseEnter={() => {
                console.log("MouseEnter");
                this.colored_sphere_enter("Medium");
              }}
              onMouseLeave={() => {
                console.log("MouseLeave");
                this.colored_sphere_leave("All");
              }}
            >
              <span className="orange dot" />
              <strong className="sphere-button-label">Medium</strong>
            </button>
            <div
              className="small-grey-text"
              style={{
                fontSize:
                  this.dashContainer.offsetWidth < 650 || this.dashContainer.offsetHeight < 350 ? "10px" : "14px",
              }}
            >
              10-18%
            </div>
          </div>
          <div className="dot-container-small" style={{ height: "15%" }}>
            <button
              id="large-spheres-btn"
              className="btn btn-primary colored-sphere-btn"
              style={{
                backgroundColor:
                  this.state.spheres_selected == "Large"
                    ? "#ffff66"
                    : "#0069d9",
                color:
                  this.state.spheres_selected == "Large" ? "#007bff" : "white",
                width: "100%",
                fontSize: this.dashContainer.offsetWidth < 650 || this.dashContainer.offsetHeight < 350 ? "8px" : "12px",
              }}
              onClick={() => {
                this.colored_sphere_click("Large");
              }}
              onMouseEnter={() => {
                console.log("MouseEnter");
                this.colored_sphere_enter("Large");
              }}
              onMouseLeave={() => {
                console.log("MouseLeave");
                this.colored_sphere_leave("All");
              }}
            >
              <span className="red dot" />
              <strong className="sphere-button-label">Large</strong>
            </button>
            <div
              className="small-grey-text"
              style={{
                fontSize:
                  this.dashContainer.offsetWidth < 650 || this.dashContainer.offsetHeight < 350 ? "10px" : "14px",
              }}
            >
              18-30%
            </div>
          </div>
          <div className="dot-container-small" style={{ height: "15%" }}>
            <button
              id="xlarge-spheres-btn"
              className="btn btn-primary colored-sphere-btn"
              style={{
                backgroundColor:
                  this.state.spheres_selected == "X-Large"
                    ? "#ffff66"
                    : "#0069d9",
                color:
                  this.state.spheres_selected == "X-Large"
                    ? "#007bff"
                    : "white",
                width: "100%",
                fontSize: this.dashContainer.offsetWidth < 650 || this.dashContainer.offsetHeight < 350 ? "8px" : "12px",
              }}
              onClick={() => {
                this.colored_sphere_click("X-Large");
              }}
              onMouseEnter={() => {
                console.log("MouseEnter");
                this.colored_sphere_enter("X-Large");
              }}
              onMouseLeave={() => {
                console.log("MouseLeave");
                this.colored_sphere_leave("All");
              }}
            >
              <span className="black dot" />
              <strong className="sphere-button-label">X-Large</strong>
            </button>
            <div
              className="small-grey-text"
              style={{
                fontSize:
                  this.dashContainer.offsetWidth < 650 || this.dashContainer.offsetHeight < 350 ? "10px" : "14px",
              }}
            >
              &gt;30%%
            </div>
          </div>
        </div>
      </div>
    );
  }
  componentWillReceiveProps(props) {
    const { data_minimumPS, data_maximumPS, data_csdm_15, data_csdm_5, data_csdm_10, pressure_dashboard } = props;
    /**
     * Assign value in all metrics variables...
     */
    minimumPS = data_minimumPS;
    maximumPS = data_maximumPS;
    csdm_15 = data_csdm_15;
    csdm_5 = data_csdm_5;
    csdm_10 = data_csdm_10;
    all_spheres_json = [...maximumPS];
    if (pressure_dashboard) {
      this.setState({
        selectOption: [
          { name: "Max Intracranial Pressure", value: "max-ps" },
          { name: "Min Intracranial Pressure", value: "min-ps" },
          { name: "CSDM-15", value: "csdm_15" },
          { name: "CSDM-5", value: "csdm_5" },
          { name: "CSDM-10", value: "csdm_10" },
        ]
      });
    } else {
      this.setState({
        selectOption: [
          { name: "Principal Strain Max", value: "max-ps" },
          { name: "Principal Strain Min", value: "min-ps" },
          { name: "CSDM-15", value: "csdm_15" },
          { name: "CSDM-5", value: "csdm_5" },
          { name: "CSDM-10", value: "csdm_10" },
        ]
      });

    }

  }

  // call after all_spheres_json update
  set_filtered_spheres_ids = () => {
    if (!this.props.set_filtered_event_ids) {
      return;
    }
    var filtered_event_ids = [];
    all_spheres_json.forEach((ele) => {
      ele.forEach(function (object, index) {
        filtered_event_ids.push(object.event_id)
      });
    });
    this.props.set_filtered_event_ids(filtered_event_ids);
  }
  render() {
    let me = this;
    const { data_maximumPS } = this.props;
    if (!all_spheres_json) all_spheres_json = [...data_maximumPS];

    if (!this.props.maxValeOfBrainEffectStatus) {
      let a = parseFloat(all_spheres_json[0].length), b = parseFloat(all_spheres_json[1].length), c = parseFloat(all_spheres_json[2].length), d = parseFloat(all_spheres_json[3].length), e = parseFloat(all_spheres_json[4].length), f = parseFloat(all_spheres_json[5].length), g = parseFloat(all_spheres_json[6].length)

      var array = [a, b, c, d, e, f, g];
      var maxBrainIndex = array.reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);
      var maxBrainVal = array[maxBrainIndex];
      var preDefinedIndex = 6 - maxBrainIndex;
      var names = this.state.actionButtons[preDefinedIndex].name
      console.log('brain effect', maxBrainVal + " " + maxBrainIndex + " " + preDefinedIndex + " " + names) // Logs "c"
      if (maxBrainVal == 0) {
        names = "No";
      }
      this.props.getMaxValOfBrainEffect(names)
    }
    const data = {
      labels: [0, 0, 0, 0, 0, 0, 0],
      datasets: [
        {
          label: "Events",
          lineTension: 0.1,
          backgroundColor: this.state.barColors,
          borderColor: "#1987DD",
          hoverBackgroundColor: "rgba(255,255,102)",
          hoverBorderColor: "rgba(255,255,102)",
          data: [
            parseFloat(all_spheres_json[0].length),
            parseFloat(all_spheres_json[1].length),
            parseFloat(all_spheres_json[2].length),
            parseFloat(all_spheres_json[3].length),
            parseFloat(all_spheres_json[4].length),
            parseFloat(all_spheres_json[5].length),
            parseFloat(all_spheres_json[6].length),
          ],
        },
      ],
    };
    const options = {
      animation: false,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          color: "#007bff",
          font: function (context) {
            var width = context.chart.width;
            labelSize = Math.round(width / 20);
            return {
              size: labelSize,
            };
          },
          formatter: function (value, context) {
            switch (context.dataIndex) {
              case 0:
                return all_spheres_json[0].length;
                // eslint-disable-next-line
                break;
              case 1:
                return all_spheres_json[1].length;
                // eslint-disable-next-line
                break;
              case 2:
                return all_spheres_json[2].length;
                // eslint-disable-next-line
                break;
              case 3:
                return all_spheres_json[3].length;
                // eslint-disable-next-line
                break;
              case 4:
                return all_spheres_json[4].length;
                // eslint-disable-next-line
                break;
              case 5:
                return all_spheres_json[5].length;
                // eslint-disable-next-line
                break;
              case 6:
                return all_spheres_json[6].length;
                // eslint-disable-next-line
                break;
              default:
                break;
            }
          },
        },
        tooltip: {
          callbacks: {
            title: function (tooltipItem) {
              //return data['labels'][tooltipItem[0]['index']];
            },
            label: function (tooltipItem) {
              let event = tooltipItem["dataset"]["data"][tooltipItem["dataIndex"]];
              switch (tooltipItem["dataIndex"]) {
                case 0:
                  me.onMouseHover("", "Frontal_Lobe_node_Frontal_Lobe");
                  break;
                case 1:
                  me.onMouseHover(
                    "",
                    "Cerebral_hemispheres_R_node_Cerebral_hemispheres_R"
                  );
                  break;
                case 2:
                  me.onMouseHover("", "node_Mesh_16");
                  break;
                case 3:
                  me.onMouseHover("", "Temporal_Lobe_node_Temporal_Lobe");
                  break;
                case 4:
                  me.onMouseHover("", "Cerebellum_node_Cerebellum");
                  break;
                case 5:
                  me.onMouseHover(
                    "",
                    "Brainstem_Spinal_cord_node_Brainstem_Spinal_cord"
                  );
                  break;
                case 6:
                  me.onMouseHover(
                    "",
                    "Motor_and_Sensor_Cortex_node_Motor_and_Sensor_Cortex"
                  );
                  break;
                default:
                  break;
              }
              return " " + event + " Events";
            },
          },
          displayColors: false,
          external: function (context) {
            const tooltip = context.tooltip
            if (tooltip.opacity > 0) {
            } else {
              me.onMouseOut("");
            }
            return;
          },
        }
      },
      scales: {
        y: {
          title: {
            display: true,
            text: "Number of Events",
            font: {
              size: labelSize / 1.4,
              color: "#0000",
            }
          },
          ticks: {
            min: 0,
            font: {
              size: 12
            }
          },
        },
        x: {
          title: {
            display: false,
            text: "Angular Acceleration",
          },
          ticks: {
            display: false, //this will remove only the label
          },
        },
      },
    };
    const actionButtons = this.state.actionButtonPositions.map((pos, index) => {
      let width_calc = this.chartContainer
        ? this.chartContainer.getBoundingClientRect().width /
        this.state.actionButtonPositions.length -
        (this.dashContainer.offsetWidth >
          this.dashContainer.offsetHeight
          ? 20
          : 15)
        : 0;
      const MIN_BUTTON_WIDTH = 21;
      if (width_calc < MIN_BUTTON_WIDTH) {
        width_calc = MIN_BUTTON_WIDTH;
      }
      return (
        <button
          className="btn btn-primary lobe_btn_temp"
          id={this.state.actionButtons[index].id}
          style={{
            left: pos.x,
            width: width_calc,
            height: "100%",
            zIndex: this.state.actionButtonPositions.length - index,
          }}
          key={index}
        >
          <span class="tooltiptext">{this.state.actionButtons[index].name}<br />Click to highlight</span>
          <span
            id={this.state.actionButtons[index].id + "a"}
            style={{
              position: "relative",
              fontSize:
                this.dashContainer.offsetWidth < 1100
                  ? "8px"
                  : this.dashContainer.offsetHeight < 490
                    ? "10px"
                    : "12px",
            }}
          >
            {this.dashContainer.offsetWidth < 992
              ? this.state.actionButtons[index].shortenName
              : this.state.actionButtons[index].name}
          </span>
        </button>
      );
    });
    //Change select search width
    if (document.getElementsByClassName("select-search").length > 0) {
      document.getElementsByClassName("select-search")[0].style.maxWidth =
        this.dashContainer.offsetWidth < 320 ? "80px" :
          this.dashContainer.offsetWidth < 350 ? "120px" :
            this.dashContainer.offsetWidth < 720 ? "150px" :
              "300px";
      var elements = document.getElementsByClassName("select-search__option");
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.fontSize =
          this.dashContainer.offsetWidth < 900 ? "9px" : "14px";
      }
    }
    return (
      <React.Fragment>
        <div
          className="dash"
          style={{
            width: "100%",
            height: "100%",
            padding: "5px",
            //zIndex: 1000,
          }}
          ref={(ref) => (this.dashContainer = ref)}
        >
          <div
            style={{
              width: window.innerWidth < 650 ? "100%" : "50%",
              height: window.innerWidth < 650 ? "300px" : "100%",
              display: "inline-block",
              verticalAlign: "top",
              marginBottom: "10px",
              zIndex: 1100
            }}
            className = "brainstrain_canvas"
          >
            {this.renderStrainMetricMagnitudes()}
            <div
              style={{
                width: "75%",
                height: "100%",
                display: "inline-block",
                marginLeft: "5%",
                position: "relative"
              }}
              ref={(ref) => (this.threeCanvasContainer = ref)}
            >
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                {this.state.isLoading ? (
                  <div className="model-loader">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
                <canvas id="c"></canvas>
              </div>
              <div
                className=""
                style={{
                  display: "block",
                  position: "absolute",
                  bottom: "0px",
                  left: "0px",
                  width: "100%",
                  height: "10%",
                  whiteSpace: "nowrap"
                }}
              >
                <div style={{
                  display: "inline-block"
                }}>
                  <span
                    className="strain_text"
                    style={{
                      fontSize:
                        this.dashContainer.offsetWidth < 450 &&
                          this.dashContainer.offsetHeight < 590
                          ? "11px"
                          : this.dashContainer.offsetWidth < 1100
                            ? "14px"
                            : "18px",
                    }}
                  >
                    Strain Metric:
                  </span>
                </div>
                <div style={{
                  display: "inline-block"
                }}>
                  <CustomSelect
                    options={this.state.selectOption}
                    value="max-ps"
                    name="language"
                    placeholder="Choose"
                    onChange={(e, v) => {
                      this.strainMetric(e, v);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            id="barChart"
            style={{
              width: window.innerWidth < 650 ? "100%" : "45%",
              height: window.innerWidth < 650 ? "300px" : "100%",
              display: "inline-block",
              marginLeft: window.innerWidth < 650 ? "0px" : "5%",
              marginTop: window.innerWidth < 650 ? "30px" : "0px",
              textAlign: "center",
            }}
            ref={(ref) => (this.chartContainer = ref)}
          >
            <div style={{ height: window.innerWidth < 650 ? "100%" : "75%" }}>
              <Bar data={data} options={options} plugins={this.plugins} />
            </div>
            <div className="action-btn-container"
              style={{
                height: this.dashContainer.offsetWidth < 580 ? "35px" : this.dashContainer.offsetWidth < 1100 ? "35px" : "45px"
              }}
            >{actionButtons}</div>
            <h3
              className="chartXlabel"
              style={{
                marginTop: "10px",
                height: "calc( 25% - 45px)",
                fontSize:
                  this.dashContainer.offsetHeight < 350
                    ? "16px"
                    : this.dashContainer.offsetHeight < 450
                      ? "17px"
                      : this.dashContainer.offsetHeight < 500
                        ? "20px"
                        : "1.5rem",
              }}
            >
              Major Functional Brain Regions
            </h3>
          </div>
        </div>
        {/* <Footer /> */}
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    maxValeOfBrainEffect: state.Players.maxValeOfBrainEffect,
    maxValeOfBrainEffectStatus: state.Players.maxValeOfBrainEffectStatus
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getMaxValOfBrainEffect: (data) => dispatch(getMaxValOfBrainEffect(data))

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashPage));
// export default DashPage;