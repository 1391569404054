import { createStore, applyMiddleware, compose } from "redux"
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import rootReducer from "./reducers"
import config from "../config/configuration_keys.json";


// const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const Mode = process.env.AppMode || config.AppMode;
let store;
if (Mode === 'dev') {
  store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(logger, thunk)
    )
  )
} else {
  store = createStore(
    rootReducer,
    applyMiddleware(thunk)
  )
}

// sagaMiddleware.run(rootSaga)

export default store
