import { tr } from "date-fns/locale"
import {
  REGISTERING_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,

  GET_ORGANIZATIONS,
  GET_ORGANIZATIONS_SUCCESS,
  GET_ORGANIZATIONS_FAILURE,

  GET_TEAMS,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAILURE,

  INVITE_USER_DETAILS,
  INVITE_USER_DETAILS_SUCCESS,
  INVITE_USER_DETAILS_FAILURE,

  GET_INS,
  GET_INS_SUCCESS,
  GET_INS_FAILURE,
  PLAYER_DETAILS_FROM_TOKEN,
  PLAYER_DETAILS_FROM_TOKEN_SUCCESS,
  PLAYER_DETAILS_FROM_TOKEN_FAILURE,

  REGISTER_OAOUTH_USER
} from "./actionTypes"

const initialState = {
  registeringUser: null,
  registrationError: null,
  message: null,
  loading: false,
  user: null,

  orgloading: false,
  orgErr: null,
  orgList: null,

  insloading: false,
  insErr: null,
  insList: null,

  teamsloading: false,
  teamsErr: null,
  teamsList: null,

  oAuthUser: false,
  userRegistered: false,

  getInviteUser: false,
  invitedUserDetails: null,
  getInviteUserErr: null,

}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTERING_USER:
      state = {
        ...state,
        registeringUser: action.payload,
        registrationError: null,
        orgErr: null,
        oAuthUser: false,
        userRegistered: false
      }
      break
    case REGISTER_USER:
      state = {
        ...state,
        user: action.payload,
        loading: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        message: action.message,
        registrationError: null,
        user: action.payload,
        userRegistered: true
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        loading: false,
        registrationError: action.payload,
      }
      break
    case GET_ORGANIZATIONS:
      state = {
        ...state,
        orgloading: true,
        orgErr: null,
        oAuthUser: false
      }
      break
    case GET_ORGANIZATIONS_SUCCESS:
      state = {
        ...state,
        orgloading: false,
        orgList: action.payload,
        orgErr: null,
      }
      break
    case GET_ORGANIZATIONS_FAILURE:
      state = {
        ...state,
        orgList: null,
        orgloading: false,
        orgErr: action.payload,
      }
      break
    //get institutions...
    case GET_INS:
      state = {
        ...state,
        insloading: true,
        insErr: null,
        insList: null,
      }
      break
    case GET_INS_SUCCESS:
      state = {
        ...state,
        insloading: false,
        insErr: null,
        insList: action.payload,
      }
      break
    case GET_INS_FAILURE:
      state = {
        ...state,
        insloading: false,
        insErr: action.payload,
        insList: null,
      }
      break

    //get teams .. 
    case GET_TEAMS:
      state = {
        ...state,
        teamsloading: true,
        teamsErr: null,
      }
      break
    case GET_TEAMS_SUCCESS:
      state = {
        ...state,
        teamsloading: false,
        teamsList: action.payload,
        teamsErr: null,
      }
      break
    case GET_TEAMS_FAILURE:
      state = {
        ...state,
        teamsList: null,
        teamsloading: false,
        teamsErr: action.payload,
      }
      break

    case REGISTER_OAOUTH_USER:
      state = {
        ...state,
        user: action.payload,
        oAuthUser: true,
        registrationError: action.message
      }
      break

    // get inivited users details. ...
    case INVITE_USER_DETAILS:
      state = {
        ...state,
        getInviteUser: true,
        invitedUserDetails: null,
        getInviteUserErr: null,
      }
      break
    case INVITE_USER_DETAILS_SUCCESS:
      state = {
        ...state,
        getInviteUser: false,
        invitedUserDetails: action.payload,
        getInviteUserErr: null,
      }
      break
    case INVITE_USER_DETAILS_FAILURE:
      state = {
        ...state,
        getInviteUser: false,
        invitedUserDetails: null,
        getInviteUserErr: action.payload,
      }
      break
        // get batch player details from token. ...
    case PLAYER_DETAILS_FROM_TOKEN:
      state = {
        ...state,
        getBatchPlayer: true,
        BatchPlayerDetails: null,
        getBatchPlayerErr: null,
      }
      break
    case PLAYER_DETAILS_FROM_TOKEN_SUCCESS:
      state = {
        ...state,
        getBatchPlayer: false,
        BatchPlayerDetails: action.payload,
        getBatchPlayerErr: null,
      }
      break
    case PLAYER_DETAILS_FROM_TOKEN_FAILURE:
      state = {
        ...state,
        getBatchPlayer: false,
        BatchPlayerDetails: null,
        getBatchPlayerErr: action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default account
