export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"

export const GET_PROFILE = "GET_PROFILE"
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS"
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR"

export const UPLOAD_RBF_SUCCESS = "UPLOAD_RBF_SUCCESS"

export const VERIFI_NUMBER = "VERIFI_NUMBER"
export const VERIFI_NUMBER_SUCCESS = "VERIFI_NUMBER_SUCCESS"
export const VERIFI_NUMBER_ERROR = "VERIFI_NUMBER_ERROR"

export const VERIFI_OTP = "VERIFI_OTP"
export const VERIFI_OTP_SUCCESS = "VERIFI_OTP_SUCCESS"
export const VERIFI_OTP_ERROR = "VERIFI_OTP_ERROR"

export const UPLOAD_RBF_AVTAR = "UPLOAD_RBF_AVTAR"
export const UPLOAD_RBF_AVTAR_SUCCESS = "UPLOAD_RBF_AVTAR_SUCCESS"
export const UPLOAD_RBF_AVTAR_ERROR = "UPLOAD_RBF_AVTAR_ERROR"

export const AVTAR_MODEL = "AVTAR_MODEL"
export const AVTAR_MODEL_SUCCESS = "AVTAR_MODEL_SUCCESS"
export const AVTAR_MODEL_ERROR = "AVTAR_MODEL_ERROR"

export const GET_PERMISSIONS = "GET_PERMISSIONS"
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS"
export const GET_PERMISSIONS_ERROR = "GET_PERMISSIONS_ERROR"

export const GET_All_ACCESS = "GET_All_ACCESS"
export const GET_All_ACCESS_SUCCESS = "GET_All_ACCESS_SUCCESS"
export const GET_All_ACCESS_ERROR = "GET_All_ACCESS_ERROR"

export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS"
export const UPDATE_PERMISSIONS_SUCCESS = "UPDATE_PERMISSIONS_SUCCESS"
export const UPDATE_PERMISSIONS_ERROR = "UPDATE_PERMISSIONS_ERROR"

export const UPDATE_POSITION = "UPDATE_POSITION"
export const UPDATE_POSITION_SUCCESS = "UPDATE_POSITION_SUCCESS"
export const UPDATE_POSITION_ERROR = "UPDATE_POSITION_ERROR"

export const IRB_USER_DETAILS = "IRB_USER_DETAILS"
export const IRB_USER_DETAILS_SUCCESS = "IRB_USER_DETAILS_SUCCESS"
export const IRB_USER_DETAILS_ERROR = "IRB_USER_DETAILS_ERROR"

export const CONFIRM_GUARDIANT_IRB = "CONFIRM_GUARDIANT_IRB"
export const CONFIRM_GUARDIANT_IRB_SUCCESS = "CONFIRM_GUARDIANT_IRB_SUCCESS"
export const CONFIRM_GUARDIANT_IRB_ERROR = "CONFIRM_GUARDIANT_IRB_ERROR"

export const RESET_STATE = "RESET_STATE"
export const AVATAR_PROFILE_PICTURE_URL = "AVATAR_PROFILE_PICTURE_URL"

export const UPLOAD_MESH_RBF_AVTAR = "UPLOAD_MESH_RBF_AVTAR"
export const UPLOAD_MESH_RBF_AVTAR_SUCCESS = "UPLOAD_MESH_RBF_AVTAR_SUCCESS"
export const UPLOAD_MESH_RBF_AVTAR_ERROR = "UPLOAD_MESH_RBF_AVTAR_ERROR"
export const AVTAR_MESH_MODEL_SUCCESS = "AVTAR_MESH_MODEL_SUCCESS"

export const DOWNLOAD_MESH_AVTAR = "DOWNLOAD_MESH_AVTAR"

export const MESH_MODEL = "MESH_MODEL"
export const MESH_MODEL_SUCCESS = "MESH_MODEL_SUCCESS"
export const MESH_MODEL_ERROR = "MESH_MODEL_ERROR"

export const DELETE_USER_ACCOUNT = "DELETE_USER_ACCOUNT"
export const DELETE_USER_ACCOUNT_SUCCESS = "DELETE_USER_ACCOUNT_SUCCESS" 
export const DELETE_USER_ACCOUNT_FAIL = "DELETE_USER_ACCOUNT_FAIL"
export const DELETE_USER_ACCOUNT_STATE = "DELETE_USER_ACCOUNT_STATE" 
export const DELETE_USER_ACCOUNT_AFTER_SUCCESS = "DELETE_USER_ACCOUNT_AFTER_SUCCESS" 