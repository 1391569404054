/* USERS */
export const GET_ORGANIZATIONS_LIST = "GET_ORGANIZATIONS_LIST"
export const GET_ORGANIZATIONS_LIST_SUCCESS = "GET_ORGANIZATIONS_LIST_SUCCESS"
export const GET_ORGANIZATIONS_LIST_FAIL = "GET_ORGANIZATIONS_LIST_FAIL"
/** edit */
export const ADD_ORGANIZATION = "ADD_ORGANIZATION"
export const RENAME_ORGANIZATION = "RENAME_ORGANIZATION"
export const DELETS_ORGANIZATION = "DELETS_ORGANIZATION"
export const EDIT_ORGANIZATIONS = "EDIT_ORGANIZATIONS"
export const EDIT_ORGANIZATIONS_STATE = "EDIT_ORGANIZATIONS_STATE"
export const EDIT_ORGANIZATIONS_SUCCESS = "EDIT_ORGANIZATIONS_SUCCESS"
export const EDIT_ORGANIZATIONS_FAIL = "EDIT_ORGANIZATIONS_FAIL"
export const EPMTY_EDITING_DATA = "EPMTY_EDITING_DATA"
export const MERGE_ORGANIZATION = "MERGE_ORGANIZATION"

export const ORG_STAFF = "ORG_STAFF"
export const ORG_STAFF_SUCCESS = "ORG_STAFF_SUCCESS"
export const ORG_STAFF_FAIL = "ORG_STAFF_FAIL"


//single team
export const GET_ORG_STAT_SUMMARY = "GET_ORG_STAT_SUMMARY"
export const GET_ORG_STAT_SUMMARY_SUCCESS = "GET_ORG_STAT_SUMMARY_SUCCESS"
export const GET_ORG_STAT_SUMMARY_FAIL = "GET_ORG_STAT_SUMMARY_FAIL"

export const SET_ORG_ANYLSIS_DATA = "SET_ORG_ANYLSIS_DATA"
export const SET_ORG_MPS_DATA = "SET_ORG_MPS_DATA"


export const GET_ORG_SIMULATIONS_SUCC = "GET_ORG_SIMULATIONS_SUCC"
export const GET_ORG_SIMULATIONS_FAIL = "GET_ORG_SIMULATIONS_FAIL"  

export const DELETE_ORG_STAFF = "DELETE_ORG_STAFF"
export const DELETE_ORG_STAFF_SUCCESS = "DELETE_ORG_STAFF_SUCCESS" 
export const DELETE_ORG_STAFF_FAIL = "DELETE_ORG_STAFF_FAIL"
export const DELETE_ORG_STAFF_STATE = "DELETE_ORG_STAFF_STATE" 

export const DASHBOARD_SUCCESS_DATA="DASHBOARD_SUCCESS_DATA"

export const ADD_DASHBOARD_SETTINGS="ADD_DASHBOARD_SETTINGS"
export const ADD_DASHBOARD_SETTINGS_SUCCESS="ADD_DASHBOARD_SETTINGS_SUCCESS"
export const ADD_DASHBOARD_SETTINGS_ERROR="ADD_DASHBOARD_SETTINGS_ERROR"

//STRAIN
export const GET_ALL_INS_ANALYSIS_DATA = "GET_ALL_INS_ANALYSIS_DATA"
export const GET_ALL_INS_ANALYSIS_DATA_SUCCESS = "GET_ALL_INS_ANALYSIS_DATA_SUCCESS"
export const GET_ALL_INS_ANALYSIS_DATA_FAIL = "GET_ALL_INS_ANALYSIS_DATA_FAIL"
