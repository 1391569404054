/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT"
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE"

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME"

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR"
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR"
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR"

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER"

export const CHANGE_ITEMS_VIEW = "CHANGE_ITEMS_VIEW"
export const ACTIVE_SIDEBAR = "ACTIVE_SIDEBAR"
export const NOTIFICATIONS_LOADING = "NOTIFICATIONS_LOADING"
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS"
export const NOTIFICATIONS_ERROR = "NOTIFICATIONS_ERROR"
export const NOTIFICATIONS_READ_SUCCESS = "NOTIFICATIONS_READ_SUCCESS"
export const NOTIFICATIONS_READ_START = "NOTIFICATIONS_READ_START"

export const SIMULATIONS_LOADING = "SIMULATIONS_LOADING"
export const SIMULATIONS_SUCCESS = "SIMULATIONS_SUCCESS"
export const SIMULATIONS_ERROR = "SIMULATIONS_ERROR"


