import React from 'react';
import { Scatter } from 'react-chartjs-2';

const DashbaordBarChart = (props) => {
    // console.log('props', props.height)
    let values = []

    let max_axlation = props.MAX_ANGULAR_EXLARATION;
    let mps_95 = props.MPS_95_DATA;
    let dataColors = props.dataColors;

    

    for (var i = 0; i < max_axlation.length; i++) {
        if(props.filtered_event_ids)
        {
        if(props.filtered_event_ids.includes(max_axlation[i].event_id))
            values.push({ 'x': parseFloat(max_axlation[i].val), 'y': parseFloat(mps_95[i]) });
        }
        else 
            values.push({ 'x': parseFloat(max_axlation[i].val), 'y': parseFloat(mps_95[i]) });
    }

    

    // console.log('values :: ', values)

    const getColor = (objectName) => {
        return dataColors.filter(color => color[objectName] !== undefined);
    }

    // filter player id ...
    function filterPlayerId(valu, angAcc) {
        return valu.val == angAcc
    }
    let windowWidth = window.innerWidth - 150


    window.addEventListener('scroll', function () {
        var tooltipEl = document.getElementById('chartjs-tooltip');
        if (tooltipEl) {
            tooltipEl.style.opacity = 0;
            return;
        }
    });

    //Customize tooltip of chart ...
    var customTooltips = function (context) {
        // Tooltip Element
        var tooltip = context.tooltip
        var tooltipEl = document.getElementById('chartjs-tooltip');
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = "<table></table>"
            document.getElementById('root').appendChild(tooltipEl);
        }

        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltip.yAlign) {
            // console.log('tooltip.yAlign', tooltip.yAlign)
            tooltipEl.classList.add(tooltip.yAlign);
        } else {
            tooltipEl.classList.add('no-transform');
        }
        function getBody(bodyItem) {
            return bodyItem.lines;
        }
        // Set Text
        if (tooltip.body) {
            var titleLines = tooltip.title || [];
            var bodyLines = tooltip.body.map(getBody);
            //PUT CUSTOM HTML TOOLTIP CONTENT HERE (innerHTML)
            var innerHtml = '<thead>';
            titleLines.forEach(function (title) {
                innerHtml += '<tr><th>' + title + '</th></tr>';
            });
            innerHtml += '</thead><tbody>';
            bodyLines.forEach(function (body, i) {
                var newbody = body[0].replace('(', '');
                newbody = newbody.replace(')', '');
                var colors = tooltip.labelColors[i];
                var style = 'background:' + colors.backgroundColor;
                style += '; border-color:' + colors.borderColor;
                style += '; border-width: 2px';
                style += '; margin-left: 5px';
                var angAcc = newbody.split(', ')[0].replace(',', '');
                let event_id = max_axlation.filter((value) => filterPlayerId(value, tooltip.dataPoints[0].element.$context.parsed.x));
                event_id = event_id.length > 0 ? event_id[0].event_id : 'N/A';
                angAcc = parseFloat(angAcc);
                var mps = parseFloat(newbody.split(', ')[1].replace(',', ''));
                var span = '<span className="chartjs-tooltip-key" style="' + style + '"></span>';
                innerHtml += '<tr><td>Event Id:</td><td><a href="/player/details/' + event_id + '" target="_blank">' + event_id + '</a></td></tr>';
                innerHtml += '<tr><td>Ang Acc:</td><td>' + span + angAcc.toFixed(2) + '</td></tr>';
                innerHtml += '<tr><td>95%MPS:</td><td>' + span + mps.toFixed(2) + '</td></tr>';
            });
            innerHtml += '</tbody>';
            var tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
        }
        var position = context.chart.canvas.getBoundingClientRect();
        // Display, position, and set styles for font
        let leftMargin = position.left + tooltip.caretX;
        if (leftMargin > windowWidth) leftMargin = leftMargin - 150
        // console.log('leftMargin', leftMargin)
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = leftMargin + 'px';
        tooltipEl.style.top = position.top + tooltip.caretY + 'px';
        tooltipEl.style.fontSize = tooltip.fontSize;
        tooltipEl.style.fontStyle = tooltip._fontStyle;
        tooltipEl.style.padding = '5px 5px';

        tooltipEl.addEventListener("mouseleave", ()=>
        {
            tooltipEl.remove();
        })
    }

    const data = {

        datasets: [
            {
                label: '95% MPS Angular Acceleration',
                data: values,
                pointRadius: 5,
                pointHoverRadius: 10,
                backgroundColor: function (context) {
                    var index = context.dataIndex;
                    var value = context.dataset.data[index];
                    if(value)
                    {
                        var angAcc = value.x;
                        var object = max_axlation.filter((value) => filterPlayerId(value, angAcc));
                        let event_id = object[0] ? object[0].event_id : null;
                        var team = object[0] ? object[0].dataOf : '';
                        
                        if (event_id === props.selected_event_id) {
                            // console.log("Orange")
                            return "orange"
                        }
                        else {
                            var color = getColor(team);
                            return color[0] ? color[0][team] : 'green';
                        }
                    }
                    
                    return "rgb(0 123 255 / 63%)";
                }
            }
        ]
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',

                title: {
                    display: false,
                    font: {
                        size: 18,
                        weight: 800,
                    },
                    text: 'Ang Acc (rad/s2)'
                },
                ticks: {
                    callback: function (value) {
                        return parseFloat(value)
                    }
                }
            },
            y: {
                type: 'linear',

                title: {
                    display: true,
                    font: { size: 18 },
                    text: '95% MPS'
                },
            }
        },
        plugins: {
            datalabels: {
                color: "#007bff",
                display: false,

            },
            tooltip: {
                enabled: false,
                mode: 'index',
                position: 'nearest',
                external: customTooltips
            },
            legend: {
                display: false
            },
        },

        hover: {
            mode: 'point',
            intersect: true,
        },
        onHover: function (e, item) {

            if(props.selected_event_id === "")
            {
                var tooltipEl = document.getElementById('chartjs-tooltip');
                if(tooltipEl)
                {
                    tooltipEl.remove();
                }
            }

            if (item.length > 0) {
                const data = item[0].element.$context.parsed
                let angAcc = data.x;
                let event_obj = max_axlation.filter((value) => filterPlayerId(value, angAcc));
                let event_id = event_obj[0].event_id;

                var tooltipEl = document.getElementById('chartjs-tooltip');
                function setEventId ()
                {
                        props.set_selected_event_id(event_id)
                }

                if (tooltipEl) {

                    tooltipEl.removeEventListener("mouseenter", setEventId, true);
                    tooltipEl.addEventListener("mouseenter", setEventId);
                }

                if (props.set_selected_event_id !== undefined && props.selected_event_id !== event_id) {
                    props.set_selected_event_id(event_id)
                }

            }
            else {
                if (props.set_selected_event_id !== undefined && props.selected_event_id !== "") {
                    props.set_selected_event_id("")
                }
            }
        }

    }

    return (
        <div style={{
            width: "100%",
            height: "calc(100% - 40px)"
        }}>
        <Scatter data={data} options={options}
        />
        </div>
    );
}

export default DashbaordBarChart;