import { rejects } from "assert";
import axios from "axios";
import _ from "lodash";
import { resolve } from "path";
import {
  GET_ORGANIZATIONS_LIST,
  GET_ORGANIZATIONS_LIST_SUCCESS,
  GET_ORGANIZATIONS_LIST_FAIL,
  ADD_ORGANIZATION,
  EPMTY_EDITING_DATA,
  RENAME_ORGANIZATION,
  DELETS_ORGANIZATION,
  MERGE_ORGANIZATION,
  EDIT_ORGANIZATIONS,
  EDIT_ORGANIZATIONS_STATE,
  EDIT_ORGANIZATIONS_SUCCESS,
  EDIT_ORGANIZATIONS_FAIL,
  ORG_STAFF,
  ORG_STAFF_SUCCESS,
  ORG_STAFF_FAIL,
  GET_ORG_STAT_SUMMARY,
  GET_ORG_STAT_SUMMARY_SUCCESS,
  GET_ORG_STAT_SUMMARY_FAIL,
  SET_ORG_ANYLSIS_DATA,
  SET_ORG_MPS_DATA,
  GET_ORG_SIMULATIONS_SUCC,
  GET_ORG_SIMULATIONS_FAIL,
  DELETE_ORG_STAFF,
  DELETE_ORG_STAFF_SUCCESS,
  DELETE_ORG_STAFF_FAIL,
  DELETE_ORG_STAFF_STATE,

  DASHBOARD_SUCCESS_DATA,

  ADD_DASHBOARD_SETTINGS,
  ADD_DASHBOARD_SETTINGS_SUCCESS,
  ADD_DASHBOARD_SETTINGS_ERROR,

  //STRAIN
  GET_ALL_INS_ANALYSIS_DATA,
  GET_ALL_INS_ANALYSIS_DATA_SUCCESS,
  GET_ALL_INS_ANALYSIS_DATA_FAIL,
  
} from "./actionTypes";

import { updateLoadingPressureStatus } from "../team/actions";
import { LOGOUT_LOGGEDIN_USER } from "../auth/login/actionTypes";
import InstitutionsStats from "../../pages/InstitutionsStats";

const getOrgSimulationsSuccess = (items) => ({
  type: GET_ORG_SIMULATIONS_SUCC,
  payload: items,
});

const getOrgSimulationsFail = (error) => ({
  type: GET_ORG_SIMULATIONS_FAIL,
  payload: error,
});

export const fetchOrgSimulations = (body) => {
  return (dispatch) => {
    const { insTeams } = body;
    // var data = [];
    // var fetches = [];

    /* for(let j = 0; j< insTeams.length; j++)
  {

    var fetche = axios.patch("/player/fetchListByTeam", { team_id: insTeams[j].team_id._id, itemPerPage: 1000})
    .then(function (response) {
      var players = response.data.data.players;
      players.forEach(player => {
        data = data.concat(player.sensor_details);
      });
    })
    fetches.push(fetche);


    } */
    const fetches = insTeams.map((el) => {
      return axios
        .patch("/player/fetchListByTeam", {
          team_id: el._id,
          itemPerPage: 1000,
        })
        .then((response) => response.data.data)
        .then((response) => {
          const { players } = response;
          return _.flattenDeep(
            players.map((innerEl) => innerEl.players.map((innerItm) => innerItm.sensor_details))
          ).filter((el) => el);
        })
        .catch((e) => {
          console.log(e);
          return null;
        });
    });
    Promise.all(fetches)
      .then((data) => {
        const simulationsData = data.reduce((el, ac) => [...el, ...ac]);
        dispatch(getOrgSimulationsSuccess(simulationsData));
      })
      .catch(function (error) {
        console.log("error ----------------", error);
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          dispatch(getOrgSimulationsFail(JSON.stringify(error.response.data)));
        }
      });
  };
};

export const setOrgAnylsisData = (items) => ({
  type: SET_ORG_ANYLSIS_DATA,
  payload: items,
});

export const setOrgMpsData = (items) => ({
  type: SET_ORG_MPS_DATA,
  payload: items,
});

export const getSingleOrg = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .patch("/organization/single/org", body)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

/* org STATE SUMMARY DATA */

const getOrgSTatsSummary = () => ({
  type: GET_ORG_STAT_SUMMARY,
});

const getOrgSTatsSummarySuccess = (items) => ({
  type: GET_ORG_STAT_SUMMARY_SUCCESS,
  payload: items,
});

const getOrgSTatsSummaryFail = (error) => ({
  type: GET_ORG_STAT_SUMMARY_FAIL,
  payload: error,
});

export const fetchOrgStatsSummaryData = (body) => {
  return (dispatch) => {
    dispatch(getOrgSTatsSummary());

    axios
      .patch("/organization/stats/summary", body)
      .then(function (response) {
        console.log(response);
        dispatch(getOrgSTatsSummarySuccess(response.data.data));
      })
      .catch(function (error) {
        console.log("error ----------------", error);
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          dispatch(getOrgSTatsSummaryFail(JSON.stringify(error.response.data)));
        }
      });
  };
};

const getOrgList = () => ({
  type: GET_ORGANIZATIONS_LIST,
});

const getOrgListSuccess = (item) => ({
  type: GET_ORGANIZATIONS_LIST_SUCCESS,
  payload: item,
});

const getOrgListFail = (error) => ({
  type: GET_ORGANIZATIONS_LIST_FAIL,
  payload: error,
});

export const getAllOrganizations = () => {
  return (dispatch) => {
    dispatch(getOrgList());
    axios
      .get("/organization/getOrganizations")
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        dispatch(getOrgListSuccess(response.data.data));
      })
      .catch(function (error) {
        // console.log('error ----------------', error);
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          dispatch(getOrgListFail(JSON.stringify(error.response.data.message)));
        }
      });
  };
};

// add ...
const addOrganization = (org) => ({
  type: ADD_ORGANIZATION,
  payload: org,
});

export const emptyOrgEdtingdata = () => ({
  type: EPMTY_EDITING_DATA,
});

export const addOrg = (org) => {
  return (dispatch) => {
    dispatch(addOrganization(org));
  };
};

// Rename ...
const remanameOrganization = (item) => ({
  type: RENAME_ORGANIZATION,
  payload: item,
});

export const renameOrg = (item) => {
  return (dispatch) => {
    dispatch(remanameOrganization(item));
  };
};

// Delete ...
const deleteOrganization = (item) => ({
  type: DELETS_ORGANIZATION,
  payload: item,
});

export const deleteOrg = (item) => {
  return (dispatch) => {
    dispatch(deleteOrganization(item));
  };
};
// Marge ...

const margeOrganization = (item) => ({
  type: MERGE_ORGANIZATION,
  payload: item,
});

export const margeOrg = (item) => {
  return (dispatch) => {
    dispatch(margeOrganization(item));
  };
};

const editorg = () => ({
  type: EDIT_ORGANIZATIONS,
});

const editState = (state) => ({
  type: EDIT_ORGANIZATIONS_STATE,
  payload: state,
});

const editOrgFail = (msg) => ({
  type: EDIT_ORGANIZATIONS_FAIL,
  payload: msg,
});

const editOrgSuccess = (msg) => ({
  type: EDIT_ORGANIZATIONS_SUCCESS,
  payload: msg,
});

// Make edit api calls ...
export const editOrganizations = (items) => {
  return async (dispatch) => {
    dispatch(editorg());
    console.log("items -----------------", items);
    try {
      //.add
      // if (items.addOrgName) {
      //   dispatch(editState('Adding Organization...'));
      //   await addorgTodb(items.addOrgName);
      //   dispatch(editState('Organization added successfully.'));
      // }

      if (items.addList && items.addList.length > 0) {
        dispatch(editState("Adding Organization..."));
        const addPromises = items.addList.map((el) => addorgTodb(el));
        await Promise.all(addPromises);
        dispatch(editState("Organization added successfully."));
      }

      //.rename
      if (items.renameOrgList && items.renameOrgList.length > 0) {
        dispatch(editState("Updating oranizations name..."));
        await renameOrgTodb(items.renameOrgList);
        dispatch(editState("Organizations name updated."));
      }

      if (items.deleteOrgList && items.deleteOrgList.length) {
        dispatch(editState("Deleteing oranizations..."));
        await deleteOrgTodb(items.deleteOrgList);
        dispatch(editState("Oranizations Deleted."));
      }
      // merge
      if (items.mergeOrgList && items.mergeOrgList.length) {
        console.log("mergeOrgList", items.mergeOrgList);
        dispatch(editState("merging oranizations..."));
        await deleteOrgTodb(items.mergeOrgList);
        dispatch(editState("Oranizations merged."));
      }
      dispatch(editOrgSuccess("Successfully updated organizations."));
    } catch (error) {
      //  console.log('catch err -------------------\n', error)
      if (error.response === undefined)
        dispatch(editOrgFail("Failed to delete. Please try again."));
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Invalid Token Input"
      ) {
        dispatch(signOutUser());
      }
      // dispatch(editOrgFail(err.response));
    }
  };
};

const deleteOrgTodb = (orgs) => {
  console.log("orgs", orgs);
  return new Promise((resolve, reject) => {
    axios
      .delete("/organization/deleteOrganizations", {
        data: { organizations: orgs },
        headers: { Authorization: "***" },
      })
      .then(function (response) {
        //   console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        //  console.log('error ----------------', error);
        reject(error.response.data);
      });
  });
};

const renameOrgTodb = (orgs) => {
  return new Promise((resolve, reject) => {
    axios
      .put("/organization/renameOrganizations", { organizations: orgs })
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        // console.log('error ----------------', error);
        reject(error);
      });
  });
};

const addorgTodb = (name) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/organization/addOrganization", { name: name })
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        //   console.log('error ----------------', error);
        reject(error.response.data);
      });
  });
};

/**
 * Fetching organization staff...
 */
const orgStaff = (state) => ({
  type: ORG_STAFF,
});

const orgStaffSuccess = (items) => ({
  type: ORG_STAFF_SUCCESS,
  payload: items,
});

const orgStaffFail = (err) => ({
  type: ORG_STAFF_FAIL,
  payload: err,
});

export const getStaffList = (body) => {
  return (dispatch) => {
    dispatch(orgStaff());

    axios
      .patch("/organization/getStaffList", body)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        dispatch(orgStaffSuccess(response.data.data));
      })
      .catch(function (error) {
        // console.log('error ----------------', error);
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          dispatch(orgStaffFail(JSON.stringify(error.response.data.message)));
        }
      });
  };
};

/**
 * delete organization staff...
 */
const orgStaffDelete = (state) => ({
  type: DELETE_ORG_STAFF,
});

const orgStaffDeleteSuccess = (items) => ({
  type: DELETE_ORG_STAFF_SUCCESS,
  payload: items,
});

const orgStaffDeleteFail = (err) => ({
  type: DELETE_ORG_STAFF_FAIL,
  payload: err,
});
const orgStaffbeforeDelete = (state) => ({
  type: DELETE_ORG_STAFF_STATE,
  payload: state,
});
export const deleteOrgStaff = (body) => {
  return async (dispatch) => {
    dispatch(orgStaffDelete());
    console.log("startttt", body);
    try {
      if (body.user_id && body.user_id.length > 0) {
        console.log("innerr");
        dispatch(orgStaffbeforeDelete("Organization Admin User deleting..."));
        await deleteOrgStaffFromdb(body);
        dispatch(
          orgStaffbeforeDelete("Organization Admin User deleted successfully")
        );
      }
      dispatch(
        orgStaffDeleteSuccess("Organization Admin User deleted successfully")
      );
    } catch (error) {
      //  console.log('catch err -------------------\n', error)
      if (error.response === undefined)
        return dispatch(orgStaffDeleteFail("Network Error."));
      if (
        error.response.data &&
        error.response.data.message === "Invalid Token Input"
      ) {
        return dispatch(signOutUser());
      }
      dispatch(orgStaffDeleteFail(JSON.stringify(error.response.data.message)));
    }
  };
};

const addDashboardSettings = () => {
  return {
    type: ADD_DASHBOARD_SETTINGS,
  }
}

const addDashboardSettingsSuccess = (pressure_dashboard) => ({
  type: ADD_DASHBOARD_SETTINGS_SUCCESS,
  payload:pressure_dashboard,
})

const addDashboardSettingsErr = (err) => ({
  type: ADD_DASHBOARD_SETTINGS_ERROR,
  payload:err,
})


export const setPressureDashboard = (body) => {
  return async dispatch => {
    dispatch(addDashboardSettings())
    // dispatch(updateLoadingPressureStatus(true))
    console.log("organization :::::::::", body)

    axios.post("/organization/setDashboardSetting", body ).then((res) =>{
      console.log("set response :::::::", res.data.data)
      dispatch(addDashboardSettingsSuccess(res.data.data));
      // dispatch(updateLoadingPressureStatus(false))

    }).catch((err)=> {
      dispatch(addDashboardSettingsErr(JSON.stringify(err)))
      console.log(err)
    })
  }
}
export const getPressureDashboard = (items) => {
  return async dispatch => {
    dispatch(addDashboardSettings());
    axios.get(`/organization/getDashboardSetting/${items.id}/${items.type}`).then( function(res) {
      localStorage.setItem("pressureDashboard", res.data.data)
      dispatch(addDashboardSettingsSuccess(res.data.data));
    }).catch(function(err){
      console.log("err ::::::::::::: 1", err)

      dispatch(addDashboardSettingsErr(JSON.stringify(err)))
    })
  }
}

const deleteOrgStaffFromdb = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .patch("/organization/deleteOrgStaff", body)
      .then(function (response) {
        //   console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        //  console.log('error ----------------', error);
        reject(error.response.data);
      });
  });
};

export const dashboardPressureSettings=()=>({
 type: DASHBOARD_SUCCESS_DATA
})

// Signout user if token invalid
const signOutUser = () => ({
  type: LOGOUT_LOGGEDIN_USER,
});

// new analysis data for strain============
const getAllOrgAnalysisData = () => ({
  type: GET_ALL_INS_ANALYSIS_DATA
})

const getAllOrgAnalysisDataSuccess = (payload) => ({
  type: GET_ALL_INS_ANALYSIS_DATA_SUCCESS,
  payload
})

const getAllOrgAnalysisDataFail = (payload) => ({
  type: GET_ALL_INS_ANALYSIS_DATA_FAIL,
  payload
})

export const fetchOrgTop20Events = (data) => {
  let id = data.id;
  let pressure_dashboard = false;
  return dispatch => {
    dispatch(getAllOrgAnalysisData())
    
    axios.get(`/organization/getOrgMpsData/${id}`)
      .then((response) => response.data.data)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        dispatch(getAllOrgAnalysisDataSuccess(response));
      })
      .catch(function (error) {
        console.log('error ----------------', error);
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getAllOrgAnalysisDataFail(JSON.stringify(error.response.data)));
        }
      });
  }
}
