import { lazy } from "react"

const Register = lazy(() => import("../pages/Authentication/UserRegister/register"))
const RegisterPlan = lazy(() => import("../pages/Authentication/UserRegister/registerPlan"))
const SignupElse = lazy(() => import("../pages/Authentication/SignupElse/signupElse"))
const SignupInvitedUser = lazy(() => import("../pages/Authentication/SignUpInvitedUser/signup"))
const BatchPlayerSignUp = lazy(() => import("../pages/Authentication/BatchPlayerSignUp/BatchPlayerSignUp"))
const IRB = lazy(() => import("../components/IRB/IRBLinkContent"))
const IRB_v2 = lazy(() => import("../components/IRB_V2/IRBLinkContent"))
const Login = lazy(() => import("../pages/Authentication/Login/Login"))
const ChangePassword = lazy(() => import("../pages/Authentication/ForgotPassword/changePassword"))
const ForgotPassword = lazy(() => import("../pages/Authentication/ForgotPassword/index"))
const VerifyOTP = lazy(() => import("../pages/Authentication/OTP/index"))
const verficyUserMail = lazy(() => import("../components/VerifyUserMail/index"))
const FamilySignup = lazy(() => import("../pages/Authentication/FamilySignup/familySignup"))
const PricingSingup = lazy(() => import("../pages/Authentication/PricingSingup/familySignup"))
// const Plan = lazy(() => import("../pages/Home/PlanV2/index"))

const authRoutes = [
  // { path: "/logout", component: Logout },
  { path: "/login/:msg?", component: Login },
  { path: "/VerifyOTP/:user_id", component: VerifyOTP },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/change-password/:token", component: ChangePassword },
  { path: "/signup", component: Register },
  { path: "/plan-signup/:product", component: RegisterPlan },
  { path: "/IRB", component: IRB },
  { path: "/prev/IRB", component: IRB_v2 },
  { path: "/SignupElse", component: SignupElse },
  { path: "/signup/:token", component: SignupInvitedUser },
  { path: "/signup/:token/:invited/:code/:clientid", component: SignupInvitedUser },
  { path: "/user/verification", component: verficyUserMail },
  { path: "/FamilySignup", component: FamilySignup },
  { path: "/prev/pricing/singup", component: PricingSingup }, //family
  { path: "/player/signup/:token/:code/:clientid", component: BatchPlayerSignUp },
  // { path: "/pricing", component: Plan },
]

export default authRoutes;
