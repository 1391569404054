import axios from 'axios';
import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,

  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,

  UPLOAD_RBF_SUCCESS,

  VERIFI_NUMBER,
  VERIFI_NUMBER_SUCCESS,
  VERIFI_NUMBER_ERROR,

  VERIFI_OTP,
  VERIFI_OTP_SUCCESS,
  VERIFI_OTP_ERROR,

  UPLOAD_RBF_AVTAR,
  UPLOAD_RBF_AVTAR_SUCCESS,
  UPLOAD_RBF_AVTAR_ERROR,

  AVTAR_MODEL,
  AVTAR_MODEL_SUCCESS,
  AVTAR_MODEL_ERROR,

  GET_PERMISSIONS,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_ERROR,

  UPDATE_PERMISSIONS,
  UPDATE_PERMISSIONS_SUCCESS,
  UPDATE_PERMISSIONS_ERROR,

  UPDATE_POSITION,
  UPDATE_POSITION_SUCCESS,
  UPDATE_POSITION_ERROR,

  IRB_USER_DETAILS,
  IRB_USER_DETAILS_SUCCESS,
  IRB_USER_DETAILS_ERROR,

  CONFIRM_GUARDIANT_IRB,
  CONFIRM_GUARDIANT_IRB_SUCCESS,
  CONFIRM_GUARDIANT_IRB_ERROR,

  RESET_STATE,
  AVATAR_PROFILE_PICTURE_URL,

  GET_All_ACCESS,
  GET_All_ACCESS_SUCCESS,
  GET_All_ACCESS_ERROR,

  UPLOAD_MESH_RBF_AVTAR,
  UPLOAD_MESH_RBF_AVTAR_SUCCESS,
  UPLOAD_MESH_RBF_AVTAR_ERROR,
  AVTAR_MESH_MODEL_SUCCESS,

  DOWNLOAD_MESH_AVTAR,

  MESH_MODEL,
  MESH_MODEL_SUCCESS,
  MESH_MODEL_ERROR,

  DELETE_USER_ACCOUNT,
  DELETE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_ACCOUNT_FAIL,
  DELETE_USER_ACCOUNT_STATE,
  DELETE_USER_ACCOUNT_AFTER_SUCCESS
} from "./actionTypes"
import { LOGOUT_LOGGEDIN_USER } from '../login/actionTypes';

/**
 *  CONFIRM GUARDIANT IRB ...
 */
 const confirmGuardiantIrb = () => {
  return {
    type: CONFIRM_GUARDIANT_IRB
  }
}

const confirmGuardiantIrbSuccess = msg => {
  return {
    type: CONFIRM_GUARDIANT_IRB_SUCCESS,
    payload: msg,
  }
}

const confirmGuardiantIrbError = error => {
  return {
    type: CONFIRM_GUARDIANT_IRB_ERROR,
    payload: error,
  }
}

export const confirmGuardianIRBConsent = (body) => {
  return (dispatch) => {
    dispatch(confirmGuardiantIrb());
    axios.patch(`/auth/confirmGuardianIRBConsent`, body)
      .then(function (response) {
        dispatch(confirmGuardiantIrbSuccess(response.data.message));
      })
      .catch(function (error) {
        if (!error.response) {
          dispatch(confirmGuardiantIrbError('Failed to update permissions.'));
        } else
          if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
            dispatch(signOutUser())
          } else {
            console.log('error1 ------------', error.response)
            dispatch(confirmGuardiantIrbError(JSON.parse(error.response.data)));
          }
      });
  }
}


/**
 *  GETTING USER DETAILS FROM IRB TOKEN ...
 * @param {*}  
 * @returns status
 */
 const getIrbUserDetails = () => {
  return {
    type: IRB_USER_DETAILS
  }
}

const getIrbUserDetailsSuccess = item => {
  return {
    type: IRB_USER_DETAILS_SUCCESS,
    payload: item,
  }
}

const getIrbUserDetailsError = error => {
  return {
    type: IRB_USER_DETAILS_ERROR,
    payload: error,
  }
}

export const getUserDetailsForIRB = (body) => {
  return (dispatch) => {
    dispatch(getIrbUserDetails());
    axios.patch(`/auth/getUserDetailsForIRB`, body)
      .then(function (response) {
        dispatch(getIrbUserDetailsSuccess(response.data.data));
      })
      .catch(function (error) {
        if (!error.response) {
          dispatch(getIrbUserDetailsError('Failed to update permissions.'));
        } else
          if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
            dispatch(signOutUser())
          } else {
            console.log('error1 ------------', error.response)
            dispatch(getIrbUserDetailsError(JSON.parse(error.response.data)));
          }
      });
  }
}


/**
 *  UPDATOMG POSITIONS ...
 * @param {*}  
 * @returns status
 */
const updatePosition = () => {
  return {
    type: UPDATE_POSITION
  }
}

const updatePositionSuccess = item => {
  return {
    type: UPDATE_POSITION_SUCCESS,
    payload: item,
  }
}

const updatePositionError = error => {
  return {
    type: UPDATE_POSITION_ERROR,
    payload: error,
  }
}

export const updateUserPosition = (body) => {
  return (dispatch) => {
    dispatch(updatePosition());
    axios.put(`/auth/update/position`, body)
      .then(function (response) {
        dispatch(updatePositionSuccess(response.data.message));
      })
      .catch(function (error) {
        if (!error.response) {
          dispatch(updatePositionError('Failed to update permissions.'));
        } else
          if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
            dispatch(signOutUser())
          } else {
            console.log('error1 ------------', error.response)
            dispatch(updatePositionError(JSON.parse(error.response.data)));
          }
      });
  }
}

/**
 *  UPDATOMG PERMISSIONS ...
 * @param {*}  
 * @returns status
 */
const updatePermission = () => {
  return {
    type: UPDATE_PERMISSIONS
  }
}

const updatePermissionSuccess = item => {
  return {
    type: UPDATE_PERMISSIONS_SUCCESS,
    payload: item,
  }
}

const updatePermissionError = error => {
  return {
    type: UPDATE_PERMISSIONS_ERROR,
    payload: error,
  }
}

export const updateUserPermission = (body) => {
  return (dispatch) => {
    dispatch(updatePermission());
    axios.put(`/auth/update/permissions`, body)
      .then(function (response) {
        dispatch(updatePermissionSuccess(response.data.message));
      })
      .catch(function (error) {
        if (!error.response) {
          dispatch(updatePermissionError('Failed to update permissions.'));
        } else
          if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
            dispatch(signOutUser())
          } else {
            console.log('error1 ------------', error.response)
            dispatch(updatePermissionError(JSON.parse(error.response.data)));
          }
      });
  }
}


/**
 *  Fetching profile permissions ...
 * @param {*}  
 * @returns status
 */
const getPermission = () => {
  return {
    type: GET_PERMISSIONS
  }
}

const getPermissionSuccess = item => {
  return {
    type: GET_PERMISSIONS_SUCCESS,
    payload: item,
  }
}

const getPermissionError = error => {
  return {
    type: GET_PERMISSIONS_ERROR,
    payload: error,
  }
}

export const fetchUserPermission = (body) => {
  return (dispatch) => {
    dispatch(getPermission());
    axios.post(`/auth/get/permissions`, body)
      .then(function (response) {
        dispatch(getPermissionSuccess(response.data.data));
      })
      .catch(function (error) {
        if (!error.response) {
          dispatch(priofileModelError('Failed to load permissions.'));

        } else
          if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
            dispatch(signOutUser())
          } else {
            console.log('error1 ------------', error.response)
            dispatch(getPermissionError(error.response.data.message));
          }

      });
  }
}
const getAllPermission = () => {
  return {
    type: GET_All_ACCESS
  }
}

const getAllPermissionSuccess = item => {
  return {
    type: GET_All_ACCESS_SUCCESS,
    payload: item,
  }
}

const getAllPermissionError = error => {
  return {
    type: GET_All_ACCESS_ERROR,
    payload: error,
  }
}

export const fetchUserAllPermission = (body) => {
  return (dispatch) => {
    dispatch(getAllPermission());
    axios.post(`/auth/allAccessList`, body)
      .then(function (response) {
        dispatch(getAllPermissionSuccess(response.data.data));
      })
      .catch(function (error) {
        if (!error.response) {
          dispatch(priofileModelError('Failed to load permissions.'));

        } else
          if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
            dispatch(signOutUser())
          } else {
            console.log('error1 ------------', error.response)
            dispatch(getAllPermissionError(error.response.data.message));
          }

      });
  }
}

/**
 *  Fetching profile model ...
 * @param {*}  
 * @returns status
 */
const priofileModel = () => {
  return {
    type: AVTAR_MODEL
  }
}

const updateProfilePicture = payload => {
  return {
    type: AVATAR_PROFILE_PICTURE_URL,
    payload
  }
}

const priofileModelSuccess = item => {
  return {
    type: AVTAR_MODEL_SUCCESS,
    payload: item,
  }
}

const priofileModelError = error => {
  return {
    type: AVTAR_MODEL_ERROR,
    payload: error,
  }
}

export const getProfileModel = (body) => {
  return (dispatch) => {
    dispatch(priofileModel());
    axios.patch(`/auth/get/profile/Model`, body)
      .then(function (response) {
        console.log('response ------ :: ', response)
        dispatch(priofileModelSuccess(response.data.data));
      })
      .catch(function (error) {
        if (!error.response) {
          dispatch(priofileModelError('Failed to upload profile.'));

        } else
          if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
            dispatch(signOutUser())
          } else {
            console.log('error1 ------------', error.response)
            dispatch(priofileModelError(error.response.data.message));
          }

      });
  }
}

/**
 *  profile uploading actions
 * @param {*}  
 * @returns status
 */
const uploadProfile = () => {
  return {
    type: UPLOAD_RBF_AVTAR
  }
}

const uploadprofileSuccess = msg => {
  return {
    type: UPLOAD_RBF_AVTAR_SUCCESS,
    payload: msg,
  }
}

const uploadprofileError = error => {
  return {
    type: UPLOAD_RBF_AVTAR_ERROR,
    payload: error,
  }
}
export const updateRBFStatus = (body) => {
  return (dispatch, getState) => {
      const { Profile } = getState()
      axios.post(`/auth/updateRBFstatus`, body)
      .then((response) => response.data)
          .then(function (response) {
              const { rbf_status } = response.data || {}
              const updateRBFStatus = Object.assign({}, Profile, {
                  rbf_status: rbf_status
              })
              console.log('updateRBFStatus++++++++++++++++++', updateRBFStatus)
              console.log('rbf_status++++++++++++++++++', rbf_status)
              dispatch(uploadProfileSuccess(updateRBFStatus));
          })
        .catch(function (error) {
          if (error.response.data && error.response.data.message === "Invalid Token Input") {
              dispatch(signOutUser())
            } else {
              console.log('error1 ------------', error.response)
              dispatch(uploadprofileError(JSON.stringify(error.response.data.message)));
            }
        });
    }
}

export const updateMeshStatus = (body) => {
  return (dispatch, getState) => {
      const { Profile } = getState()
      axios.post(`/auth/updateMeshstatus`, body)
      .then((response) => response.data)
          .then(function (response) {
              const { mesh_status } = response.data || {}
              const updateMeshstatus = Object.assign({}, Profile, {
                mesh_status: mesh_status
              })
              console.log('updateRBFStatus++++++++++++++++++', updateRBFStatus)
              console.log('mesh_status++++++++++++++++++', mesh_status)
              dispatch(uploadProfileSuccess(updateMeshstatus));
          })
        .catch(function (error) {
          if (error.response.data && error.response.data.message === "Invalid Token Input") {
              dispatch(signOutUser())
            } else {
              console.log('error1 ------------', error.response)
              dispatch(uploadprofileError(JSON.stringify(error.response.data.message)));
            }
        });
    }
}

export const uploadRBFProfilePro = (body) => {
  return (dispatch, getState) => {
    const { Profile } = getState()
    const previousProfileData = Profile.profileModel || null
    dispatch(uploadProfile());
    axios.post(`/auth/uploadProfileRBFproccessing`, body)
      .then((response) => response.data)
      .then(function (response) {
        const { message, data } = response
        if (previousProfileData) {
          const files = previousProfileData.files || {}
          const profileDataFiles = Object.assign({}, files, {
            profile_picture_url: data
          })
          const profileData = Object.assign({}, previousProfileData, {
            files: profileDataFiles
          })
          dispatch(updateProfilePicture(profileData))
        } else {
          const previousProfileData = {}
          previousProfileData.files = {
            profile_picture_url: data
          }
          dispatch(updateProfilePicture(previousProfileData))
        }
        dispatch(uploadprofileSuccess(message));
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          console.log('error1 ------------', error.response)
          dispatch(uploadprofileError(JSON.stringify(error.response.data.message)));
        }

      });
  }
}
//@ end uploading action
// buf mesh
const uploadMesh = () => {
  return {
    type: UPLOAD_MESH_RBF_AVTAR
  }
}

const uploadMeshSuccess = msg => {
  return {
    type: UPLOAD_MESH_RBF_AVTAR_SUCCESS,
    payload: msg,
  }
}

const meshCompleteSuccess = msg => {
  return {
    type: AVTAR_MESH_MODEL_SUCCESS,
    payload: msg,
  }
}

const uploadMeshError = error => {
  return {
    type: UPLOAD_MESH_RBF_AVTAR_ERROR,
    payload: error,
  }
}
export const uploadRBFMesh = (body) => {
  return (dispatch, getState) => {
    dispatch(uploadMesh());
    dispatch(uploadMeshSuccess("Please be patient. File is uploaded and now it's generating the subject-specific head model."));
    axios.post(`/auth/buyMeshRBFproccessing`, body)
      .then((response) => response.data)
      .then(function (response) {
        console.log('---response here ----- : ', response)
        const { message, data } = response
        // dispatch(meshCompleteSuccess(message))
      })
      .catch(function (error) {
        const { response } = error || {}
        const { data } = response || {}
        const { message } = data || {}
        console.log('---response here error ---- : ', response)
        if (message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          console.log('error1 ------------', error.response)
          console.log('message ::: ', message)
          dispatch(uploadMeshError(message ? message.message : 'Something went wrong. Please try after some time.'));
        }
      });
  }
}
const downloadMesh = (msg) => {
  return {
    type: DOWNLOAD_MESH_AVTAR,
    payload: msg
  }
}
export const downloadVtkFile = (body) => {
  console.log('downloadVtkFile_body:::', body)
  return (dispatch, getState) => {
    axios.post(`/auth/download/vtkFile`, body)
      .then((response) => response.data)
      .then(function (response) {
        dispatch(downloadMesh('Your customized finite element mesh has been sent to your email.'));
      })
      .catch(function (error) {
        const { response } = error || {}
        const { data } = response || {}
        const { message } = data || {}
        console.log('---response here error ---- : ', response)
        if (message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          console.log('error1 ------------', error.response)
          console.log('message ::: ', message)
        }
      });
  }
}
const meshModel = () => {
  return {
    type: MESH_MODEL
  }
}

const meshModelSuccess = item => {
  return {
    type: MESH_MODEL_SUCCESS,
    payload: item,
  }
}

const meshModelError = error => {
  return {
    type: MESH_MODEL_ERROR,
    payload: error,
  }
}
export const getVtkFile = (body) => {
  return (dispatch) => {
    dispatch(meshModel());
    axios.patch(`/auth/get/vtkFile`, body)
      .then(function (response) {
        console.log('response ------ :: ', response)
        dispatch(meshModelSuccess(response.data.data));
      })
      .catch(function (error) {
        if (!error.response) {
          dispatch(meshModelError('Failed to upload profile.'));

        } else
          if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
            dispatch(signOutUser())
          } else {
            console.log('error1 ------------', error.response)
            dispatch(meshModelError(error.response.data.message));
          }

      });
  }
}
//@end buy mesh
const editProfile = user => {
  return {
    type: EDIT_PROFILE
  }
}

const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const editUserProfile = (body) => {
  return (dispatch) => {
    dispatch(editProfile());
    axios.post(`/auth/updateProfile`, body)
      .then(function (response) {
        dispatch(profileSuccess(response.data.message));
      })
      .catch(function (error) {

        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          console.log('error1 ------------', error.response)
          dispatch(profileError(error.response.data.message));
        }

      });
  }
}


// Email Setting
export const defaultEmailSetting = (body) => {
    return (dispatch) => {
      axios.post(`/auth/defaultEmailSetting`, body)
        .then(function (response) {
          dispatch(profileSuccess(response.data.message));
        })
        .catch(function (error) {
  
          if (error.response.data && error.response.data.message === "Invalid Token Input") {
            dispatch(signOutUser())
          } else {
            console.log('error1 ------------', error.response)
            dispatch(profileError(error.response.data.message));
          }
  
        });
    }
  }
  export const editEmailSetting = (body) => {
    return (dispatch) => {
      axios.post(`/auth/updateEmailSetting`, body)
        .then(function (response) {
          dispatch(profileSuccess(response.data.message));
        })
        .catch(function (error) {
  
          if (error.response.data && error.response.data.message === "Invalid Token Input") {
            dispatch(signOutUser())
          } else {
            console.log('error1 ------------', error.response)
            dispatch(profileError(error.response.data.message));
          }
  
        });
    }
  }
  
  

/**
 * get profile
 * @param {*} user 
 * @returns 
 */
const getProfile = () => {
  return {
    type: GET_PROFILE
  }
}

const getprofileSuccess = msg => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: msg,
  }
}

const uploadProfileSuccess = msg => {
  return {
    type: UPLOAD_RBF_SUCCESS,
    payload: msg,
  }
}

const getprofileError = error => {
  return {
    type: GET_PROFILE_ERROR,
    payload: error,
  }
}

export const getUserProfile = (body) => {
  return (dispatch) => {
    dispatch(getProfile());
    axios.post(`/auth/get/profile`, body)
      .then(function (response) {
        console.log('esponse.data.data ::::::::::::::::::',response.data.data)
        // localStorage.setItem("authUser", JSON.stringify(response.data.data))
        dispatch(getprofileSuccess(response.data.data));
      })
      .catch(function (error) {

        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          console.log('error1 ------------', error.response)
          dispatch(getprofileError(error.response.data.message));
        }

      });
  }
}

/**
 * Verifying user phone number by otp
 * @returns 
 */

const verifyNumber = () => {
  return {
    type: VERIFI_NUMBER
  }
}

const verifyNumberSuccess = msg => {
  return {
    type: VERIFI_NUMBER_SUCCESS,
    payload: msg,
  }
}

const verifyNumberError = error => {
  return {
    type: VERIFI_NUMBER_ERROR,
    payload: error,
  }
}


export const reqVerifyNumber = (body) => {
  return (dispatch) => {
    dispatch(verifyNumber());
    axios.post(`/auth/verify/number`, body)
      .then(function (response) {
        dispatch(verifyNumberSuccess(response.data.data));
      })
      .catch(function (error) {

        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          console.log('error1 ------------', error.response)
          dispatch(verifyNumberError(error.response.data.message));
        }

      });
  }
}

/**
 * Verifying  otp
 * @returns 
 */

const verifyOtp = () => {
  return {
    type: VERIFI_OTP
  }
}

const verifyOtpSuccess = msg => {
  return {
    type: VERIFI_OTP_SUCCESS,
    payload: msg,
  }
}

const verifyOtpError = error => {
  return {
    type: VERIFI_OTP_ERROR,
    payload: error,
  }
}

export const reqVerifyOtp = (body) => {
  return (dispatch) => {
    dispatch(verifyOtp());
    axios.post(`/auth/verify/otp`, body)
      .then(function (response) {
        dispatch(verifyOtpSuccess(response.data.message));
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          console.log('error1 ------------', error.response)
          dispatch(verifyOtpError(error.response.data.message));
        }

      });
  }
}

export const resetProfileSTate = () => ({
  type: RESET_STATE
})

//delete user account
const userAccountDelete = (state) => ({
  type: DELETE_USER_ACCOUNT,
})

const userAccountDeleteSuccess = (items) => ({
  type: DELETE_USER_ACCOUNT_SUCCESS,
  payload: items
})

const userAccountDeleteFail = (err) => ({
  type: DELETE_USER_ACCOUNT_FAIL,
  payload: err
})
const userAccountbeforeDelete = (state) => ({
  type: DELETE_USER_ACCOUNT_STATE,
  payload: state
})
const userAccountAfterSuccess = (state) => ({
  type: DELETE_USER_ACCOUNT_AFTER_SUCCESS,
})
export const deleteAccount = (body) => {
  return async dispatch => {
    dispatch(userAccountDelete());
    console.log('startttt', body)
    try {
      let currentAccount
      if (body.user_id && body.user_id.length > 0) {
        console.log('innerr')
        dispatch(userAccountbeforeDelete('Account deleting...'));
        let output = await deleteAccountalldetails(body);
        console.log("output", output.data.currentAccount)
        if (output && output.message === "success") {
          console.log("output.data.currentAccount", output.data.currentAccount)
          currentAccount = output.data.currentAccount
          if(currentAccount == 1){
            dispatch(signOutUser())
          }
        }
        dispatch(userAccountbeforeDelete('Account deleted successfully'));



      }
      let obj = {};
      obj.message = "Account deleted successfully"
      obj.currentAccount = currentAccount
      dispatch(userAccountDeleteSuccess(obj));
      setTimeout(() =>
        dispatch(userAccountAfterSuccess())
        , 2000)

    } catch (error) {
      //  console.log('catch err -------------------\n', error)
      if (error.response === undefined) return dispatch(userAccountDeleteFail('Network Error.'))
      if (error.response.data && error.response.data.message === "Invalid Token Input") {
        return dispatch(signOutUser())
      }
      dispatch(userAccountDeleteFail(JSON.stringify(error.response.data.message)));
      dispatch(userAccountAfterSuccess());
    }
  }
}
const deleteAccountalldetails = (body) => {
  return new Promise((resolve, reject) => {
    axios.patch("/auth/deleteAccount", body)
      .then(function (response) {
        //   console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        //  console.log('error ----------------', error);
        reject(error.response.data);
      });
  })
}

// Signout user if token invalid
const signOutUser = () => ({
  type: LOGOUT_LOGGEDIN_USER
})
