export const colors = [
    "#A2E062",
    "#3119FE",
    "#46170B",
    "#482F9C",
    "#EEA320",
    "#098644",
    "#C3B361",
    "#7F0E6C",
    "#759FB1",
    "#EB2503",
    "#49E1CB",
    "#86C3AF",
    "#0CEBA0",
    "#54F441",
    "#111B99",
    "#76A308",
    "#A337F2",
    "#150EBB",
    "#6540C1",
    "#F2E511",
    "#6B0C69",
    "#47D597",
    "#795DCD",
    "#DF8745",
    "#EB35D7",
    "#BE2D7F",
    "#59DEA0",
    "#264E4D",
    "#8C0B85",
    "#355400",
    "#879FCF",
    "#91981F",
    "#98F139",
    "#8D496F",
    "#B4ED8D",
    "#C6A43D",
    "#E2B89B",
    "#B71B45",
    "#E088AC",
    "#86643E",
    "#CEC074",
    "#0A8FC4",
    "#F01285",
    "#A1226A",
    "#B9024B",
    "#D07D4A",
    "#F35EA4",
    "#290E4C",
    "#B81FD8",
    "#E00FC9",
    "#A8C87C",
    "#7E21DF",
    "#27AA0E",
    "#E6A464",
    "#430451",
    "#147FA8",
    "#7A2BA2",
    "#6693AC",
    "#115F7C",
    "#A28CB7",
    "#76C273",
    "#B13BF9",
    "#8676E1",
    "#08B7C1",
    "#C00DA3",
    "#7972BA",
    "#6A10F7",
    "#496FBF",
    "#099AE0",
    "#3DC5B9",
    "#B6B620",
    "#0D016C",
    "#8C22D1",
    "#263E04",
    "#7DA80B",
    "#F4A310",
    "#23BD5C",
    "#391EBE",
    "#6BCEC4",
    "#E4973E",
    "#DE84CE",
    "#4D3B9E",
    "#1208CF",
    "#696AC4",
    "#4DBB82",
    "#4D3734",
    "#F61909",
    "#289929",
    "#7E5A85",
    "#E13E1D",
    "#7ED171",
    "#9848EC",
    "#D5901D",
    "#A5409C",
    "#0564DB",
    "#FA38B0",
    "#7E487B",
    "#3CD092",
    "#33B371",
    "#7A38E0",
    "#A43B36",
    "#FF6219",
    "#75D5C0",
    "#650DC6",
    "#AD56F3",
    "#CE91DE",
    "#8BBA18",
    "#7FDD19",
    "#4E92B8",
    "#B0CF06",
    "#93CDF9",
    "#239DD1",
    "#AF5810",
    "#5CE76A",
    "#4D29D4",
    "#577E73",
    "#EDFFC6",
    "#58D672",
    "#C30DAE",
    "#717E03",
    "#B44EAF",
    "#FD8A0E",
    "#9808CB",
    "#B9A82F",
    "#ADD932",
    "#CA9202",
    "#85B1DA",
    "#E87A2F",
    "#D80FBB",
    "#AAC2D5",
    "#ECF758",
    "#738FFB",
    "#D93F41",
    "#753185",
    "#DBB0B8",
    "#2611D2",
    "#543766",
    "#2E68A7",
    "#0DB41C",
    "#D01755",
    "#3D3EDB",
    "#D439D1",
    "#17E9AB",
    "#48BA95",
    "#701456",
    "#16B662",
    "#0F07F4",
    "#BE0725",
    "#CE4414",
    "#D76E9E",
    "#7A8491",
    "#31061C",
    "#1E78D6",
    "#D3DA52",
    "#37535D",
    "#2ADF10",
    "#302391",
    "#436266",
    "#40447C",
    "#C19056",
    "#EEB919",
    "#4684B3",
    "#E659EA",
    "#C33B0A",
    "#181694",
    "#99089C",
    "#9C0498",
    "#5DE1E9",
    "#94EB38",
    "#DD2B43",
    "#937A47",
    "#6EE4CA",
    "#A7E96E",
    "#28E9B2",
    "#6FE128",
    "#0235A6",
    "#06A63B",
    "#59513F",
    "#7DD9CC",
    "#59542B",
    "#D34070",
    "#B66529",
    "#4C37AA",
    "#572CF3",
    "#C85BED",
    "#1C385D",
    "#233630",
    "#237EA5",
    "#61B027",
    "#5FF3B6",
    "#394815",
    "#A9BA09",
    "#B6B561",
    "#9B36FC",
    "#E65B64",
    "#F3B6AF",
    "#08DB39",
    "#1D301F",
    "#811261",
    "#FBD7BA",
  ];
  