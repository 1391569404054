/* ALL LIST */
export const GET_INSTITUTIONS_LIST = "GET_INSTITUTIONS_LIST"
export const GET_INSTITUTIONS_LIST_SUCCESS = "GET_INSTITUTIONS_LIST_SUCCESS"
export const GET_INSTITUTIONS_LIST_FAIL = "GET_INSTITUTIONS_LIST_FAIL"

/* ORG INS LIST */
export const GET_ORG_INSTITUTIONS_LIST = "GET_ORG_INSTITUTIONS_LIST"
export const GET_ORG_INSTITUTIONS_LIST_SUCCESS = "GET_ORG_INSTITUTIONS_LIST_SUCCESS"
export const GET_ORG_INSTITUTIONS_LIST_FAIL = "GET_ORG_INSTITUTIONS_LIST_FAIL"

export const GET_INSTITUTIONS_BY_ORG = "GET_INSTITUTIONS_BY_ORG"
export const GET_INSTITUTIONS_BY_ORG_SUCCESS = "GET_INSTITUTIONS_BY_ORG_SUCCESS"
export const GET_INSTITUTIONS_BY_ORG_FAIL = "GET_INSTITUTIONS_BY_ORG_FAIL"

export const INS_STAFF = "INS_STAFF"
export const INS_STAFF_SUCCESS = "INS_STAFF_SUCCESS"
export const INS_STAFF_FAIL = "INS_STAFF_FAIL"

//single team
export const INS_BYID = "INS_BYID"
export const INS_BYID_SUCCESS = "INS_BYID_SUCCESS"
export const INS_BYID_FAIL = "INS_BYID_FAIL"

/** edit */
export const ADD_INSTITUTION = "ADD_INSTITUTION"
export const RENAME_INSTITUTION = "RENAME_INSTITUTION"
export const DELETS_INSTITUTION = "DELETS_INSTITUTION"
export const EDIT_INSTITUTION = "EDIT_INSTITUTION"
export const EDIT_INSTITUTION_STATE = "EDIT_INSTITUTION_STATE"
export const EDIT_INSTITUTION_SUCCESS = "EDIT_INSTITUTION_SUCCESS"
export const EDIT_INSTITUTION_FAIL = "EDIT_INSTITUTION_FAIL"
export const EPMTY_EDITING_DATA = "EPMTY_EDITING_DATA"
export const MERGE_INSTITUTION = "MERGE_INSTITUTION"

//org state summary
export const GET_INS_STAT_SUMMARY = "GET_INS_STAT_SUMMARY"
export const GET_INS_STAT_SUMMARY_SUCCESS = "GET_INS_STAT_SUMMARY_SUCCESS"
export const GET_INS_STAT_SUMMARY_FAIL = "GET_INS_STAT_SUMMARY_FAIL"
export const SET_INS_ANYLSIS_DATA = "SET_INS_ANYLSIS_DATA"
export const SET_INS_MPS_DATA = "SET_INS_MPS_DATA"

export const ADD_FAMILY = "ADD_FAMILY"


export const GET_INS_SIMULATIONS_SUCC = "GET_INS_SIMULATIONS_SUCC"
export const GET_INS_SIMULATIONS_FAIL = "GET_INS_SIMULATIONS_FAIL"

/*deleting the ins staff*/
export const DELETE_INS_STAFF_STATE = "DELETE_INS_STAFF_STATE"
export const DELETE_INS_STAFF_FAIL = "DELETE_INS_STAFF_FAIL"
export const DELETE_INS_STAFF_LOADER = "DELETE_INS_STAFF_LOADER"
export const DELETE_INS_STAFF_AFTER_SUCC = "DELETE_INS_STAFF_AFTER_SUCC"

//STRAIN
export const GET_ALL_TEAM_ANALYSIS_DATA = "GET_ALL_TEAM_ANALYSIS_DATA"
export const GET_ALL_TEAM_ANALYSIS_DATA_SUCCESS = "GET_ALL_TEAM_ANALYSIS_DATA_SUCCESS"
export const GET_ALL_TEAM_ANALYSIS_DATA_FAIL = "GET_ALL_TEAM_ANALYSIS_DATA_FAIL"

//PRESSURE
export const GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE = "GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE"
export const GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE_SUCCESS = "GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE_SUCCESS"
export const GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE_FAIL = "GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE_FAIL"
