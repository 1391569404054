import axios from 'axios';
import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,

  GET_ACTIVE_USERS,
  GET_ACTIVE_USERS_SUCCESS,
  GET_ACTIVE_USERS_FAIL,

  CONTACT_MAIL,
  CONTACT_MAIL_SUCCESS,
  CONTACT_MAIL_FAIL,

  GET_SIMULATIONS_NUMBERS,
  SIMULATIONS_NUMBERS,
  GET_SIMULATIONS_NUMBERS_FAIL,

  GET_AWS_COST,
  AWS_COST,
  GET_AWS_FAIL,
  GET_MPS_DATA,
  GET_MPS_DATA_SUCC,
 GET_MPS_DATA_FAIL
} from "./actionTypes"
import { LOGOUT_LOGGEDIN_USER } from "../auth/login/actionTypes";

// get aws cost by day  
export const getawsCost = () => ({
  type: GET_AWS_COST,
})

export const getawsCostSuccess = data => ({
  type: AWS_COST,
  payload: data,
})

export const getawsCostFail = error => ({
  type: GET_AWS_FAIL,
  payload: error,
})


export const fetchAwsDailyCost = (body) => {
  return dispatch => {
    dispatch(getawsCost());
    // axios.patch("/sensor/awscost", body)
    axios.patch("/sensor/awscostnew", body)
      .then(function (response) {
        dispatch(getawsCostSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getawsCostFail(JSON.stringify(error.response.data.message)));
        }
      });
  }
}

// Contact us page ..
export const contactMail = () => ({
  type: CONTACT_MAIL,
})

export const contactMailSuccess = msg => ({
  type: CONTACT_MAIL_SUCCESS,
  payload: msg,
})

export const contactMailFail = error => ({
  type: CONTACT_MAIL_FAIL,
  payload: error,
})

export const snedContactUsMail = (body) => {
  return dispatch => {
    dispatch(contactMail());
    axios.post("/Admin/contact-us", body)
      .then(function (response) {
        dispatch(contactMailSuccess(response.data.message));
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(contactMailFail(JSON.stringify(error.response.data.message)));
        }
      });
  }
}



// FETCH NUMBERS OF SIMULATIONS ..
export const getSimulationsNumbers = () => ({
  type: GET_SIMULATIONS_NUMBERS,
})

export const getSimulationsNumbersSuccess = item => ({
  type: SIMULATIONS_NUMBERS,
  payload: item,
})

export const cgetSimulationsNumbersFail = error => ({
  type: GET_SIMULATIONS_NUMBERS_FAIL,
  payload: error,
})

export const fetchNumbersOFSimulations = (body) => {
  return dispatch => {
    dispatch(getSimulationsNumbers());
    axios.patch("/Admin/simulations/numbers", body)
      .then(function (response) {
        dispatch(getSimulationsNumbersSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(cgetSimulationsNumbersFail(JSON.stringify(error.response.data.message)));
        }
      });
  }
}


//end


export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getAdminStaff = () => {
  return dispatch => {
    dispatch(getUsers());
    axios.get("/Admin/staffList")
      .then(function (response) {
        dispatch(getUsersSuccess(response.data.data));

      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getUsersFail(JSON.stringify(error.response.data.message)));
        }
      });
  }
}

export const getActiveUsers = () => ({
  type: GET_ACTIVE_USERS,
})

export const getActiveUsersSuccess = users => ({
  type: GET_ACTIVE_USERS_SUCCESS,
  payload: users,
})

export const getActiveUsersFail = error => ({
  type: GET_ACTIVE_USERS_FAIL,
  payload: error,
})


export const getActiveUsersData = (body) => {
  return dispatch => {
    dispatch(getActiveUsers());
    axios.patch("/Admin/activeUserData", body)
      .then(function (response) {
        dispatch(getActiveUsersSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getActiveUsersFail(JSON.stringify(error.response.data.message)));
        }
      });
  }
}

// fetching all MPs data ON ADMIN DASHBOARD...
const getMpsData = () => ({
  type: GET_MPS_DATA,
})

const getMpsDataSuccess = items => ({
  type: GET_MPS_DATA_SUCC,
  payload: items,
})

const getMpsDataFail = error => ({
  type:GET_MPS_DATA_FAIL,
  payload: error,
})

export const fetchMpsData = (body) => {
  return dispatch => {
    dispatch(getMpsData());
    axios.patch("/admin/mpsData", body)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        dispatch(getMpsDataSuccess(response.data.data));
      })
      .catch(function (error) {
        console.log('error ----------------', error);
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getMpsDataFail(JSON.stringify(error.response.data)));
        }
      });
  }
}

// Signout user if token invalid
const signOutUser = () => ({
  type: LOGOUT_LOGGEDIN_USER
})