export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS"
export const SOCIAL_LOGIN_FAILURE = "SOCIAL_LOGIN_FAILURE"
export const LOGOUT_LOGGEDIN_USER = "LOGOUT_LOGGEDIN_USER"


export const INVITE_USER = "INVITE_USER"
export const INVITE_SUCCESS = "INVITE_SUCCESS"
export const INVITE_ERROR = "INVITE_ERROR"

//Forgot password.
export const FORGOT_PASS = "FORGOT_PASS"
export const FORGOT_PASS_SUCCESS = "FORGOT_PASS_SUCCESS"
export const FORGOT_PASS_ERROR = "FORGOT_PASS_ERROR"

export const CHANGE_PASS = "CHANGE_PASS"
export const CHANGE_PASS_SUCCESS = "CHANGE_PASS_SUCCESS"
export const CHANGE_PASS_ERROR = "CHANGE_PASS_ERROR"
//Resend confirmation mail
export const RESEND_CONFIRMATION = "RESEND_CONFIRMATION"
export const RESEND_CONFIRMATION_SUCCESS = "RESEND_CONFIRMATION_SUCCESS"
export const RESEND_CONFIRMATION_ERROR = "RESEND_CONFIRMATION_ERROR"

export const RESET_STATE = "RESET_STATE"

