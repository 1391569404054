// @flow
import {
  TEAMS_ORGS,
  TEAMS_ORGS_ERROR,
  MONTHLY_LOGS,
  MONTHLY_LOGS_LOADING,
  MONTHLY_LOGS_ERROR
} from "./actionTypes"

const INIT_STATE = {
  all_teams: null,
  all_orgs: null,
  monthly_logs: null,
  loadingMonthlylogs: false,
  loadingteamsorgs: false,
  teamorgError: '',
  monthlyLogsError: ''

}

const Logs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MONTHLY_LOGS_LOADING:
      return {
        ...state,
        monthly_logs: null,
        loadingMonthlylogs: true,
        monthlyLogsError: ''
      }
    case MONTHLY_LOGS:
      return {
        ...state,
        monthly_logs: action.payload,
        loadingMonthlylogs: false,
        monthlyLogsError: ''
      }
    case TEAMS_ORGS:
      return {
        ...state,
        all_teams: action.payload.data?.teams,
        all_orgs: action.payload.data?.orgs,
        loadingteamsorgs: false,
        teamorgError: false,
      }
    case TEAMS_ORGS_ERROR:
      return {
        ...state,
        all_teams: null,
        all_orgs: null,
        loadingteamsorgs: true,
        teamorgError: 'Error while fetching data'
      }
    case MONTHLY_LOGS_ERROR:
      return {
        ...state,
        monthly_logs: null,
        loadingMonthlylogs: true,
        monthlyLogsError: 'Error while fetching data'
      }
       default:
      return state
  }
}

export default Logs
