import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,

  GET_ACTIVE_USERS,
  GET_ACTIVE_USERS_SUCCESS,
  GET_ACTIVE_USERS_FAIL,

  CONTACT_MAIL,
  CONTACT_MAIL_SUCCESS,
  CONTACT_MAIL_FAIL,

  GET_SIMULATIONS_NUMBERS,
  SIMULATIONS_NUMBERS,
  GET_SIMULATIONS_NUMBERS_FAIL,

  GET_AWS_COST,
  AWS_COST,
  GET_AWS_FAIL,
 GET_MPS_DATA,
 GET_MPS_DATA_SUCC,
GET_MPS_DATA_FAIL
} from "./actionTypes"

const INIT_STATE = {
  staff: null,
  loading: false,
  userProfile: {},
  error: {},

  activeUsers: null,
  loadingActiveUsers: false,
  activeUserLoadError: {},

  contactMail: false,
  contactSuccess: null,
  contactFail: null,

  getsimulationNumbers: false,
  simulationNumbers: null,
  simulationNumbersFail: null,

  getAwsCost: false,
  awsCosts: null,
  awsCostFail: null,
  dashboardMpsData: false,
  dashboardMpsDataSucc: null,
  dashboardMpsDataErr: null,
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    //AWS COSTS
    case GET_AWS_COST:
      return {
        ...state,
        getAwsCost: true,
        awsCosts: null,
        awsCostFail: null
      }

    case AWS_COST:
      return {
        ...state,
        getAwsCost: false,
        awsCosts: action.payload,
        awsCostFail: null
      }

    case GET_AWS_FAIL:
      return {
        ...state,
        getAwsCost: false,
        awsCosts: null,
        awsCostFail: action.payload
      }
    //

    //SIMULATIONS  NUMBERS
    case GET_SIMULATIONS_NUMBERS:
      return {
        ...state,
        getsimulationNumbers: true,
        simulationNumbers: null,
        simulationNumbersFail: null
      }

    case SIMULATIONS_NUMBERS:
      return {
        ...state,
        getsimulationNumbers: false,
        simulationNumbers: action.payload,
        simulationNumbersFail: null
      }

    case GET_SIMULATIONS_NUMBERS_FAIL:
      return {
        ...state,
        getsimulationNumbers: false,
        simulationNumbers: null,
        simulationNumbersFail: action.payload
      }
    //
    //contact mail
    case CONTACT_MAIL:
      return {
        ...state,
        contactMail: true,
        contactSuccess: null,
        contactFail: null
      }

    case CONTACT_MAIL_SUCCESS:
      return {
        ...state,
        contactMail: false,
        contactSuccess: action.payload,
        contactFail: null
      }

    case CONTACT_MAIL_FAIL:
      return {
        ...state,
        contactMail: false,
        contactSuccess: null,
        contactFail: action.payload
      }
    //
    case GET_USERS:
      return {
        ...state,
        loading: true,
        error: null
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        staff: action.payload,
        loading: false,
        error: null
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case GET_ACTIVE_USERS:
      return {
        ...state,
        loadingActiveUsers: true,
        activeUserLoadError: null
      }

    case GET_ACTIVE_USERS_SUCCESS:
      // console.log('state --------------------- activeuser',state.activeUsers)
      return {
        ...state,
        activeUsers: action.payload,
        loadingActiveUsers: false,
        activeUserLoadError: null
      }

    case GET_ACTIVE_USERS_FAIL:
      return {
        ...state,
        activeUserLoadError: action.payload,
        loadingActiveUsers: false
      }
      // Event simulation acceleration ...
    case GET_MPS_DATA:
      return {
        ...state,
        dashboardMpsData: true,
        dashboardMpsDataSucc: null,
        dashboardMpsDataErr: null
      }

    case GET_MPS_DATA_SUCC:
      return {
        ...state,
        dashboardMpsData: false,
        dashboardMpsDataSucc: action.payload,
        dashboardMpsDataErr: null
      }

    case GET_MPS_DATA_FAIL:
      return {
        ...state,
        dashboardMpsData: false,
        dashboardMpsDataSucc: null,
        dashboardMpsDataErr: action.payload
      }
    default:
      return state
  }
}

export default contacts
