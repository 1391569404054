import {
  GET_DATA_SECURITY,
  GET_DATA_SECURITY_SUCCESS,
  GET_DATA_SECURITY_FAIL,

  GET_SENSOR,
  GET_SENSOR_SUCCESS,
  GET_SENSOR_FAIL
} from "./actionTypes"

const INIT_STATE = {
  sensors: null,
  loading: false,

  dataSecurity: null,
  dataSecurityLoading: false,
  dataSecurityError: false,

  userProfile: null,
  error: false,
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SENSOR:
      return {
        ...state,
        loading: true,
        error: null
      }

    case GET_SENSOR_SUCCESS:
      return {
        ...state,
        sensors: action.payload,
        loading: false,
        error: null
      }

    case GET_SENSOR_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      
    case GET_DATA_SECURITY:
      return {
        ...state,
        dataSecurityLoading: true,
        dataSecurityError: null
      }

    case GET_DATA_SECURITY_SUCCESS:
      return {
        ...state,
        dataSecurity: action.payload,
        dataSecurityLoading: false,
        dataSecurityError: null
      }

    case GET_DATA_SECURITY_FAIL:
      return {
        ...state,
        dataSecurityError: action.payload,
        dataSecurityLoading: false
      }

    default:
      return state
  }
}

export default contacts
