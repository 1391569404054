import { combineReducers } from "redux"

import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import Layout from "./layout/reducer"
import Sensor from "./sensor/reducer"
import Organization from "./organization/reducer"
import Institutions from "./institutions/reducer"
import Admin from "./admin/reducer"
import Teams from "./team/reducer"
import Profile from "./auth/profile/reducer"
import Players from "./players/reducer"
import Logs from "./DataSecurity/reducer"



const rootReducer = combineReducers({
  Login,
  Account,
  Layout,
  Sensor,
  Organization,
  Institutions,
  Admin,
  Teams,
  Profile,
  Logs,
  Players
})

export default rootReducer
