import  { lazy } from "react"

const Landing = lazy(() => import("../pages/Home/Landing/index"))
const Landing2 = lazy(() => import("../pages/Home/index"))
const Thanks = lazy(() => import("../pages/Authentication/Thanks/index"))
const Howitwork = lazy(() => import("../pages/Home/Howitwork/index"))
const DashGrid = lazy(() => import("../pages/Dash/DashGrid"))
const Faq = lazy(() => import("../pages/Home/Faq/index"))
const Contact = lazy(() => import("../pages/Home/Contact/index"))
const Plan = lazy(() => import("../pages/Home/Plan/index"))
const Thank = lazy(() => import("../pages/Home/Thank/index"))
const Privacy = lazy(() => import("../pages/Home/Privacy/index"))
const Military = lazy(() => import("../pages/Home/Military/MilitaryPage"))
const SportsPage = lazy(() => import("../pages/Home/Sports/SportsPage"))
const IRBParentConsent = lazy(() => import("../pages/IRBParentConsent/IRBParentConsent"))
const Validation = lazy(() => import("../pages/Home/Validation"))
const PressureResponse = lazy(() => import("../pages/Home/Validation/Inner/PressureResponse"))
const BrainDisplacementResponse = lazy(() => import("../pages/Home/Validation/Inner/BrainDisplacementResponse"))
const BrainStrainResponse = lazy(() => import("../pages/Home/Validation/Inner/BrainStrainResponse"))
const StrainRateResponse = lazy(() => import("../pages/Home/Validation/Inner/StrainRateResponse"))
const HardyEtAl = lazy(() => import("../pages/Home/Validation/Inner/HardyEtAl"))
const FamilyPlayerConf = lazy(() => import("../pages/Home/FamilyPlayerConf/index"))
const CookiePolicy = lazy(() => import("../pages/Home/CookiePolicy/index"))
const TermsofService = lazy(() => import("../pages/Home/TermsofService/index"))
const DataDeletion = lazy(() => import("../pages/Home/DataDeletion/index"))
const Donate = lazy(() => import("../pages/Home/Donate"))
const Success = lazy(() => import("../pages/Home/Donate/success"))
// const PlayerWelcome = lazy(() => import("../pages/PlayerWelcome/index"))
const ConfirmTeamInvite = lazy(() => import("../pages/ConfirmTeamInvite"))
const PlanLoggedIn = lazy(() => import("../pages/Home/PlanV2/loggedInUserPlan"))
const FDADonation = lazy(() => import("../pages/Home/FDADonation/index"))
const FDADonationForm = lazy(() => import("../pages/Home/FDADonation/FDADonationForm"))
const newsletterSignupForm = lazy(() => import("../pages/Home/NewsletterSignup/index"))
const checkForm = lazy(() => import("../pages/Home/Thank/check"))
const Blogs = lazy(() => import("../pages/Home/Blogs/index"))
const BlogPage = lazy(() => import("../pages/Home/Blogs/BlogPage"))
const GenerateCustomMesh = lazy(() => import ("../pages/Home/GenerateCustomMesh"))
const TechnicalDetails = lazy(() => import("../pages/Home/TechnicalDetails"))
const CodeDetails = lazy(() => import('../pages/CodeDetails'))
const CodeDetailsVersion1 = lazy(() => import('../pages/CodeDetailsVersion1'))
const CodeDetailsNew = lazy(() => import('../pages/CodeDetailsNew'))
const Performance = lazy(() => import('../pages/Home/Performance'))
const Verfication = lazy(() => import('../pages/Home/Verfication'))
const FeMeshScaling = lazy(() => import('../pages/Home/FeMesh/Scaling'))
const FeMeshAnatomy = lazy(() => import('../pages/Home/FeMesh/Anatomy'))
const FeMeshFeMeshing = lazy(() => import('../pages/Home/FeMesh/FeMeshing'))
const AlshareefEtAl = lazy(() => import("../pages/Home/Validation/Inner/AlshareefEtAl"))

const HardyEtAlMesh = lazy(() => import("../pages/Home/Validation/Inner/HardyEtAlMesh") );
const AlshareefEtAlMesh = lazy(() => import("../pages/Home/Validation/Inner/AlshareefEtAlMesh") );

const PressureBC = lazy(() => import("../pages/PressureBC/PressureBC"));
const NewBrainContainer = lazy(() => import("../pages/NewBrain/NewBrainContainer"));

const homeRoutes = [
  { path: "/pressureBC", component: PressureBC },
  { path: "/newbrain", component: NewBrainContainer },
  { path: "/IRBParentConsent", component: IRBParentConsent },
  { path: "/home2", component: Landing },
  { path: "/home", component: Landing2 },
  { path: "/dash", component: DashGrid },                                                                                                                                                                                                                                                   
  { path: "/confirm-team-invite/:token", component: ConfirmTeamInvite},
  { path: "/profile/upload", component: Thanks },
  { path: "/how-it-works", component: Howitwork },
  { path: "/faq", component: Faq },
  { path: "/privacy", component: Privacy },
  { path: "/contact", component: Contact },
  { path: "/pricing", component: Plan },
  { path: "/thank", component: Thank },
  { path: "/military", component: Military },
  { path: "/sports", component: SportsPage },
  { path: "/thank", component: Thank },
  { path: "/validation", component: Validation },
  { path: "/pressure-response", component: PressureResponse },
  { path: "/brain-displacement-response", component: BrainDisplacementResponse },
  { path: "/brain-strain-response", component: BrainStrainResponse },
  { path: "/strain-rate-response", component: StrainRateResponse },
  { path: "/hardy-et-al/:cases/:version", component: HardyEtAl },
  { path: "/hardy-et-al/", component: HardyEtAl },
  { path: "/hardy-et-al-mesh/:cases/:version", component: HardyEtAlMesh },
  { path: "/hardy-et-al-mesh/", component: HardyEtAlMesh },

  { path: "/familyplayer/confirmation", component: FamilyPlayerConf },
  { path: "/cookie-policy", component: CookiePolicy },
  { path: "/terms-of-service", component: TermsofService },
  { path: "/data-deletion", component: DataDeletion },
  { path: "/make-a-donation", component: Donate},
  { path: "/success-donation", component: Success},
  { path: "/subscription/plan/:plan?", component: PlanLoggedIn },
  { path: "/FDA-donation", component: FDADonation },
  { path: "/become-a-study-participant", component: FDADonationForm },
  { path: "/newsletter-signup", component: newsletterSignupForm },
  { path: "/checkingurl", component: checkForm },
  {path:"/blogs",component:Blogs},
  {path:"/blog-page",component:BlogPage},
  { path: "/buy-mesh", component: GenerateCustomMesh},
  { path:"/technical-details",component: TechnicalDetails },
  { path: "/code-details/v1", component: CodeDetails},
  { path: "/code-details/v1.0", component: CodeDetailsVersion1},
  { path: "/code-details/v2", component: CodeDetailsNew},
  { path: "/performance", component: Performance},
  { path: "/verification", component: Verfication},
  { path: "/fe-mesh/scaling", component: FeMeshScaling},
  { path: "/fe-mesh/anatomy", component: FeMeshAnatomy},
  { path: "/fe-mesh/fe-meshing", component: FeMeshFeMeshing},
  { path: "/alshareef-et-al/:validationCases/:version", component: AlshareefEtAl },
  { path: "/alshareef-et-al-mesh/:validationCases/:version", component: AlshareefEtAlMesh, },

]

export default homeRoutes;
