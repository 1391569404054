import React, { Component, Suspense } from "react"
import { BrowserRouter as Router, Switch, withRouter } from "react-router-dom"
import { authRoutes, userRoutes, homeRoutes } from "./routes/index"
import { connect } from "react-redux"
import Authmiddleware from "./routes/middleware/Authmiddleware"
import Layout from "./components/layOutIndex"
import NonAuthLayout from "./components/NonAuthLayout"
import { history } from "./helpers/historyHelper"
import './App.scss';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logout: false
    }

  }
  render() {
    const { LogOut } = this.props;
    return (
      <React.Fragment>
        <Router history={history}>
          <Suspense fallback={
             <div style={{ width: '100%', textAlign: "center", height: '100vh', background: 'linear-gradient(90deg, #083c66 10%, #078ae2)', marginTop: '0px', overflow: 'hidden',display:"flex",justifyContent:"center",alignItems:"center" }}>
             <p style={{ color: '#fff' }}>
               <span style={{display: "block" }}>
                 <h4 style={{ textDecoration: 'underline' }}>BRAINSIM.HEALTH</h4>
               Loading...
               </span>
             </p>
           </div>
          }>
            <Switch>
              {authRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  privateRoute={false}
                  key={idx}
                  isAuthProtected={false}
                  exact
                />
              ))}
              {homeRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  privateRoute={false}
                  key={idx}
                  isAuthProtected={false}
                />
              ))}
              {userRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  privateRoute={true}
                  userAccessLevel={route.userAccessLevel}
                  key={idx}
                  isLogoutUser={LogOut}
                  isAuthProtected={true}
                />
              ))}
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    LogOut: state.Login.LogOut,
  }
}

export default connect(mapStateToProps)(withRouter(App))

