import {
  GET_INSTITUTIONS_LIST,
  GET_INSTITUTIONS_LIST_SUCCESS,
  GET_INSTITUTIONS_LIST_FAIL,

  GET_ORG_INSTITUTIONS_LIST,
  GET_ORG_INSTITUTIONS_LIST_SUCCESS,
  GET_ORG_INSTITUTIONS_LIST_FAIL,

  GET_INSTITUTIONS_BY_ORG,
  GET_INSTITUTIONS_BY_ORG_SUCCESS,
  GET_INSTITUTIONS_BY_ORG_FAIL,

  INS_STAFF,
  INS_STAFF_SUCCESS,
  INS_STAFF_FAIL,

  ADD_INSTITUTION,
  RENAME_INSTITUTION,
  DELETS_INSTITUTION,
  EDIT_INSTITUTION,
  EDIT_INSTITUTION_STATE,
  EDIT_INSTITUTION_SUCCESS,
  EDIT_INSTITUTION_FAIL,
  EPMTY_EDITING_DATA,
  MERGE_INSTITUTION,

  INS_BYID,
  INS_BYID_SUCCESS,
  INS_BYID_FAIL,

  GET_INS_STAT_SUMMARY,
  GET_INS_STAT_SUMMARY_SUCCESS,
  GET_INS_STAT_SUMMARY_FAIL,
  SET_INS_ANYLSIS_DATA,
  SET_INS_MPS_DATA,

  GET_INS_SIMULATIONS_SUCC,
  GET_INS_SIMULATIONS_FAIL,
  
  DELETE_INS_STAFF_STATE,
  DELETE_INS_STAFF_FAIL,
  DELETE_INS_STAFF_LOADER,
  DELETE_INS_STAFF_AFTER_SUCC,

  //strain
  GET_ALL_TEAM_ANALYSIS_DATA,
  GET_ALL_TEAM_ANALYSIS_DATA_SUCCESS,
  GET_ALL_TEAM_ANALYSIS_DATA_FAIL,

  //PRESSURE
  GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE,
  GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE_SUCCESS,
  GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  institutions: null,
  loading: false,
  error: {},

  loadingIns: false,
  orgInstitution: null,
  orgInsErr: null,
  orgId: null,
  
  loadingInsList: false,
  orgInsListErr: null,
  orgInstitutionList: null,

  loadingStaff: false,
  insStaff: null,
  staffErr: null,

  addInsName: null,
  renameInsList: [],
  deleteInsList: [],
  mergeInsList: [],
  editingState: null,
  editingErr: null,
  editSuccess: null,

  insById: false,
  insByIdSucc: null,
  insByIdErr: null,

  getInsStatsSummary: false,
  InsStatsSummary: null,
  playerData: null,
  getInsStatsSummaryErr: null,

  insAnylsisData: null,

  insstaffdeleteSuccess: null,
  insstaffdeleteErr: null,
  insstaffdeleteloader:false,
  insstaffdeleteafterSucc:false,

  //STARIN
  loadingOptimizeAllTeamAnalysis: false,
  optimizeAllTeamAnalysisData: null,
  optimizeAllTeamAnalysisDataErr: false,

  //PRESSURE
  loadingOptimizeAllTeamAnalysisPressure: false,
  optimizeAllTeamAnalysisDataPressure: null,
  optimizeAllTeamAnalysisDataPressureErr: false,
}

const Institutions = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_INS_SIMULATIONS_SUCC:
      return {
        ...state,
        getinsSimulations: true,
        insSimulations: action.payload
      }

    case GET_INS_SIMULATIONS_FAIL:
      return {
        ...state,
        getinsSimulations: false,
        insSimulations: null,
      }
    
    case SET_INS_MPS_DATA:
      return {
        ...state,
         insMPSData: action.payload
      }

    //Ins anylsis data
    case SET_INS_ANYLSIS_DATA:
      return {
        ...state,
        insAnylsisData: action.payload
      }
    //InsOrg stats
    case GET_INS_STAT_SUMMARY:
      return {
        ...state,
        getInsStatsSummary: true,
        InsStatsSummary: null,
        playerData: null,
        getInsStatsSummaryErr: null,
      }
    case GET_INS_STAT_SUMMARY_SUCCESS:
      return {
        ...state,
        getInsStatsSummary: false,
        playerData: action.payload.playerProfile,
        InsStatsSummary: action.payload.statSummaryData,
        getInsStatsSummaryErr: null,
      }
    case GET_INS_STAT_SUMMARY_FAIL:
      return {
        ...state,
        getInsStatsSummary: false,
        InsStatsSummary: null,
        playerData: null,
        getInsStatsSummaryErr: action.payload,
      }
    //
    // crud operations ...
    case ADD_INSTITUTION:
      return {
        ...state,
        addInsName: action.payload
      }
    case RENAME_INSTITUTION:
      return {
        ...state,
        renameInsList: state.renameInsList.concat(action.payload)
      }
    case DELETS_INSTITUTION:
      return {
        ...state,
        deleteInsList: state.deleteInsList.concat(action.payload)
      }
    case MERGE_INSTITUTION:
      return {
        ...state,
        mergeInsList: state.mergeInsList.concat(action.payload)
      }
    // Edit ...
    case EDIT_INSTITUTION:
      return {
        ...state,
        loadingIns: true,
        editSuccess: null
      }

    case EDIT_INSTITUTION_STATE:
      return {
        ...state,
        editingState: action.payload
      }
    case EDIT_INSTITUTION_FAIL:
      return {
        ...state,
        editingErr: action.payload,
        loadingIns: false
      }
    case EDIT_INSTITUTION_SUCCESS:
      return {
        ...state,
        editingErr: null,
        loadingIns: false,
        editSuccess: action.payload
      }
    case EPMTY_EDITING_DATA:
      return {
        ...state,
        addInsName: null,
        renameInsList: [],
        deleteInsList: [],
        mergeInsList: [],
        editingState: null,
        editingErr: null,
        editSuccess: null
      }
    //list 
    case GET_INSTITUTIONS_LIST:
      return {
        ...state,
        loading: true,
        error: null
      }

    case GET_INSTITUTIONS_LIST_SUCCESS:
      return {
        ...state,
        institutions: action.payload,
        loading: false,
        error: null
      }

    case GET_INSTITUTIONS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_ORG_INSTITUTIONS_LIST:
      return {
        ...state,
        loadingIns: true,
        orgInsErr: null,
        orgInstitution: null,
        orgId: action.payload
      }
    case GET_ORG_INSTITUTIONS_LIST_SUCCESS:
      return {
        ...state,
        orgInstitution: action.payload,
        loadingIns: false,
        orgInsErr: null
      }
    case GET_ORG_INSTITUTIONS_LIST_FAIL:
      return {
        ...state,
        orgInsErr: action.payload,
        loadingIns: false
      }
    case GET_INSTITUTIONS_BY_ORG:
      return {
        ...state,
        loadingInsList: true,
        orgInsListErr: null,
        orgInstitutionList: null,
      }
    case GET_INSTITUTIONS_BY_ORG_SUCCESS:
      return {
        ...state,
        orgInstitutionList: action.payload,
        loadingInsList: false,
        orgInsListErr: null
      }
    case GET_INSTITUTIONS_BY_ORG_FAIL:
      return {
        ...state,
        orgInsListErr: action.payload,
        loadingInsList: false
      }
    // fetching staff...
    case INS_STAFF:
      return {
        ...state,
        loadingStaff: true,
        insStaff: null,
        staffErr: null
      }

    case INS_STAFF_SUCCESS:
      return {
        ...state,
        loadingStaff: false,
        insStaff: action.payload,
        staffErr: null
      }

    case INS_STAFF_FAIL:
      return {
        ...state,
        loadingStaff: false,
        insStaff: null,
        staffErr: action.payload
      }
    // fetching single institute details by id...
    case INS_BYID:
      return {
        ...state,
        insById: true,
        insByIdSucc: null,
        insByIdErr: null,
      }

    case INS_BYID_SUCCESS:
      return {
        ...state,
        insById: false,
        insByIdSucc: action.payload,
        insByIdErr: null,
      }

    case INS_BYID_FAIL:
      return {
        ...state,
        insById: false,
        insByIdSucc: null,
        insByIdErr: action.payload,
      }
       /**deleting the ins staff */
    case DELETE_INS_STAFF_LOADER:
        return {
          ...state,
          insstaffdeleteloader:true,
          insstaffdeleteafterSucc:false
        }
    case DELETE_INS_STAFF_STATE:
      return {
        ...state,
        insstaffdeleteErr: null,
        insstaffdeleteloader:false,
        insstaffdeleteafterSucc:true,
        insstaffdeleteSuccess: action.payload,
      }
    case DELETE_INS_STAFF_FAIL:
      return {
        ...state,
        insstaffdeleteErr: action.payload,
        insstaffdeleteloader:false,
        insstaffdeleteafterSucc:false,
        insstaffdeleteSuccess: null,
      }
    case DELETE_INS_STAFF_AFTER_SUCC:
      return {
        ...state,
        insstaffdeleteafterSucc:false,
        insstaffdeleteErr: null,
        insstaffdeleteloader:false,
        insstaffdeleteSuccess: null,
      }
    //starin
    case GET_ALL_TEAM_ANALYSIS_DATA:
      return {
        ...state,
        loadingOptimizeAllTeamAnalysis: true,
        optimizeAllTeamAnalysisData: null,
        optimizeAllTeamAnalysisDataErr: false
      }

    case GET_ALL_TEAM_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        loadingOptimizeAllTeamAnalysis: false,
        optimizeAllTeamAnalysisData: action.payload,
        optimizeAllTeamAnalysisDataErr: false
      }

    case GET_ALL_TEAM_ANALYSIS_DATA_FAIL:
      return {
        ...state,
        loadingOptimizeAllTeamAnalysis: false,
        optimizeAllTeamAnalysisData: null,
        optimizeAllTeamAnalysisDataErr: action.payload
      }
    //PRESSURE
    case GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE:
      return {
        ...state,
        loadingOptimizeAllTeamAnalysisPressure: true,
        optimizeAllTeamAnalysisDataPressure: null,
        optimizeAllTeamAnalysisDataPressureErr: false
      }

    case GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE_SUCCESS:
      return {
        ...state,
        loadingOptimizeAllTeamAnalysisPressure: false,
        optimizeAllTeamAnalysisDataPressure: action.payload,
        optimizeAllTeamAnalysisDataPressureErr: false
      }

    case GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE_FAIL:
      return {
        ...state,
        loadingOptimizeAllTeamAnalysisPressure: false,
        optimizeAllTeamAnalysisDataPressure: null,
        optimizeAllTeamAnalysisDataPressureErr: action.payload
      }
    default:
      return state
  }
}

export default Institutions
