// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  CHANGE_ITEMS_VIEW,
  ACTIVE_SIDEBAR,
  NOTIFICATIONS_LOADING,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_ERROR,
  NOTIFICATIONS_READ_START,
  NOTIFICATIONS_READ_SUCCESS,
  SIMULATIONS_LOADING,
  SIMULATIONS_SUCCESS,
  SIMULATIONS_ERROR
} from "./actionTypes"

const INIT_STATE = {
  layoutType: "vertical",
  layoutWidth: "fluid",
  leftSideBarTheme: "dark",
  leftSideBarType: "default",
  topbarTheme: "light",
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  itemView: 'grid',
  activeSidebar: false,
  notificationCount:0,
  notificationDat:[],
  notificationRead:null
}

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      }
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      }

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      }
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar,
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true,
      }
    case HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false,
      }
    case CHANGE_ITEMS_VIEW:
      return {
        ...state,
        itemView: action.payload,
      }
    case ACTIVE_SIDEBAR:
      return {
        ...state,
        activeSidebar: state.activeSidebar ? false : true,
      }
       /* notifiation state*/
       case NOTIFICATIONS_LOADING:
        return {
          ...state,
          notificationCount:0,
          notificationDat:[],
          notificationAvail:null
        }
      case NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          notificationCount:action.payload.notificationCount,
          notificationData:action.payload.notifcationData,
          notificationAvail:true
        }
      case NOTIFICATIONS_ERROR:
        return {
          ...state,
          notificationCount:0,
          notificationDat:[],
          notificationAvail:false
        }
        case NOTIFICATIONS_READ_START:
        return {
          ...state,
          notificationRead:false,
        }
        case NOTIFICATIONS_READ_SUCCESS:
          return {
            ...state,
            notificationRead:true,
          }
          /** org simulation */
           /* notifiation state*/
       case SIMULATIONS_LOADING:
        return {
          ...state,
          orgSimulationsCount:0,
        }
      case SIMULATIONS_SUCCESS:
        return {
          ...state,
          orgSimulationsCount:action.payload,
        }
      case SIMULATIONS_ERROR:
        return {
          ...state,
          orgSimulationsCount:0,
        }
    default:
      return state
  }
}

export default Layout
