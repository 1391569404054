import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import HttpsRedirect from 'react-https-redirect';

// import './index.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store"


const app = (
  <Provider store={store}>

    <BrowserRouter forceRefresh={true}>
      <HttpsRedirect>
        <App />
      </HttpsRedirect>
    </BrowserRouter>

  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
