import axios from 'axios';
import {
  GET_SENSOR,
  GET_SENSOR_SUCCESS,
  GET_SENSOR_FAIL,

  GET_DATA_SECURITY,
  GET_DATA_SECURITY_SUCCESS,
  GET_DATA_SECURITY_FAIL,

  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
} from "./actionTypes"
import { LOGOUT_LOGGEDIN_USER } from "../auth/login/actionTypes";


// export const getUsers = () => ({
//   type: GET_USERS,
// })

// export const getUsersSuccess = users => ({
//   type: GET_USERS_SUCCESS,
//   payload: users,
// })

// export const getUsersFail = error => ({
//   type: GET_USERS_FAIL,
//   payload: error,
// })

const getSensor = () => ({
  type: GET_SENSOR,
})

const getSensorSuccess = item => ({
  type: GET_SENSOR_SUCCESS,
  payload: item,
})

const getSensorFail = error => ({
  type: GET_SENSOR_FAIL,
  payload: error,
})

export const getAllMpsVsTimeData = (body) => {
  return new Promise((resolve, reject) => {
    axios.post("/sensor/getAllMpsVsTimeData", body)
    .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          reject("Invalid Token")
        } else {
          reject("Somthing wrong");
        }
      });
  })
}

const getDataSecurity = () => ({
  type: GET_DATA_SECURITY,
})

const getDataSecuritySuccess = item => ({
  type: GET_DATA_SECURITY_SUCCESS,
  payload: item,
})

const getDataSecurityFail = error => ({
  type: GET_DATA_SECURITY_FAIL,
  payload: error,
})
export const getDataSecurityTable = () => {
  return dispatch => {
    dispatch(getDataSecurity());
    axios.get("/player/getDataSecurityTable")
     .then(function (response) {
        dispatch(getDataSecuritySuccess(response.data.data));
      })
      .catch(function (error) {
        console.log('error getDataSecurityTable----------------', error);
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getDataSecurityFail(JSON.stringify(error.response.data.message)));

        }
      });
  }
}

export const singleDataSecurityTable = (body) => {
  return new Promise((resolve, reject) => {
    axios.post("/player/singleDataSecurity", body)
      .then(function (response) {
        resolve(response) 
      })
      .catch(function (error) {
		    console.log("error",error);
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          reject("Invalid Token")
        } else {
          reject("Somthing wrong");
        }
      });
  })
}

export const getAllsensors = () =>{
  return dispatch => {
    dispatch(getSensor());
    axios.get("/sensor/getSensor")
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      dispatch(getSensorSuccess(response.data.data));

    })
    .catch(function (error) {
      console.log('error ----------------', error);
      if (error.response.data && error.response.data.message === "Invalid Token Input") {
        dispatch(signOutUser())
      } else {
        dispatch(getSensorFail(JSON.stringify(error.response.data.message)));

      }
     
    });
  }

}
export const getCompleteSimulation = (body) => {
    return new Promise((resolve, reject) => {
    axios.post("/sensor/getCompleteSimulation", body)
     .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          reject("Invalid Token")
        } else {
          reject("Somthing wrong");
        }
      });
  })
}
export const getAllCompleteSimulation = (body) => {
    return new Promise((resolve, reject) => {
    axios.post("/sensor/getAllCompleteSimulation", body)
     .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          reject("Invalid Token")
        } else {
          reject("Somthing wrong");
        }
      });
  })
}
export const getfailedSimulation = (body) => {
    return new Promise((resolve, reject) => {
    axios.post("/sensor/getfailedSimulation", body)
     .then(function (response) {
        resolve(response) 
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          reject("Invalid Token")
        } else {
          reject("Somthing wrong");
        }
      });
  })
}
export const getpendingSimulation = (body) => {
    return new Promise((resolve, reject) => {
    axios.post("/sensor/getpendingSimulation", body)
     .then(function (response) {
        resolve(response) 
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          reject("Invalid Token")
        } else {
          reject("Somthing wrong");
        }
      });
  })
}
// Signout user if token invalid
const signOutUser = () => ({
  type: LOGOUT_LOGGEDIN_USER
})