import {
  GET_ORGANIZATIONS_LIST,
  GET_ORGANIZATIONS_LIST_SUCCESS,
  GET_ORGANIZATIONS_LIST_FAIL,

  ADD_ORGANIZATION,
  EPMTY_EDITING_DATA,
  RENAME_ORGANIZATION,
  DELETS_ORGANIZATION,
  MERGE_ORGANIZATION,
  EDIT_ORGANIZATIONS,
  EDIT_ORGANIZATIONS_STATE,
  EDIT_ORGANIZATIONS_SUCCESS,
  EDIT_ORGANIZATIONS_FAIL,

  ORG_STAFF,
  ORG_STAFF_SUCCESS,
  ORG_STAFF_FAIL,

  GET_ORG_STAT_SUMMARY,
  GET_ORG_STAT_SUMMARY_SUCCESS,
  GET_ORG_STAT_SUMMARY_FAIL,

  SET_ORG_ANYLSIS_DATA,
  SET_ORG_MPS_DATA,

  GET_ORG_SIMULATIONS_SUCC,
  GET_ORG_SIMULATIONS_FAIL,
  DELETE_ORG_STAFF,
  DELETE_ORG_STAFF_SUCCESS,
  DELETE_ORG_STAFF_FAIL,
  DELETE_ORG_STAFF_STATE,

  DASHBOARD_SUCCESS_DATA,

  ADD_DASHBOARD_SETTINGS,
  ADD_DASHBOARD_SETTINGS_SUCCESS,
  ADD_DASHBOARD_SETTINGS_ERROR,

  //STRAIN
  GET_ALL_INS_ANALYSIS_DATA,
  GET_ALL_INS_ANALYSIS_DATA_SUCCESS,
  GET_ALL_INS_ANALYSIS_DATA_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  organizations: null,
  loading: false,
  userProfile: {},
  error: {},
  addOrgName: null,
  renameOrgList: [],
  deleteOrgList: [],
  mergeOrgList: [],
  editingState: null,
  editingErr: null,
  editSuccess: null,

  loadingStaff: false,
  orgStaff: null,
  staffErr: null,

  getOrgStatsSummary: false,
  OrgStatsSummary: null,
  playerData: null,
  getOrgStatsSummaryErr: null,

  orgSimulations: null,
  orgAnylsisData: null,

  dashboardPressure: null,
  dashboardPressureLoading: false,

  //STARIN
  loadingOptimizeAllInsAnalysis: false,
  optimizeAllInsAnalysisData: null,
  optimizeAllInsAnalysisDataErr: false,
}

const Organization = (state = INIT_STATE, action) => {
  switch (action.type) {


    case GET_ORG_SIMULATIONS_SUCC:
      return {
        ...state,
        getOrgSimulations: true,
        orgSimulations: action.payload
      }

    case GET_ORG_SIMULATIONS_FAIL:
      return {
        ...state,
        getOrgSimulations: false,
        orgSimulations: null,
      }

    case SET_ORG_MPS_DATA:
      return {
        ...state,
        orgMPSData: action.payload
      }
    //Ins anylsis data
    case SET_ORG_ANYLSIS_DATA:
      return {
        ...state,
        orgAnylsisData: action.payload
      }
    //Org stats
    case GET_ORG_STAT_SUMMARY:
      return {
        ...state,
        getOrgStatsSummary: true,
        playerData: null,
        OrgStatsSummary: null,
        getOrgStatsSummaryErr: null,
      }
    case GET_ORG_STAT_SUMMARY_SUCCESS:
      return {
        ...state,
        getOrgStatsSummary: false,
        playerData: action.payload.playerProfile,
        OrgStatsSummary: action.payload.statSummaryData,
        getOrgStatsSummaryErr: null,
      }
    case GET_ORG_STAT_SUMMARY_FAIL:
      return {
        ...state,
        getOrgStatsSummary: false,
        playerData: null,
        OrgStatsSummary: null,
        getOrgStatsSummaryErr: action.payload,
      }
    //
    case GET_ORGANIZATIONS_LIST:
      return {
        ...state,
        loading: true,
        error: null,
        editingState: null,
        editingErr: null,
        editSuccess: null
      }

    case GET_ORGANIZATIONS_LIST_SUCCESS:
      return {
        ...state,
        organizations: action.payload,
        loading: false,
        error: null
      }

    case GET_ORGANIZATIONS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // crud operations ...
    case ADD_ORGANIZATION:
      return {
        ...state,
        addOrgName: action.payload
      }
    case RENAME_ORGANIZATION:
      return {
        ...state,
        renameOrgList: state.renameOrgList.concat(action.payload)
      }
    case MERGE_ORGANIZATION:
      return {
        ...state,
        mergeOrgList: state.mergeOrgList.concat(action.payload)
      }

    case DELETS_ORGANIZATION:
      return {
        ...state,
        deleteOrgList: state.deleteOrgList.concat(action.payload)
      }
    case EPMTY_EDITING_DATA:
      return {
        ...state,
        deleteOrgList: [],
        renameOrgList: [],
        deleteOrgList: [],
        mergeOrgList: [],
        addOrgName: null,
        loading: false,
        editingState: null,
        editingErr: null
      }
    // Edit ...
    case EDIT_ORGANIZATIONS:
      return {
        ...state,
        loading: true,
        editSuccess: null
      }

    case EDIT_ORGANIZATIONS_STATE:
      return {
        ...state,
        editingState: action.payload
      }
    case EDIT_ORGANIZATIONS_FAIL:
      return {
        ...state,
        editingErr: action.payload,
        loading: false
      }
    case EDIT_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        editingErr: null,
        loading: false,
        editSuccess: action.payload
      }
    // fetching staff...
    case ORG_STAFF:
      return {
        ...state,
        loadingStaff: true,
        orgStaff: null,
        staffErr: null,
        orgdeletedSuccess: null
      }

    case ORG_STAFF_SUCCESS:
      return {
        ...state,
        loadingStaff: false,
        orgStaff: action.payload,
        staffErr: null
      }

    case ORG_STAFF_FAIL:
      return {
        ...state,
        loadingStaff: false,
        orgStaff: null,
        staffErr: action.payload
      }
    // Edit players...
    case DELETE_ORG_STAFF:
      return {
        ...state,
        orgdeletingStaff: true,
        orgdeletedSuccess: null,
        orgdeletingErr: null,
        orgdeletedBeforeSuccess: null,
      }
    case DELETE_ORG_STAFF_STATE:
      return {
        ...state,
        orgdeletedBeforeSuccess: action.payload
      }
    case DELETE_ORG_STAFF_SUCCESS:
      return {
        ...state,
        orgdeletingStaff: false,
        orgdeletedSuccess: action.payload,
        orgdeletingErr: null,
        orgdeletedBeforeSuccess: null,
      }
    case DELETE_ORG_STAFF_FAIL:
      return {
        ...state,
        orgdeletingStaff: false,
        orgdeletedSuccess: null,
        orgdeletingErr: action.payload,
        orgdeletedBeforeSuccess: null,
      }
    case ADD_DASHBOARD_SETTINGS:
      return {
        ...state,
        dashboardPressureLoading: true
      }
    case ADD_DASHBOARD_SETTINGS_SUCCESS:
      return {
        ...state,
        dashboardPressure: action.payload,
        dashboardPressureLoading: false
      }
    case ADD_DASHBOARD_SETTINGS_ERROR:
      return {
        ...state,
        dashboardPressure: action.payload
      }
    //starin
    case GET_ALL_INS_ANALYSIS_DATA:
      return {
        ...state,
        loadingOptimizeAllInsAnalysis: true,
        optimizeAllInsAnalysisData: null,
        optimizeAllInsAnalysisDataErr: false
      }

    case GET_ALL_INS_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        loadingOptimizeAllInsAnalysis: false,
        optimizeAllInsAnalysisData: action.payload,
        optimizeAllInsAnalysisDataErr: false
      }

    case GET_ALL_INS_ANALYSIS_DATA_FAIL:
      return {
        ...state,
        loadingOptimizeAllInsAnalysis: false,
        optimizeAllInsAnalysisData: null,
        optimizeAllInsAnalysisDataErr: action.payload
      }
    default:
      return state
  }
}

export default Organization
