/* USERS */
export const GET_SENSOR = "GET_SENSOR"
export const GET_SENSOR_SUCCESS = "GET_SENSOR_SUCCESS"
export const GET_SENSOR_FAIL = "GET_SENSOR_FAIL"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"


/* Data Security */
export const GET_DATA_SECURITY = "GET_DATA_SECURITY"
export const GET_DATA_SECURITY_SUCCESS = "GET_DATA_SECURITY_SUCCESS"
export const GET_DATA_SECURITY_FAIL = "GET_DATA_SECURITY_FAIL"

/* Data Security */
export const SINGLE_DATA_SECURITY = "SINGLE_DATA_SECURITY"
export const SINGLE_DATA_SECURITY_SUCCESS = "SINGLE_DATA_SECURITY_SUCCESS"
export const SINGLE_DATA_SECURITY_FAIL = "SINGLE_DATA_SECURITY_FAIL"
